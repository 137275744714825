import React, { useMemo } from 'react';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import { Button, InputProps } from '@mui/material';
import { TextField } from '@mui/material';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import { useEffect } from 'react';
import { COL, JOBCOST_BILL_COLS } from '../jobtix/Types';
import { formatFromISO } from '../jobtix/JobList';
import { parseISOLocal, dateToDay, handleResponseToJson } from './JobCost';

/** Pop-up component displays 'quick dates' for un-used Work Reports, and a date selection that ghosts already used dates */
export function ColumnDateSelection(props: InputProps & {
  col: 'new' | COL;
  wrDates: string[];
  order_id: number;
  columns: COL[];
  toClose: Function;
  changeCol: (col: COL, action: 'add' | 'edit' | 'del') => Promise<void>;
  showError: (error: any, message: string, temp: boolean) => void;
}) {

  // Collect dates that are already used via other columns
  const datesDayPartNotThisCol = useMemo(() => {
    return props.columns
      .filter(c => props.col == 'new' || props.col.col_id != c.col_id)
      .map(c => parseISOLocal(c.date))
      .map((d) => dateToDay(d));
  }, [props.columns]);

  // Method used by date picker
  const canPickDay = (date: Date) => {
    let dayPart = dateToDay(date);
    return !(datesDayPartNotThisCol.find((dp) => dp == dayPart) === undefined);
    // dates.find((findDate) => isSameDay(date, findDate)) != undefined;
  };

  const [value, setValue] = React.useState<Date>((props.col != 'new') ? parseISOLocal(props.col.date) : new Date());

  useEffect(() => {
    // Make sure if this is a new column, that it doesn't set the value to today if a column already exists for today.
    // Only called when col changed
    if (props.col === 'new') {
      let localValue = new Date(value);
      let localDayValue = dateToDay(localValue);
      let isConflict = datesDayPartNotThisCol.find((day) => day == localDayValue) != undefined;
      if (isConflict) {
        while (isConflict == true) {
          localValue.setDate(localValue.getDate() + 1);
          localDayValue = dateToDay(localValue);
          isConflict = datesDayPartNotThisCol.find((day) => day == localDayValue) != undefined;
        }
        setValue(localValue);
      }
    }
  }, [props.col]);

  const performAction = (deleteAction: any, optDateValue?: string) => {

    let performOnValue = (optDateValue) ? optDateValue : value.toISOString().split('T')[0];

    const formPostData: any = {
      target: 'col',
      action: (props.col == 'new') ? 'add' : (deleteAction) ? 'del' : 'edit',
      order_id: props.order_id,
      date: performOnValue,
    };
    if (props.col != 'new') {
      formPostData['col_id'] = (props.col as COL).col_id;
      formPostData['oldVersion'] = (props.col as COL).version;
    }

    fetch('/api/json_jobcost_api.php', { method: "POST", body: JSON.stringify(formPostData) })
      .then(handleResponseToJson)
      .then((json: { success: boolean; col_id: string; version: string; }) => {
        console.log('/api/json_jobcost_api.php RSP:', json);
        let newVersion = (json.version) ? json.version : undefined;
        let newCol: COL = {
          order_id: '' + props.order_id,
          col_id: json.col_id || formPostData.col_id,
          date: formPostData.date || formPostData.date,
          version: json.version,
        };
        props.changeCol(newCol, formPostData.action);
        props.toClose(performOnValue);
      })
      .catch((error) => {
        props.showError(error, "" + error, false);
      });
  };
  const handleSave = () => {
    performAction(false);
  };
  const handleDelete = () => {
    window.confirm('Are you sure you want to delete this column?') && performAction(true);
  };
  console.log({ value });

  const quickWRDates = useMemo(() => {
    let qDates = [...props.wrDates];
    for (let c of props.columns) {
      qDates = qDates.filter((d) => d != c.date);
    }
    return qDates;
  }, [props.wrDates, props.columns]);

  return (
    <React.Fragment>
      {props.col === 'new'
        && quickWRDates.length > 0 && (<>
          <div style={{ fontWeight: 'bold' }}>Work Reports w/o Job Costing:</div>
          {quickWRDates.map((d: string) => <Button variant='outlined' key={d} style={{ margin: 1 }}
            onClick={() => { performAction(false, d); }}
            startIcon={<DocumentScannerIcon />}
          >Quick Add: {formatFromISO(d)}</Button>
          )}
        </>)}

      <StaticDatePicker
        displayStaticWrapperAs="desktop"
        value={value}
        onChange={(newValue) => {
          // console.log('newValue', newValue);
          setValue(newValue || new Date());
        }}
        renderInput={(params) => <TextField {...params} />}
        openTo='day'
        shouldDisableDate={canPickDay} />
      <Button variant="contained" sx={{ float: 'right' }} onClick={handleSave}>{props.col && props.col != 'new' && "Change column date" || "Add new column"}</Button>
      {props.col && props.col != 'new' && <Button variant="contained" color="error" onClick={handleDelete}>Delete</Button>}
    </React.Fragment>
  );
}
