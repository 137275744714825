import { TaskData, Options } from './index';
import { Group } from './ChartTasks';
import date_utils from './date_utils';
import { useRef } from 'react';
import { propsToClassKey } from '@mui/styles';
import TaskFunc from './TaskFunc';
import TaskFuncYeast from './TaskFuncYeast';

const HANDLE_WIDTH = 8;

export class Task {
  task: TaskData;

  selectTaskCallBack;

  _active: boolean = false;

  _options: Options;
  _chart_start: Date;
  _group: Group;
  groupPos: number;

  _deps: Task[] = [];

  _startEndDuration: number = 0;
  _x: number = 0;
  _y: number = 0;
  _width: number = 0;
  _height: number = 0;
  _progress: number = 0;
  _display: string | null = null;

  constructor(task: TaskData, group: Group, groupPos: number, options: Options, chart_start: Date, selectTaskCallBack: (task: Task | null) => void) {
    this.task = task;

    this._group = group;
    this.groupPos = groupPos;
    this.selectTaskCallBack = selectTaskCallBack;

    this._options = options;
    this._height = options.bar_height;
    this._chart_start = chart_start;

    this.dataToPosition();
  }

  getY() {
    return this._group.getSubY(this.groupPos);
    // return this._group.starty + (1 + this.groupPos*2) * (this._options.padding / 2) + (this.groupPos * this._options.bar_height);
  }

  setChartStart(start: Date) {
    this._chart_start = start;
    this.dataToPosition();
  }

  get_snap_position(deltaX: number): number {
    let position = deltaX;
    let partial;
    let round;

    if (this._options.view_mode == "Week") {
      partial = deltaX % (this._options!.column_width / 7);
      round =
        (partial < this._options!.column_width / 14
          ? 0
          : this._options!.column_width / 7);
    } else if (this._options.view_mode == "Month") {
      partial = deltaX % (this._options!.column_width / 30);
      round =
        (partial < this._options!.column_width / 60
          ? 0
          : this._options!.column_width / 30);
    } else {
      partial = deltaX % this._options!.column_width;
      round =
        (partial < this._options!.column_width / 2
          ? 0
          : this._options!.column_width);
    }
    return position - partial + round;
  }

  getRowPosNum() {
    return (this._group?.prerows || 0) + this.groupPos;
  }

  dataToPosition() {
    const { step, column_width, header_height, padding, bar_height } = this._options;
    const { _chart_start } = this;
    const rowDownPosition = this.getRowPosNum();

    const diff = date_utils.diff(this.task.start, _chart_start, 'hour');
    let calcDuration = date_utils.diff(this.task.end, this.task.start, 'hour') / step;

    this._x = diff / step * column_width;
    // this._y = header_height + padding + rowDownPosition * (bar_height + padding);
    this._y = padding / 2 + rowDownPosition * (bar_height + padding);
    this._width = column_width * calcDuration;
    this._height = bar_height;
    this._progress = column_width * calcDuration * (this.task.progress! / 100) || 0;
    this._startEndDuration = calcDuration;
  }

  positionToDates() {
    let diff = (this._x / this._options.column_width) * this._options.step;

    const computed_start_date = date_utils.add(this._chart_start, diff, 'hour');
    const width_in_units = this._width / this._options.column_width;
    const computed_end_date = date_utils.add(computed_start_date, width_in_units * this._options.step, 'hour');

    console.log("convertPositionToDates", "start", computed_start_date.toISOString(), "end", computed_end_date.toISOString());
    return [computed_start_date, computed_end_date];
  }

  render(ignore: any) {
    // console.log("onRender: ",ignore?.name, (this === ignore) ? this.name : null);
    // this._active = (this === ignore);
    if (this.task.groupID === 'yeast') {
      return <TaskFuncYeast key={"task-" + this.task.id}
        name={this._display || this.task.name + " [" + this._deps.length + "/" + this._group.data.name + "]"} id={this.task.id} options={this._options}
        x={this._x}
        y={this.getY() || 0} width={this._width} height={this._height} progress={this._progress}
        active={this._active}
        taskClass={(this.task.groupID === 'yeast' ? "yeast" : undefined)}
      />
    } else
      return <TaskFunc key={"task-" + this.task.id}
        name={this._display || this.task.name + " [" + this._deps.length + "/" + this._group.data.name + "]"} id={this.task.id} options={this._options}
        x={this._x}
        y={this.getY() || 0} width={this._width} height={this._height} progress={this._progress}
        active={this._active}
        taskClass={(this.task.id === 't3' ? "yeast" : undefined)}
      />

    // return (
    //   <g className="bar-wrapper" id={this.id}>
    //     {/* bar_group */}
    //     <g className="bar-group">
    //       {/* $bar */}
    //       <rect x={this._x} y={this._y} width={this._width} height={this._options.bar_height} rx={this._options.bar_corner_radius} ry={this._options.bar_corner_radius} className="bar"></rect>
    //       {/* $bar_progress */}
    //       <rect x={this._x} y={this._y} width={this._progress} height={this._options.bar_height} rx={this._options.bar_corner_radius} ry={this._options.bar_corner_radius} className="bar-progress"></rect>
    //     </g>
    //     <g className="handle-group">
    //       <rect className="handle right" x={this._x + this._width - 9} y={this._y + 1} width={HANDLE_WIDTH} height={this._options.bar_height - 2} rx={this._options.bar_corner_radius} ry={this._options.bar_corner_radius}></rect>
    //       <rect className="handle left" x={this._x + 1} y={this._y + 1} width={HANDLE_WIDTH} height={this._options.bar_height - 2} rx={this._options.bar_corner_radius} ry={this._options.bar_corner_radius}></rect>
    //       <polygon className="handle progress" points={this.calcProgressTrianglePoints()}></polygon>
    //     </g>
    //     <text x={(this._textIsBig) ? (this._x + this._width + 5) : (this._x + this._width / 2)} y={this._y + this._height / 2} className={(this._textIsBig) ? "bar-label big" : "bar-label"}>{this.name}</text>
    //   </g>
    // );
  }


  calculate_path(from: Task, to: Task): string {
    let start_x =
      from._x + from._width / 2;

    const condition = () =>
      to._x < start_x + this._options.padding &&
      start_x > from._x + this._options.padding;

    while (condition()) {
      start_x -= 10;
    }

    const start_y = //from._group.getStartY(this.groupPos) + this._options.bar_height;
      this._options.bar_height +
      (this._options.padding + this._options.bar_height) *
      from.getRowPosNum() +
      this._options.padding / 2;

    const end_x = to._x - this._options.padding / 8;
    const end_y = this._group.getSubY(this.groupPos, 'mid');
    // this._options.bar_height / 2 +
    // (this._options.padding + this._options.bar_height) *
    // to.getRowPosNum() +
    // this._options.padding / 2;

    const from_is_below_to =
      from.getRowPosNum() > to.getRowPosNum();
    const curve = this._options.arrow_curve;
    const clockwise = from_is_below_to ? 1 : 0;
    const curve_y = from_is_below_to ? -curve : curve;
    const offset = from_is_below_to
      ? end_y + this._options.arrow_curve
      : end_y - this._options.arrow_curve;

    let path = `
        M ${start_x} ${start_y}
        V ${offset}
        a ${curve} ${curve} 0 0 ${clockwise} ${curve} ${curve_y}
        L ${end_x} ${end_y}
        m -5 -5
        l 5 5
        l -5 5`;

    if (
      to._x <
      from._x + this._options.padding
    ) {
      const down_1 = this._options.padding / 2 - curve;
      const down_2 =
        (to._group.getSubY(this.groupPos, 'mid') || 0) //+
        // to._height / 2 
        - curve_y;
      const left = to._x - this._options.padding;

      path = `
            M ${start_x} ${start_y}
            v ${down_1}
            a ${curve} ${curve} 0 0 1 -${curve} ${curve}
            H ${left}
            a ${curve} ${curve} 0 0 ${clockwise} -${curve} ${curve_y}
            V ${down_2}
            a ${curve} ${curve} 0 0 ${clockwise} ${curve} ${curve_y}
            L ${end_x} ${end_y}
            m -5 -5
            l 5 5
            l -5 5`;
    }

    return path;
  }


  renderArrows(ignore: any) {
    let arrows: JSX.Element[] = [];
    for (let dep of this._deps) {
      arrows.push(
        <path d={this.calculate_path(dep, this)} data-from={this.task.id} data-to={dep.task.id} stroke={this._active ? "black" : "lightgrey"}></path>
      );
    }
    return arrows;
  }

}




// if (label.getBBox().width > getWidth(bar)) {
//   label.classList.add('big');
//   label.setAttribute('x', (getX(bar) + getWidth(bar) + 5) as any);
// } else {
//   label.classList.remove('big');
//   label.setAttribute('x', (getX(bar) + getWidth(bar) / 2) as any);
// }