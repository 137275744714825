import React, { useEffect, useState } from "react";

import { handleResponseToJson } from "./JobCost";
import { ROW } from "../jobtix/Types";


import { Autocomplete, Button, InputProps, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { createFilterOptions } from "@mui/base";



const RowCrewEditWidget = (props: InputProps & { row?: 'newcrew' | ROW, order_id: number, toClose: Function, changeRow: (action: 'add' | 'edit' | 'del') => Promise<void>, showError: (error: any, message: string, temp: boolean) => void, readonly: boolean }) => {
  const isNew: boolean = (props.row === 'newcrew');
  const row: ROW | null = (!isNew && props.row != null) ? props.row as ROW : null;

  const [value, setValue] = React.useState<string>("");
  const [inputValue, setInputValue] = React.useState('');

  const [isBusy, setBusy] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [options, setOptions] = useState<string[]>([""]);
  const filter = createFilterOptions<string>();

  const addPrefixText = "Add to database: \"";

  useEffect(() => {
    setBusy(true);
    fetch('/api/json_jobcost_api.php', { method: "POST", body: JSON.stringify({ target: 'extracrew', action: 'list' }) })
      .then(handleResponseToJson)
      .then((json: { success: boolean, crews: { name: string }[] }) => {
        setOptions(json.crews.map((c) => c.name));
        // Attempt to set value to pre-selected eqid
        if (props.row !== 'newcrew') {
          let found = json.crews.find((t) => t.name == row?.name);
          if (found)
            setValue(found.name);
          else if (row != null) {
            setOptions((options) => [...options, row.name]);
            setValue(row.name);
          }
        }
      })
      .catch((error) => {
        props.showError(error, "" + error, false);
        setError("Saving crew");
      }).finally(() => {
        setBusy(false);
      });
  }, []);

  const performAction = (deleteAction: any) => {
    const formPostData: any = {
      target: 'row',
      action: (isNew) ? 'addextra' : (deleteAction) ? 'del' : 'editextra',
      order_id: props.order_id,
      jobType: 'extracrew',
      name: (value.startsWith(addPrefixText)) ? value.substring(addPrefixText.length, value.length - 1) : value,
    }

    if (!isNew) {
      formPostData['row_id'] = (props.row as ROW).row_id;
      formPostData['oldVersion'] = (props.row as ROW).version;
    }

    setBusy(true);
    fetch('/api/json_jobcost_api.php', { method: "POST", body: JSON.stringify(formPostData) })
      .then(handleResponseToJson)
      .then((json: { success: boolean, row_id: string, version: string }) => {
        console.log('/api/json_jobcost_api.php RSP:', json);
        let newVersion = (json.version) ? json.version : undefined;
        props.changeRow(formPostData.action);
        props.toClose();
      })
      .catch((error) => {
        props.showError(error, "" + error, false);
      }).finally(() => {
        setBusy(false);
      });
  };
  const handleSave = () => {
    performAction(false);
  }
  const handleDelete = () => {
    window.confirm('Are you sure you want to delete this special crew?') && performAction(true);
  }
  const handleOnBlur = () => {
  }

  return (
    <React.Fragment>
      <Typography fontSize="x-large" variant="overline">{isNew && "Add Special Crew" || "Edit Special Crew"} </Typography>
      <Box marginBottom={1}>
        <Autocomplete autoComplete autoHighlight autoSelect disableClearable style={{ width: '100%' }}
          freeSolo
          value={value}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            console.log('Autocomplete onInputChange ', newInputValue, event);
            setInputValue(newInputValue);
          }}
          onChange={(event: any, newValue: string | null) => {
            console.log('Autocomplete onChange ', newValue, event);
            setValue(newValue || "");
          }}
          // isOptionEqualToValue={(option, value) => option === value}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            let op = option as any;
            if (op?.name) {
              return op.name;
            } else if (op.label) {
              return op.label;
            }
            console.error("Invalid typed option?", option);
            return "INVALOD00";
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option);
            if (inputValue !== '' && !isExisting) {
              filtered.push(addPrefixText + inputValue + '"');
            }

            return filtered;
          }}
          options={options}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Special Crew" />}
          disabled={props.disabled || props.readonly || (error != null) || isBusy}
        />
        {error != null && <Typography color="red">{error}</Typography>}
        {value.startsWith(addPrefixText) && <Typography fontStyle="italic">New Database Special Crew will be created</Typography>}
      </Box>

      <Button variant="contained" sx={{ float: 'right' }} disabled={value == null || props.readonly} onClick={handleSave}>{isNew && "Add Crew" || "Edit Crew"}</Button>
      {!isNew && <Button variant="contained" color="error" disabled={props.readonly} onClick={handleDelete}>Delete</Button>}
    </React.Fragment>
  );
};

export default RowCrewEditWidget;