import React from "react";
import { Link } from "react-router-dom";
import useSWR from "swr";
import * as api from "./apiCalls";
import { CUSTOMER } from "./Types";


export default function ShowCustomer(props: { customerNo: number | null }) {

  const swr = useSWR(['getCustomer', props.customerNo], api.getCustomer);
  const customer = swr.data;
  const error = swr.error;

  React.useEffect(() => {
    if (error)
      console.log("Error loading customer information: ", props.customerNo, error);
  }, [error]);

  return (
    <div style={{ marginTop: 16, width: "100%" }}>
      {!customer && "N/A"}
      {error && <span style={{ color: 'red' }}>Unable to load, see console for error msg.</span>}
      {customer && (<>
        <Link to={"/customer.php?id=" + customer.CustomerNo}>{customer.Customer}</Link><br />
        {customer.BillingAddress}<br />
        {customer.City}, {customer.State} {customer.Zip}<br />
        Phone: {customer.PhNumber} / Fax: {customer.Fax}
        {customer.Status && (<><br />Status: {customer.Status}</>)}
      </>)}
    </div>
  );
}