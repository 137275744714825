import React, { ChangeEvent, ChangeEventHandler, Component, useEffect, useState } from 'react';
import * as api from './apiCalls';

import { FlexDate, FlexSelect, FlexSelectStr, PhoneMaskCustom } from './JobTicket';
import { CUSTOMER, JOB } from './Types';

import { Box, Button, Input, InputProps, InputBase, CssBaseline, AppBar, Toolbar, IconButton, Divider, Typography, LinearProgress, StandardTextFieldProps, SelectChangeEvent, InputAdornment, FormHelperText, Link, Stack, Avatar, Chip, ButtonGroup, TableContainer, TextFieldProps } from '@mui/material';
import { Container, Grid, Paper, Tab, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import { Stepper, Step, StepLabel } from '@mui/material';

import AutorenewIcon from '@mui/icons-material/Autorenew';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { Edit as EditIcon } from '@mui/icons-material';

import { render } from '@testing-library/react';
import { useLocation, useNavigate, useParams } from 'react-router';
import AutoCustomerField from '../utils/AutoCustomer';

import { IMaskInput, IMaskMixin } from 'react-imask';
import { asDollars } from '../utils/Functions';
import usePrompt from '../utils/usePrompt';
import ShowCustomer from './ShowCustomer';
import { useSWRConfig } from 'swr';
import debounce from '@mui/utils/debounce';


type DataValidObj = {
  CustomerNo?: null | string | boolean,
  Office?: null | string | boolean,
  JobName?: null | string | boolean,
  JobTypeID?: null | string | boolean,
  CallDate?: null | string | boolean,
  Contact?: null | string | boolean,
  Beeper?: null | string | boolean,
  ContactEmail?: null | string | boolean,
  EstmDays?: null | string | boolean,
  JobAddress?: null | string | boolean,
  JobCity?: null | string | boolean,
  ProposedInstructions?: null | string | boolean,
}

function JobTixTicket(props: { setEditMode: (editMode: boolean) => void, job: JOB, customer: CUSTOMER | null, offices: [[number, string, boolean]], jobtypes: [[number, string]], authorizations: [[string, string]] }) {
  const [isBusy, setBusy] = useState(false);
  const [error, setError] = useState<string | null>();
  const [localData, setLocalData] = useState<JOB | null>(null);
  const [localDataErrors, setLocalDataErrors] = useState<DataValidObj>({});

  // const [localCustomer, setLocalCustomer] = useState<CUSTOMER | null>(props.customer);

  const { mutate } = useSWRConfig();

  const isEditMode = (localData != null);
  const readOnly = !isEditMode;
  usePrompt("Unsaved changes, are you sure you want to leave?", isEditMode);

  // handle form methods works when localData != null (aka: isEditMode)
  const handleFormChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<number> | SelectChangeEvent<string>) => void = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    setLocalData((prevData) => {
      return (prevData === null) ? null : { ...prevData, [key]: value }
    });
  }
  const handleNamedFormChange = (name: keyof JOB) => {
    return (value: any) => {
      setLocalData((prevData) => {
        return (prevData === null) ? null : { ...prevData, [name]: value }
      });
    };
  }

  const onCustomerNoChange = (newCustNo: string | number | null) => {
    let custNo = (newCustNo != null) ? (typeof newCustNo === 'string') ? Number.parseInt(newCustNo) : newCustNo : null;
    handleNamedFormChange('CustomerNo')((Number.isFinite(custNo)) ? custNo : null);
  }

  const onEditButton = () => {
    props.setEditMode(true);
    setLocalData({ ...props.job });
  }

  const onCancel = () => {
    props.setEditMode(false)
    setLocalData(null);
  }

  const validateData = (localData: JOB | null) => {
    if (localData == null) {
      setLocalDataErrors({});
    }
    else {
      setLocalDataErrors({
        CustomerNo: (localData.CustomerNo == null),
        Office: (localData.Office <= 0),
        JobName: (localData.JobName == ""),
        JobTypeID: (localData.JobTypeID <= 0),
        CallDate: (localData.CallDate == null || localData.CallDate == ''),
        Contact: (localData.Contact == null || localData.Contact == ''),
        Beeper: (localData.Beeper == null || localData.Beeper == ''),
        ContactEmail: (localData.ContactEmail == null || localData.ContactEmail == ''),
        EstmDays: (localData.EstmDays <= 0),
        JobAddress: (localData.JobAddress == null || localData.JobAddress == ''),
        JobCity: (localData.JobCity == null || localData.JobCity == ''),
        ProposedInstructions: (localData.ProposedInstructions == null || localData.ProposedInstructions == ''),
      });
    }
  }
  const debouncedValidateData = React.useMemo(
    () => debounce(validateData, 300)
    , []);
  React.useEffect(() => {
    console.log("useEffect - need validateData");
    debouncedValidateData(localData);
    return () => { debouncedValidateData.clear() }
  }, [localData])

  const isErrorExist = (isEditMode) ? Object.values(localDataErrors).some((e) => e == true) : false;

  const onSave = () => {
    if (isErrorExist) {
      alert("Problem proventing submission, correct red colored form fields below.")
    } else if (localData) {
      api.saveJob(localData).then(result => {
        console.log("RESULT from job save: ", result)
        // Clear cache - will make SWR refresh job
        mutate(["getJobCluster", '' + localData.OrderNo], () => Promise.resolve(localData));
        // Clear out local change cache
        onCancel();
      }).catch(error => setError(error));
    }
  }

  const job = localData || props.job;
  // const customer = localCustomer || props.customer;
  const { offices, jobtypes, authorizations } = props;

  const textProps: TextFieldProps = { onChange: handleFormChange, fullWidth: true, variant: "standard", InputProps: { readOnly: readOnly } };
  const textMProps: TextFieldProps = { ...textProps, multiline: true };
  const readonlyProps = { InputProps: { readOnly: readOnly } };

  return (
    <Container>
      <Grid container spacing={2}>

        {!isEditMode &&
          <Grid item xs={12}>
            <Stack direction="row" spacing={1}>
              {!job.videourl &&
                <Button startIcon={<VideoLibraryIcon />} variant="outlined"
                  href={'/onedrive/viewdrive.php?type=videos&jobid=' + job.OrderNo}
                  target="_blank"
                >
                  Create Video Storage
                </Button>
              }
              {job.videourl &&
                <ButtonGroup disableElevation variant="outlined">
                  <Button href={job.videourl} target="_blank" startIcon={<VideoLibraryIcon />}
                    style={{
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      borderRight: '1px solid #bdbdbd',
                      borderColor: '#1565c0'
                    }}
                  >Video Storage</Button>
                  <Button size="small" style={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderLeft: '1px solid #bdbdbd',
                    borderColor: '#1565c0'
                  }}
                    href={'/onedrive/viewdrive.php?type=videos&jobid=' + job.OrderNo}
                    target="_blank"
                  >
                    <AutorenewIcon />
                  </Button>
                </ButtonGroup>
              }

              {!job.filesurl &&
                <Button startIcon={<FilePresentIcon />} variant="outlined"
                  href={'/onedrive/viewdrive.php?type=files&jobid=' + job.OrderNo}
                  target="_blank"
                >
                  Create Attachments
                </Button>
              }
              {job.filesurl &&
                <ButtonGroup disableElevation variant="outlined">
                  <Button href={job.filesurl} target="_blank" startIcon={<FilePresentIcon />}
                    style={{
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      borderRight: '1px solid #bdbdbd',
                      borderColor: '#1565c0'
                    }}
                  >Attachments</Button>
                  <Button size="small" style={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderLeft: '1px solid #bdbdbd',
                    borderColor: '#1565c0'
                  }}
                    href={'/onedrive/viewdrive.php?type=files&jobid=' + job.OrderNo}
                    target="_blank">
                    <AutorenewIcon />
                  </Button>
                </ButtonGroup>
              }
              <Box sx={{ flexGrow: 1 }}></Box>

              <Button style={{ float: 'right' }} startIcon={<EditIcon />} variant="contained" onClick={onEditButton}>Edit</Button>
            </Stack>
          </Grid>
        }

        {isEditMode && <>
          <Grid item xs={12} component={Paper} elevation={2} square={true} sx={{ position: 'sticky', top: 64, zIndex: 1, padding: 1, backgroundColor: 'white' }}>
            <Stack direction="row" spacing={1}>
              <Typography variant='h5'>Edit JobTicket</Typography>
              <Box sx={{ flexGrow: 1 }}></Box>

              <Button variant="outlined" style={{ marginLeft: 5, float: 'right' }} onClick={onCancel}>Cancel</Button>
              <Button variant="contained" style={{ float: 'right' }} onClick={onSave} color={(isErrorExist) ? 'error' : 'primary'}>Save</Button>
            </Stack>
          </Grid>
        </>}


        {job && job.sage_export_id && <Grid item xs={12}><Typography variant="overline" fontSize="medium">SageID: {job.sage_export_id}</Typography></Grid>}
        <Grid item xs={4}><TextField name="JobName" label="Job Name" value={job.JobName} {...textProps} required error={(localDataErrors['JobName']) ? true : false} /></Grid>
        {localDataErrors ? localDataErrors['JobName'] : 'xxx'}
        <Grid item xs={4}><FlexSelect name="Office" label="Office" value={job.Office} entries={offices} onChange={handleFormChange} readonly={readOnly} error={(localDataErrors['Office']) ? true : false} /></Grid>
        {/* InputProps={{ readOnly: !props.isEditMode }} */}
        <Grid item xs={4}><FlexSelect name="JobTypeID" label="Job Type" value={job.JobTypeID} entries={jobtypes} onChange={handleFormChange} readonly={readOnly} error={(localDataErrors['JobTypeID']) ? true : false} /></Grid>

        <Grid item xs={4}><TextField name="Estimator" label="Estimator" value={job.Estimator} {...textProps} /></Grid>
        <Grid item xs={4}>
          <TextField name="EstmDays" label="Est. Days" value={job.EstmDays} {...textProps} autoComplete='off'
            inputProps={{ type: 'number', step: '.1', min: '.2' }}
            // @ts-ignore
            InputProps={{ inputMode: 'numeric', endAdornment: (<InputAdornment position="end">days</InputAdornment>) }}
            // type: 'numeric', step: '.1', min: '.2', pattern: '[0-9]*.?[0-9]*', 
            error={(localDataErrors['EstmDays']) ? true : false}
          />
        </Grid>
        <Grid item xs={4}><TextField name="EstmTotal" label="Est. Total" value={job.EstmTotal} {...textProps} InputProps={{ disabled: true, startAdornment: (<InputAdornment position="start">$</InputAdornment>) }} /></Grid>

        <Grid item xs={4}><TextField name="PurchaseOrder" label="Purchase Order" value={job.PurchaseOrder} {...textProps} /></Grid>

        {/* <Grid item xs={12}><Typography variant="overline" fontSize="medium">Customer: {customer && customer.Customer}</Typography></Grid> */}
        <Grid item xs={4}><AutoCustomerField label="CustomerNo" value={job.CustomerNo} onChange={onCustomerNoChange} readonly={readOnly} error={(localDataErrors['CustomerNo']) ? true : false} /></Grid>
        {/* Removed from LABEL since we are not caching CUSTOMER on this level... letting the 'ShowCustomer' component below customer.Customer ||  */}
        {/* <Grid item xs={4}><TextField name="CustomerNo" label="CustomerNo" value={job.CustomerNo} {...this.textProps} /></Grid> */}
        <Grid item xs={4}>
          <FormControl variant="standard" fullWidth>
            <InputLabel shrink={true}>Customer Details</InputLabel>
            <ShowCustomer customerNo={job.CustomerNo} />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FlexSelectStr name="authorization" label="Authorization" value={job.authorization} entries={authorizations} onChange={handleFormChange} readonly={readOnly} error={false} />
        </Grid>

        <Grid item xs={12}><Typography variant="overline" fontSize="medium">Contact:</Typography></Grid>
        <Grid item xs={4}><TextField name="Contact" label="Contact" value={job.Contact} {...textProps} error={(localDataErrors['Contact']) ? true : false} /></Grid>
        <Grid item xs={4}><TextField name="ContactEmail" label="ContactEmail" value={job.ContactEmail} {...textProps} error={(localDataErrors['ContactEmail']) ? true : false} /></Grid>
        <Grid item xs={4}>
          {/* <TextField name="Beeper" label="Contact Ph" value={job.Beeper} {...this.textProps} /> */}
          <FormControl variant="standard" fullWidth>
            <InputLabel shrink={(job.Beeper != null && job.Beeper != '')}>Contact Ph</InputLabel>
            <Input
              name="Beeper"
              value={job.Beeper}
              readOnly={readOnly}
              onChange={(e) => { console.log('mask test', e.target.name, e.target.value); handleFormChange(e) }} //handleFormChange
              inputComponent={PhoneMaskCustom as any}
              error={(localDataErrors['Beeper']) ? true : false}
            />
          </FormControl>
        </Grid>


        <Grid item xs={12}><Typography variant="overline" fontSize="medium">Job Dates:</Typography></Grid>
        <Grid item xs={4}><FlexDate name="CallDate" label="Call Date" value={job.CallDate} entries={jobtypes} onChange={handleNamedFormChange('CallDate')} readonly={readOnly} /></Grid>
        <Grid item xs={4}><FlexDate name="ProposalDate" label="Proposal Date" value={job.ProposalDate} entries={jobtypes} onChange={handleNamedFormChange('ProposalDate')} readonly={readOnly} /></Grid>
        <Grid item xs={4}><FlexDate name="ProposalReturnDate" label="Authorized Date" value={job.ProposalReturnDate} entries={jobtypes} onChange={handleNamedFormChange('ProposalReturnDate')} readonly={readOnly} /></Grid>
        <Grid item xs={4}><FlexDate name="StartDate" label="Start Date" value={job.StartDate} entries={jobtypes} onChange={handleNamedFormChange('StartDate')} readonly={readOnly} /></Grid>
        <Grid item xs={4}><FlexDate name="CompletionDate" label="Completion Date" value={job.CompletionDate} entries={jobtypes} onChange={handleNamedFormChange('CompletionDate')} readonly={readOnly} /></Grid>
        <Grid item xs={4}><FlexDate name="KilledDate" label="Killed Date" value={job.KilledDate} entries={jobtypes} onChange={handleNamedFormChange('KilledDate')} readonly={readOnly} /></Grid>

        <Grid item xs={12}><Typography variant="overline" fontSize="medium">Details:</Typography></Grid>

        <Grid item xs={4}><TextField name="JobAddress" label="Job Address" value={job.JobAddress} {...textMProps} error={(localDataErrors['JobAddress']) ? true : false} /></Grid>
        <Grid item xs={4}><TextField name="JobCity" label="Job City" value={job.JobCity} {...textProps} error={(localDataErrors['JobCity']) ? true : false} /></Grid>
        <Grid item xs={4}><TextField name="Directions" label="Directions" value={job.Directions} {...textMProps} /></Grid>

        <Grid item xs={12}><Typography variant="overline">Text Instructions:</Typography></Grid>
        <Grid item xs={6}><TextField name="EquipmentNeeded" label="Equipment Needed" value={job.EquipmentNeeded} {...textMProps} /></Grid>
        <Grid item xs={6}><TextField name="ProposedInstructions" label="Proposed Instructions" value={job.ProposedInstructions} {...textMProps} error={(localDataErrors['ProposedInstructions']) ? true : false} /></Grid>
        <Grid item xs={6}><TextField name="JobInstructions" label="Job Instructions" value={job.JobInstructions} {...textMProps} /></Grid>
        <Grid item xs={6}><TextField name="NTO" label="NTO" value={job.NTO} {...textMProps} /></Grid>
        <Grid item xs={4}><TextField name="HrBilled" label="Hr Billed" value={job.HrBilled} {...textProps} /></Grid>

      </Grid>
    </Container>
  );
}
export default JobTixTicket;