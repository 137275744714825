// import { redirect } from "react-router-dom";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const loader = async () => {
  // return redirect("/app/tools/lien");
  new Response("", {
    status: 302,
    headers: {
      Location: "/app/tools/lien",
    },
  });
};

export default function LeinRedirect() {
  const nav = useNavigate();

  useEffect(() => {
    nav("/app/tools/lien");
  }, [])


  return (<a href="/app/tools/lien">Redirect to /app/tools/lien</a>);
}
