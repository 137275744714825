import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import debounce from '@mui/utils/debounce';
import useSWR from 'swr';
import * as api from '../jobtix/apiCalls';

interface CustomerList {
  id: number;
  label: string;
}

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

function joinLabel(cust: CustomerList): string {
  if (cust.id >= 0 && (cust.label == ''))
    return '' + cust.id;
  else if (cust.id < 0 && cust.label != '')
    return cust.label;
  else
    return cust.id + ': ' + cust.label;
}

export default function AutoCustomerField(props: { label: string, value: null | number, readonly: boolean, onChange: (value: string | number | null) => void, error?: boolean }) {
  // const [value, setValue] = React.useState<number | null>(null);
  const value = props.value;
  const [options, setOptions] = React.useState<readonly CustomerList[]>([{ id: -1, label: 'Type to search by customer number or name' }]);
  const [loading, setLoading] = React.useState(false);

  const swr = useSWR(['getCustomer', value], api.getCustomer);
  const customer = swr.data;
  const optionValue = (value) ? { id: value || -1, label: customer?.Customer || "Loading customer details..." } : null;

  React.useEffect(() => {
    if (customer)
      setOptions([{ id: customer.CustomerNo, label: customer.Customer || "Loading customer details..." }]);
  }, [value, customer])

  const fetchData = async (toSearch: string): Promise<void | CustomerList[]> => {
    // console.log("in fetch, looking for url params: ", this.props.params.id);
    return axios.get('/api/json.php', { params: { a: 'lookup', t: 'customer', query: toSearch } })
      .then((resp) => resp.data as [[number, string]])
      .then((json) => {
        console.log('/api/json.php RSP:', json);

        let custList: CustomerList[] = json.map((cust) => { return { id: cust[0], label: cust[1] } });
        return custList;
      })
      .catch((error) => {
        console.error("ERROR in looking up customer: ", error);
      });
  }

  const handleTextChange = async (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    let custList = await fetchData(event.target.value);
    console.log('custList', custList)
    if (custList)
      setOptions(custList);
    setLoading(false);
  }
  const debouncedChangeHandler = React.useMemo(
    () => debounce(handleTextChange, 300)
    , []);
  React.useEffect(() => {
    return () => { debouncedChangeHandler.clear() }
  }, [])

  return (
    <Autocomplete
      fullWidth
      onChange={(e, v, r, d) => {
        //console.log('auto onChange: ', e, v, r, d); 
        props.onChange(v ? '' + v.id : null);
      }}
      isOptionEqualToValue={(option, value) => option?.id === value.id}
      getOptionLabel={joinLabel}
      options={options}
      getOptionDisabled={(opt) => opt.id < 0}
      loading={loading}
      value={optionValue}
      handleHomeEndKeys={false}
      readOnly={props.readonly}
      placeholder='Type to search by customer number or name'
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          variant="standard"
          error={props.error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          onChange={(e) => { setLoading(true); debouncedChangeHandler(e) }}
        />
      )}
      selectOnFocus
      // clearOnBlur
      // This disabled the native filtering, since we are pulling from a server
      filterOptions={(x) => x}
    />
  );
}
