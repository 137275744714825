import { useState, useEffect } from 'react';
import * as api from '../jobtix/apiCalls';

import { Container, Typography, LinearProgress, Paper } from '@mui/material';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import JobMgrHeader from '../utils/JobMgrHeader';

export type SAGE_LOG = { id: number, datetime: Date, pid: number, label: string, text: string };

export function SageLog() {

  const [reset, setReset] = useState(1);
  const [logs, setLogs] = useState<SAGE_LOG[]>([]);
  const [error, setError] = useState<string | null>();

  useEffect(() => {
    api.getSageLogs("getSageLogs").then((result) => {
      setLogs(result.sagelogs);
    }).catch((error) => {
      setError(JSON.stringify(error));
    })
  }, [reset])

  return <>
    <JobMgrHeader>
      <Typography variant="h6" component="h1">
        Sage Log
      </Typography>
    </JobMgrHeader>

    <Container>
      {error && <Typography>ERROR: {error}</Typography>}
      {!error && !logs && <Typography>Loading... <LinearProgress /></Typography>}
      {!error && logs && (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="right">ID</TableCell>
                <TableCell align="right">DateTime</TableCell>
                <TableCell align="right">PID</TableCell>
                <TableCell align="right">Label</TableCell>
                <TableCell align="right">Message</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logs.map((log) => (
                <TableRow
                  key={log.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">{log.id}</TableCell>
                  <TableCell align="right">{log.datetime}</TableCell>
                  <TableCell align="right">{log.pid}</TableCell>
                  <TableCell align="right">{log.label}</TableCell>
                  <TableCell align="right">{log.text}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  </>;
}