import React, { useEffect, RefCallback } from 'react';
import { useLocation, useNavigate, useParams, RouteMatch } from 'react-router';
import useSWR from 'swr';
import axios from 'axios';
import * as api from './apiCalls';

import { Box, Button, Typography, LinearProgress, SelectChangeEvent } from '@mui/material';
import { Container, Paper, Tab, Accordion } from '@mui/material';
import { FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import { Stepper, Step, StepLabel } from '@mui/material';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

import { styled } from '@mui/material/styles';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { IMaskInput } from 'react-imask';
import JobTixItems from './JobTixItems';
import JobTixProposal from './JobTixProposal';
import { JobMgrHeader } from '../utils/JobMgrHeader';
import JobSchedule from './JobSchedule';
import JobTixTicket from './JobTixTicket';
import useTSQueryParams from '../utils/useTSQueryParams';
import { CUSTOMER, JOB } from './Types';
import { Launch } from '@mui/icons-material';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export interface PhoneMaskCustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const PhoneMaskCustom = React.forwardRef<HTMLInputElement, PhoneMaskCustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="(#00) 000-0000"
        definitions={{
          '#': /[1-9]/,
        }}
        ref={ref as RefCallback<HTMLInputElement>} // HTMLTextAreaElement |
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);


const SmAccordian = styled(Accordion)(({ theme }) => ({
  // '&.Mui-expanded': { marginTop: 0 },
  '&:not(.Mui-expanded):hover': { backgroundColor: 'rgba(100,100,100,.1)' },
}));


export type JSON_JOBTICKET_ALL = { job: JOB, customer: CUSTOMER, offices: [[number, string, boolean]], jobtypes: [[number, string]], authorizations: [[string, string]] };


type FLEX_SELECT_PROPS = {
  name: string,
  label: string,
  value: number,
  error?: boolean,
  entries: [[number, string, boolean?]],
  onChange: ((event: SelectChangeEvent<number>, child: React.ReactNode) => void)
  readonly: boolean
}
export function FlexSelect(props: FLEX_SELECT_PROPS) {
  let found = false;
  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel>{props.label}</InputLabel>
      <Select value={props.value} name={props.name} label={props.label} onChange={props.onChange} readOnly={props.readonly} error={props.error}>
        {props.entries.map(e => {
          if (e[0] == props.value) found = true;
          return (<MenuItem key={e[0]} value={e[0]} disabled={(e.length >= 3) ? !e[2] : false}>{e[1]}</MenuItem>);
        })}
        {(!found) && <MenuItem key={props.value} value={props.value}>Old Value: {props.value}</MenuItem>}
      </Select>
    </FormControl>
  );
}
type FLEX_SELECT_PROPS_STR = {
  name: string,
  label: string,
  value: string,
  error?: boolean,
  entries: [[string, string]],
  onChange: ((event: SelectChangeEvent<string>, child: React.ReactNode) => void)
  readonly: boolean
}
export function FlexSelectStr(props: FLEX_SELECT_PROPS_STR) {
  let found = false;
  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel>{props.label}</InputLabel>
      <Select value={props.value} name={props.name} label={props.label} onChange={props.onChange} readOnly={props.readonly} error={props.error}>
        {props.entries.map(e => {
          if (e[0] == props.value) found = true;
          return (<MenuItem key={e[0]} value={e[0]}>{e[1]}</MenuItem>);
        })}
        {(!found) && <MenuItem key={props.value} value={props.value}>Old Value: {props.value}</MenuItem>}
      </Select>
    </FormControl>
  );
}
type FLEX_DATE_PROPS = {
  name: string,
  label: string,
  value: Date | string | null,
  entries: [[number, string]],
  onChange: (newValue: string | null) => void,
  readonly: boolean
}
const flexDatePad = (num: number): string => {
  var r = String(num);
  return (r.length === 1) ? '0' + r : r;
}
export const toISOStr = (input: Date | string | null): string | null => {
  if (input == null || (typeof input === 'string' && input.trim() == ''))
    return null;

  let d = (typeof input === 'string') ? new Date(input) : input;

  if (d instanceof Date && Number.isNaN(d.valueOf()) == false)
    return d.getFullYear() + '-' + flexDatePad(d.getMonth() + 1) + '-' + flexDatePad(d.getDate());
  else
    return null;
}
export const dateToISOStr = (d: Date) => {
  return d.getFullYear() + '-' + flexDatePad(d.getMonth() + 1) + '-' + flexDatePad(d.getDate());
}
const date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
export const validDateValue = (input: Date | string) => {
  return (typeof input === 'string' && date_regex.test(input)) || true;
}

export function FlexDate(props: FLEX_DATE_PROPS) {
  return (<>
    <DatePicker label={props.label} value={props.value} readOnly={props.readonly} onChange={(d: Date | string | null, k?: string) => {
      console.log('datepickers on change', typeof d, JSON.stringify(d), '/', typeof k, k);
      // @ts-ignore
      props.onChange(d);

      // if (typeof d === 'string') d = new Date(d);


      // if (k && date_regex.test(k) && d) {
      //   props.onChange(d.getFullYear() + '-' + flexDatePad(d.getMonth() + 1) + '-' + flexDatePad(d.getDate()));
      // } else
      //  if (k === '' || k === undefined) {
      //   props.onChange(null);
      // } else {
      //   props.onChange(k);
      // }



      // if (d instanceof Date && !isNaN(d.valueOf()))
      //   props.onChange(d.getFullYear() + '-' + flexDatePad(d.getMonth() + 1) + '-' + flexDatePad(d.getDate()));
      // else if (d === null)
      //   props.onChange(null);
      // else
      //   props.onChange('Invalid Date');
    }}
      renderInput={(params: any) => <TextField variant="standard" fullWidth {...params} />}
    />
  </>
  );
}


type JobTicketState = {
  d1: string | null,
  d2: string | null,
  loading: boolean,
  error?: string | null,
  tabValue: string,

  job?: JOB,
  customer?: CUSTOMER,
  offices: [[number, string]],
  jobtypes: [[number, string]],
};
interface JobTicketProps {
  id: string,
  // params: { id: string } 
}

export function JobTicket(props: JobTicketProps) {
  const params = useParams<{ id: string }>();
  let navigate = useNavigate();

  const [loading, setLoading] = React.useState(false);
  const [isEditMode, setEditMode] = React.useState(false);

  // const [error, setError] = React.useState<string | null>(null);
  // const [tabValue, setTabValue] = React.useState<'1' | '2' | '3' | '4' | '5'>('1');

  const [job, setJob] = React.useState<JOB | null>(null);
  const [customer, setCustomer] = React.useState<CUSTOMER | null>(null);

  const [offices, setOffices] = React.useState<[[number, string, boolean]]>([[0, 'Loading...',false]]);
  const [jobtypes, setJobtypes] = React.useState<[[number, string]]>([[0, 'Loading...']]);
  const [authorizations, setAuthorizations] = React.useState<[[string, string]]>([['', 'Loading...']]);

  let [searchParams, setSearchParams] = useTSQueryParams({ tab: '1' }, true);
  let tabValue: string = searchParams.get('tab');
  const setTabValue = (newTab: number) => { setSearchParams({ tab: newTab }) };


  // useEffect(() => {
  //   let parsedID = (params.id) ? Number.parseInt(params.id) : Number.NaN;
  //   if (params.id && Number.isInteger(parsedID))
  //     fetchData(parsedID);
  //   else
  //     setError("Unable to process Job#" + params.id);
  // }, [params.id]);

  // const { data, error } = useSWR(params.id, fetchData);
  const { data, error } = useSWR(["getJobCluster", params.id], api.getJobCluster);
  useEffect(() => {
    if (data) {
      setJob(data.job);
      setCustomer(data.customer)
      setOffices(data.offices)
      setJobtypes(data.jobtypes)
      setAuthorizations(data.authorizations)
    } else {
      setJob(null);
      setCustomer(null)
    }
    setLoading(false);
  }, [data]);

  const customerDetails = (!customer) ? "N/A" : `${customer.Customer} \n${customer.BillingAddress} \n${customer.City}, ${customer.State} ${customer.Zip}\nPhone: ${customer.PhNumber} / Fax: ${customer.Fax}`;

  // Configure steps, pull in fields, format dates for display
  let currentStep = 0;
  let steps: [string, Date | null, string | null][] = [];
  if (job) {
    const stepConfig: [string, Date | string | null][] = [['Called', job.CallDate], ['Proposed', job.ProposalDate], ['Accepted', job.ProposalReturnDate], ['Scheduled', job.StartDate], ['Finished', job.CompletionDate || job.KilledDate],];
    steps = stepConfig
      .map((step) => {
        let final: [string, Date | null, string | null] = [step[0], null, null];
        if (step[1]) {
          const d = (step[1] instanceof Date) ? step[1] : new Date(step[1]);
          final[1] = d
          final[2] = (d.getMonth() + 1) + '/' + d.getDate();
          if (new Date().getFullYear() !== d.getFullYear()) {
            final[2] = final[2] + '/' + ((d.getFullYear() > 1970) ? d.getFullYear().toString().slice(-2) : d.getFullYear());
          }
        }
        return final;
      });
    // To find current step, walk backwards and find last entry w/ date.
    for (let i = steps.length - 1; i >= 0; i--) {
      if (steps[i][1] != null) {
        currentStep = i;
        break;
      }
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container>
        <JobMgrHeader >
          {job && <>
            <Typography variant="h6" component="h1">
              Job Ticket #{job.OrderNo} &nbsp;
            </Typography>
            <Typography variant="subtitle1" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }} overflow="hidden" textOverflow="ellipsis">
              &nbsp; {job.JobName}
              {job.sage_export_id && (<Typography fontSize='small'>&nbsp; SageID: {job.sage_export_id} </Typography>)}
            </Typography>
          </>}
          {!job && error && <Typography variant="h6" component="h1">Error loading job ticket...</Typography>}
        </JobMgrHeader>

        {loading && <LinearProgress />}
        {error && <Container><Typography variant="h5" color="red">{error.message}</Typography></Container>}
      </Container>
      {!error && job && customer && authorizations && 
        <>
          <Container>
            <Stepper activeStep={currentStep} alternativeLabel={false} style={{ paddingBottom: 16 }} variant="elevation" sx={{ width: '80%', margin: '0 auto' }}>
              {steps.map((step) => (
                <Step key={step[0]} title={step[0]} >
                  <StepLabel sx={{ textAlign: 'center' }}>{step[0]} {step[1] && (<div>{step[2]}</div>)}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Container>

          <div />
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={tabValue}>
              <Container>
                <Box sx={{ position: 'relative', borderBottom: 1, borderColor: 'divider', overflow: 'hidden' }} style={{ transition: 'max-height 0.3s ease-out', maxHeight: isEditMode ? 0 : 100 }}>
                  {/*  */}
                  <TabList onChange={(e, v) => setTabValue(v)} aria-label="lab API tabs example" variant="fullWidth" centered>
                    <Tab label="Ticket" value="1" />
                    <Tab label="Items" value="2" />
                    <Tab label="Proposal" value="3" />
                    <Tab label="Schedule" value="4" />
                    <Tab label="Job Cost" value="5" onClick={() => { navigate('/app/jobcost/' + params.id) }} icon={<Launch />} iconPosition='end' style={{ minHeight: 'initial' }} />
                  </TabList>
                </Box>
              </Container>
              {/* Tab: Job */}
              <TabPanel value="1">
                <JobTixTicket setEditMode={setEditMode} job={job} offices={offices} jobtypes={jobtypes} customer={customer} authorizations={authorizations} />
                {/* isEditMode={isEditMode}  */}
              </TabPanel>
              <TabPanel value="2">
                {tabValue == "2" && <JobTixItems id={job.OrderNo} setEditMode={setEditMode} />}
              </TabPanel>
              <TabPanel value="3">
                {tabValue == "3" && customer && <JobTixProposal id={job.OrderNo} job={job} customer={customer} />}
              </TabPanel>
              <TabPanel value="4">
                <JobSchedule id={job.OrderNo} />
              </TabPanel>
              <TabPanel value="5">Job Cost - Reports?


                <div style={{ backgroundColor: '#E7EBF0', position: 'sticky', left: 15, padding: '15px', width: 'fit-content' }}>
                  <Paper style={{ width: 'fit-content' }}>
                    <Table sx={{ width: 'initial' }} size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell align="right" style={{ fontWeight: 'bold' }}>Proposed</TableCell>
                          <TableCell></TableCell>
                          <TableCell align="right" style={{ fontWeight: 'bold' }}>Actual</TableCell>
                          <TableCell></TableCell>
                          <TableCell align="right" style={{ fontWeight: 'bold' }}>Difference</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>Rate</TableCell>
                          <TableCell align="right" style={{ color: 'green' }}>$95100</TableCell>
                          <TableCell align="right">&nbsp;-&nbsp;</TableCell>
                          <TableCell align="right" style={{ color: 'green' }}>$95025</TableCell>
                          <TableCell align="right">&nbsp;=&nbsp;</TableCell>
                          <TableCell align="right" style={{ color: 'green' }}>$75</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Cost</TableCell>
                          <TableCell align="right" style={{ color: 'green' }}>$7500</TableCell>
                          <TableCell align="right">&nbsp;-&nbsp;</TableCell>
                          <TableCell align="right" style={{ color: 'green' }}>$60853.65</TableCell>
                          <TableCell align="right">&nbsp;=&nbsp;</TableCell>
                          <TableCell align="right" style={{ color: 'red' }}>$-53353.65</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Profit</TableCell>
                          <TableCell align="right" style={{ color: 'green' }}>$87600</TableCell>
                          <TableCell align="right">&nbsp;-&nbsp;</TableCell>
                          <TableCell align="right" style={{ color: 'green' }}>$34171.35</TableCell>
                          <TableCell align="right">&nbsp;=&nbsp;</TableCell>
                          <TableCell align="right" style={{ color: 'green' }}>$53428.65</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Paper>
                </div>


              </TabPanel>
            </TabContext>
          </Box>
        </>}

    </LocalizationProvider >
  );
}

export const withRouter = (Component: React.ComponentType<any>) => {
  const WithRouter = (props: any) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return <Component {...props} location={location} navigate={navigate} params={params} />;
  }
  return WithRouter;
}

export default withRouter(JobTicket);