import { Link as RouterLink, Route, Routes } from "react-router-dom";

import LienExportPreview from './LienExportPreview';
import LienExportEdit from './LienExportEdit';

export default function LienExport() {

  return (
    <Routes>
      <Route path="preview" element={<LienExportPreview />} />
      <Route index element={<LienExportEdit />} />
    </Routes>
  );
}
