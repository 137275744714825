import { useEffect, useRef, useState } from 'react';
import * as api from './apiCalls';
import equal from 'fast-deep-equal/react';
import usePrompt from '../utils/usePrompt';

import { Button, Typography, LinearProgress, Link, TableContainer, Stack, Box, FormControl, InputLabel, Select, MenuItem, Container } from '@mui/material';
import { Grid, Paper } from '@mui/material';

import ReplayIcon from '@mui/icons-material/Replay';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PeopleIcon from '@mui/icons-material/People';
import AddIcon from '@mui/icons-material/Add';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { asDollars, sageCodes, sagecodetotext } from '../utils/Functions';
import { JC_ROW, JOB_ITEM_ROW } from './Types';
import { JTItemRow } from './JTItemRow';
import { Edit } from '@mui/icons-material';

export type EDIT_JC_ROW = JC_ROW & { delete: boolean };

function JobTixItems(props: { id: number, setEditMode: (editMode: boolean) => void }) {
  const [isBusy, setBusy] = useState(false);
  const [srcRows, setSrcRows] = useState<JC_ROW[]>([]);
  // const [editRows, setEditRows] = useState<EDIT_JC_ROW[] | null>(null);
  const [isEditMode, setInternalEditMode] = useState(false);
  const [isUnsavedChanges, setUnsavedChanges] = useState(false);
  usePrompt("Unsaved changes, are you sure you want to leave?", isUnsavedChanges);

  const editRowsRef = useRef<{ [key: number]: EDIT_JC_ROW } | null>(null);

  // const isEditMode = (editRows != null);

  const setEditMode = (editMode: boolean) => {
    if (editMode) {
      let copyRows: { [key: number]: EDIT_JC_ROW } = {};
      for (let i = 0; i < srcRows.length; i++) {
        copyRows[srcRows[i].row_id] = { delete: false, ...srcRows[i] };
        //idx: i, 
      }
      editRowsRef.current = copyRows;
    } else {
      editRowsRef.current = null;
      setUnsavedChanges(false);
    }
    props.setEditMode(editMode);
    setInternalEditMode(editMode);
  };

  useEffect(() => {
    loadJobCostItems();
  }, [props.id]);

  const loadJobCostItems = () => {
    setBusy(true);
    api.getJobCostItems("getJobCostItems", props.id)
      .then((json) => {
        setSrcRows(json.jc_rows);
        setEditMode(false);
        return json.jc_rows;
      })
      .catch((error) => {
        console.error("ERROR in looking up jobcost items: ", error);
      }).finally(() => {
        setBusy(false);
      })
  }

  const filterEqUn = (row: EDIT_JC_ROW) => row.jobcost_type === 'equip' || row.jobcost_type === 'unique';

  const isChanged = () => {
    if (editRowsRef.current == null)
      return false;

    let srcRowsAsMap: { [key: number]: EDIT_JC_ROW } = {};
    for (let i = 0; i < srcRows.length; i++) {
      srcRowsAsMap[srcRows[i].row_id] = { delete: false, ...srcRows[i] };
    }

    const isChanged = !equal(srcRowsAsMap, editRowsRef.current);
    console.log('isChanged', isChanged, Object.values(srcRowsAsMap).filter(filterEqUn), Object.values(editRowsRef.current).filter(filterEqUn));
    return isChanged;
  }

  const notifyChange = (row_id: number) => {
    console.log("notifyChange", row_id, (editRowsRef.current) ? editRowsRef.current[row_id] : null);
    setUnsavedChanges(isChanged());
  }

  const saveChanges = async () => {
    if (editRowsRef.current != null) {
      let editRows = Object.values(editRowsRef.current).filter((row) => row.jobcost_type === 'equip' || row.jobcost_type === 'unique');
      if (editRows.length > 0) {
        await api.saveJobItems(editRows);
        loadJobCostItems();
      }
    }
  }

  const nextSmallestNewID = () => {
    if (editRowsRef.current) {
      let numKeys = Object.keys(editRowsRef.current).map(Number);
      if (numKeys.length > 0) {
        let smallestKey = Math.min(...numKeys);
        let nextNewID = (smallestKey >= 0) ? -1 : smallestKey - 1;
        return nextNewID;
      }
    }
    return -1;
  }
  const recentCostCode = () => {
    if (editRowsRef.current) {
      let values: EDIT_JC_ROW[] = Object.values(editRowsRef.current);
      console.log('values', values.length, values );
      for (let i=values.length; i>0; i--){
        console.log('recentCostCode', i, values[i-1].costcode, (values[i-1].costcode) );
        if (values[i-1].costcode)
          return values[i-1].costcode;
      }
    }
    return '0';
  }

  const addNewUnique = () => {
    if (editRowsRef.current) {
      setBusy(true);
      let newID = nextSmallestNewID();
      editRowsRef.current[newID] = {
        order_id: '' + props.id, row_id: newID, jobcost_type: 'unique', qty: 0, costcode: recentCostCode(), name: '', info: '',
        cost: null, rate: null, eqid: null, unit_type: null,
        version: '', equip_type: null, show_proposal: 0, info_on_proposal: 0,
        delete: false,
        unit_type_abbr: null, unit_type_name: null, equipmentname: null, // <-- this row used for display, but applicable in edit
      };


      // order_id: string,
      // row_id: number,
      // jobcost_type: "equip" | "unique" | "additional" | "extracrew" | "extratruck",
      // qty: number,
      // cost: null | number,
      // rate: null | number,
      // name: string,
      // eqid: null | number,
      // info: null | string,
      // version: string,
      // equip_type: null | number,
      // costcode: string,
      // unit_type: null | number,
      // unit_type_abbr: null | string,
      // unit_type_name: null | string,
      // equipmentname: null | string,

      setTimeout(()=>{
        setBusy(false); // This should re-render
      }, 150)
      
    }
  }
  const addNewEquip = () => { }



  const editRows = editRowsRef.current;
  const rows = (editRows) ? Object.values(editRows) : srcRows;

  return (<>
    <Container maxWidth={isEditMode ? 'xl' : undefined}>
      <Grid container spacing={2}>
        {isBusy && <Grid item xs={12}><LinearProgress /></Grid>}

        {!isEditMode && <>
          <Grid key="notEditMode" item xs={12}>
            <Button startIcon={<Edit />} variant="contained" onClick={() => { setEditMode(true) }} style={{ marginLeft: 5, float: 'right' }}>Edit</Button>
            <Button startIcon={<ReplayIcon />} variant="outlined" onClick={() => { alert('To be done') }} style={{ float: 'right' }}>
              {"SageID: " + (props.id || "N/A")}
              {/* .sage_export_id */}
            </Button>
            <Typography variant="h4">Proposal Items</Typography>
          </Grid>
        </>}
        {isEditMode && <>
          <Grid key="editMode" item xs={12} component={Paper} elevation={2} square={true} sx={{ position: 'sticky', top: 64, zIndex: 1, padding: 1, backgroundColor: 'white' }}>
            <Stack direction="row" spacing={1}>
              <Typography variant="h4">Edit Proposal Items</Typography>
              <Box sx={{ flexGrow: 1 }}></Box>
              <Button variant="outlined" style={{ marginLeft: 5, float: 'right' }} onClick={() => { setEditMode(false) }}>Cancel</Button>
              <Button variant="contained" style={{ float: 'right' }} disabled={!isUnsavedChanges} onClick={() => { saveChanges() }}>Save</Button>
            </Stack>
          </Grid>
        </>}
        
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              {/* sx={{ minWidth: 650, width: "max-content" }}  */}
              <TableHead>
                <TableRow>
                  <TableCell>ServiceCode</TableCell>
                  <TableCell style={{ minWidth: 200 }}>Job Item</TableCell>
                  <TableCell align="right" style={{ minWidth: 100 }}>Info</TableCell>
                  <TableCell align="center" width={50}>Info OnProp</TableCell>
                  <TableCell align="right">Unit Type</TableCell>
                  <TableCell align="right" width={150} style={{ minWidth: 100 }}>Cost</TableCell>
                  <TableCell align="right" width={150} style={{ minWidth: 100 }}>Rate</TableCell>
                  <TableCell align="right" width={150} style={{ minWidth: 100 }}>Units</TableCell>
                  <TableCell align="right" width={50}>Subtotal</TableCell>
                  <TableCell align="center" width={50}>Show On Prop</TableCell>
                  {isEditMode && <TableCell></TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>

                {rows.filter((row) => row.jobcost_type === 'equip' || row.jobcost_type === 'unique').map((row: JC_ROW, idx: number) => (
                  <JTItemRow key={row.row_id} row={row} editRow={editRows ? editRows[row.row_id] : null} isEditMode={isEditMode} notifyChange={notifyChange} />
                ))}{/* End for every row... */}
                {isEditMode && (
                  <TableRow key='addNewRow'>
                    <TableCell colSpan={11} align="right">
                      <Button variant='outlined' color='primary' startIcon={<AddIcon />} onClick={addNewUnique}>
                        Add Unique Row
                      </Button>
                      &nbsp;
                      <Button variant='outlined' color='primary' startIcon={<AddIcon />} onClick={addNewEquip}>
                        Add Equip Row
                      </Button>
                    </TableCell>
                  </TableRow>
                )}

                <TableRow key='subtotals'>
                  {/* <TableCell component="th" scope="row">{row.costcode}</TableCell> */}
                  <TableCell colSpan={4}></TableCell>
                  <TableCell align='right' style={{ fontWeight: 'bold' }}>Subtotals:</TableCell>
                  <TableCell align="right" style={{ fontWeight: 'bold' }}>{asDollars(
                    rows.filter((row) => row.jobcost_type === 'equip' || row.jobcost_type === 'unique')
                      .reduce((prev, curr) => prev + (curr.cost != null ? curr.qty * curr.cost : 0), 0)
                  )}</TableCell>
                  <TableCell align="right" style={{ fontWeight: 'bold' }}>{asDollars(
                    rows.filter((row) => row.jobcost_type === 'equip' || row.jobcost_type === 'unique')
                      .reduce((prev, curr) => prev + (curr.rate != null ? curr.qty * curr.rate : 0), 0)
                  )}</TableCell>
                  {/* <TableCell align="right">x</TableCell> */}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer >
        </Grid >

        <Grid item xs={12}><Typography variant="h4">Job Cost Items</Typography></Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 800, width: "max-content" }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell width={50}>Type</TableCell>
                  <TableCell width='100%'>Truck or Crew Name</TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  {/* <TableCell align="right">Delete</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.filter((row) => row.jobcost_type === 'extratruck').map((row: JC_ROW) => (
                  <TableRow key={row.row_id}>
                    <TableCell >{(row.jobcost_type === 'extratruck') && <LocalShippingIcon />}</TableCell>
                    <TableCell colSpan={1}><Link href={"/equipment.php?id=" + row.eqid}>{row.name}</Link></TableCell>
                  </TableRow>
                ))}
                {rows.filter((row) => row.jobcost_type === 'extracrew').map((row: JC_ROW) => (
                  <TableRow key={row.row_id}>
                    <TableCell>{(row.jobcost_type === 'extracrew') && <PeopleIcon />}</TableCell>
                    <TableCell colSpan={1}>{row.name}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell width={50} component="th" style={{ fontWeight: 'bold' }}>Type</TableCell>
                  <TableCell width='100%' component="th" style={{ fontWeight: 'bold' }}>Job Item</TableCell>
                  <TableCell align="right" component="th" style={{ fontWeight: 'bold' }}>Cost</TableCell>
                  <TableCell align="right" component="th" style={{ fontWeight: 'bold' }}>Rate</TableCell>
                  <TableCell align="right" component="th" style={{ fontWeight: 'bold' }}>Units</TableCell>
                  <TableCell align="right" component="th" style={{ fontWeight: 'bold' }}>Subtotal</TableCell>
                  {/* <TableCell align="right">Delete</TableCell> */}
                </TableRow>
                {rows.filter((row) => row.jobcost_type === 'additional').map((row: JC_ROW) => (
                  <TableRow
                    key={row.row_id}
                  // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    {/* <TableCell component="th" scope="row">{row.costcode}</TableCell> */}
                    <TableCell><AddIcon /></TableCell>
                    <TableCell>{row.name}</TableCell>
                    {/* -/- {row.equipmentname} */}
                    {/* {row.unit_type} */}
                    <TableCell align="right">{asDollars(row.cost)}</TableCell>
                    <TableCell align="right">{asDollars(row.rate)}</TableCell>
                    <TableCell align="right">{row.qty}</TableCell>
                    <TableCell align="right">{asDollars((row.qty || 0) * (row.rate || 0))}</TableCell>
                    {/* <TableCell align="right">x</TableCell> */}
                  </TableRow>
                ))}
                <TableRow key='subtotals'>
                  {/* <TableCell component="th" scope="row">{row.costcode}</TableCell> */}
                  <TableCell colSpan={2}></TableCell>
                  <TableCell align='right' style={{ fontWeight: 'bold' }}>Subtotals:</TableCell>
                  <TableCell align="right" style={{ fontWeight: 'bold' }}>{asDollars(
                    rows.filter((row) => row.jobcost_type === 'additional')
                      .reduce((prev, curr) => prev + (curr.cost != null ? curr.qty * curr.cost : 0), 0)
                  )}</TableCell>
                  <TableCell align="right" style={{ fontWeight: 'bold' }}>{asDollars(
                    rows.filter((row) => row.jobcost_type === 'additional')
                      .reduce((prev, curr) => prev + (curr.rate != null ? curr.qty * curr.rate : 0), 0)
                  )}</TableCell>
                  {/* <TableCell align="right">x</TableCell> */}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>


      </Grid >
    </Container>
  </>);
}
export default JobTixItems;