import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import debounce from '@mui/utils/debounce';
import useSWR from 'swr';
import * as api from '../jobtix/apiCalls';
import { SxProps } from '@mui/material';

interface JobList {
  id: number;
  label: string;
}

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

function joinLabel(cust: JobList): string {
  if (cust.id >= 0 && (cust.label == ''))
    return '#' + cust.id;
  else if (cust.id < 0 && cust.label != '')
    return cust.label;
  else
    return '#' + cust.id + ': ' + cust.label;
}

export default function AutoJobTicket(props: { label: string, value: null | number, readonly: boolean, onChange: (value: string | number | null) => void, error?: boolean, sx?: SxProps<any>, fullWidth?: boolean }) {
  // const [value, setValue] = React.useState<number | null>(null);
  const value = Number.isNaN(props.value) ? null : props.value;
  const [options, setOptions] = React.useState<readonly JobList[]>([{ id: -1, label: 'Type to search by job number or name' }]);
  const [loading, setLoading] = React.useState(false);

  const swr = useSWR(['getJobCluster', value], api.getJobCluster);
  const jobCluster = swr.data;
  const optionValue = (value) ? { id: value || -1, label: jobCluster?.job.JobName || "Loading job details3..." } : null;

  React.useEffect(() => {
    if (jobCluster)
      setOptions([{ id: jobCluster.job.OrderNo, label: jobCluster.job.JobName || "Loading job details2..." }]);
  }, [value, jobCluster])

  const fetchData = async (toSearch: string): Promise<void | JobList[]> => {
    // console.log("in fetch, looking for url params: ", this.props.params.id);
    return axios.get('/api/json.php', { params: { a: 'lookup', t: 'jobsbynameornumber', query: toSearch } })
      .then((resp) => resp.data as [[number, string]])
      .then((json) => {
        console.log('/api/json.php lookup jobsbynameornumber RSP:', json);

        let jobList: JobList[] = json.map((jobLst) => { return { id: jobLst[0], label: jobLst[1] } });
        return jobList;
      })
      .catch((error) => {
        console.error("ERROR in looking up jobs: ", error);
      });
  }

  const handleTextChange = async (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    let jobList = await fetchData(event.target.value);
    console.log('jobList', jobList)
    if (jobList)
      setOptions(jobList);
    setLoading(false);
  }
  const debouncedChangeHandler = React.useMemo(
    () => debounce(handleTextChange, 300)
    , []);
  React.useEffect(() => {
    return () => { debouncedChangeHandler.clear() }
  }, [])

  return (
    <Autocomplete
      fullWidth={(props.fullWidth !== undefined) ? props.fullWidth : true}
      onChange={(e, v, r, d) => {
        //console.log('auto onChange: ', e, v, r, d); 
        props.onChange(v ? '' + v.id : null);
      }}
      isOptionEqualToValue={(option, value) => option?.id === value.id}
      getOptionLabel={joinLabel}
      options={options}
      getOptionDisabled={(opt) => opt.id < 0}
      loading={loading}
      value={optionValue}
      handleHomeEndKeys={false}
      readOnly={props.readonly}
      placeholder='Type to search for job by number or name'
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          variant="standard"
          error={props.error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          onChange={(e) => { setLoading(true); debouncedChangeHandler(e) }}
        />
      )}
      selectOnFocus
      // clearOnBlur
      // This disabled the native filtering, since we are pulling from a server
      filterOptions={(x) => x}
      sx={props.sx}
    />
  );
}
