import { Divider, ListItemText, ListItemIcon, Menu, MenuItem, PaperProps, Paper } from "@mui/material";
import { COL, JC_JOB_PART, JOBCOST_BILL_COLS } from "../jobtix/Types";

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import BillingHoldIcon from '@mui/icons-material/MoneyOff';
import BillingQueuedIcon from '@mui/icons-material/AttachMoney';
import BilledIcon from '@mui/icons-material/PriceCheck';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import Draggable from 'react-draggable';

export default function ColumnDateHeaderMenu(props: {
  colClickMenuEl: null | HTMLElement,
  colClickMenu: null | COL,
  colBillStatus: undefined | JOBCOST_BILL_COLS,
  wrDates: string[],
  onOpenDateChange: (col: COL) => void,
  onBillingStatusChange: (col: COL, newStatus: 0 | 1) => void,
  closeMenu: (mustBeNull: null) => void,
  openWRForDate: (date: string) => void,
  job: JC_JOB_PART | null,
  disableEdits: boolean,
}) {
  const { colClickMenuEl, colClickMenu } = props;
  const closeMenu = props.closeMenu;
  const billStatus = props.colBillStatus?.status || 0;

  if (colClickMenu == null) {
    return <></>;
  } else {
    return (<>


      <Menu
        anchorEl={colClickMenuEl}
        open={(colClickMenu != null)}
        onClose={() => closeMenu(null)}
      >
        <MenuItem component="a"
          // onClick={() => {
          //   props.openWRForDate(colClickMenu.date); closeMenu(null);
          // }}
          href={"/app2/workreports/" + colClickMenu.order_id + "-" + colClickMenu.date}
          target="_window"
          disabled={props.wrDates.find((d) => colClickMenu.date == d) === undefined}
        >
          <ListItemIcon>
            <DocumentScannerIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>View Work Report</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => { props.onBillingStatusChange(colClickMenu, 0); props.closeMenu(null); }} selected={billStatus === 0} disabled={billStatus >= 2}>
          <ListItemIcon>
            <BillingHoldIcon htmlColor="red" fontSize="small" />
          </ListItemIcon>
          <ListItemText>Hold Billing</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => { props.onBillingStatusChange(colClickMenu, 1); props.closeMenu(null); }} selected={billStatus === 1} disabled={billStatus >= 2}>
          <ListItemIcon>
            <BillingQueuedIcon htmlColor="green" fontSize="small" />
          </ListItemIcon>
          <ListItemText>Queue for Billing</ListItemText>
        </MenuItem>
        <MenuItem selected={billStatus >= 2} disabled={billStatus < 2}>
          <ListItemIcon>
            <BilledIcon htmlColor="green" fontSize="small" />
          </ListItemIcon>
          <ListItemText>Billing Processed</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => { props.onOpenDateChange(colClickMenu); closeMenu(null); }} disabled={billStatus !== 0 || props.disableEdits}>
          <ListItemIcon>
            <CalendarTodayIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Change Date</ListItemText>
        </MenuItem>
      </Menu>
    </>);
  }
}