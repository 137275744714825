import React, { PropsWithChildren, useState } from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps, } from "react-router-dom";

import { AppBar, Collapse, Drawer, IconButton, Theme, Toolbar, Typography } from "@mui/material";
import { Box, SxProps } from "@mui/system";

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import ArrowBack from "@mui/icons-material/ArrowBack";
import MenuIcon from '@mui/icons-material/Menu';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import WorkIcon from '@mui/icons-material/Work';
import ScheduleIcon from '@mui/icons-material/Schedule';
import HomeIcon from '@mui/icons-material/Home';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { ExpandLess, ExpandMore } from "@mui/icons-material";

interface ListItemLinkProps {
  icon?: React.ReactElement;
  primary: string;
  to: string;
  sx?: SxProps<Theme>;
}

function ListItemLink(props: ListItemLinkProps) {
  const { icon, primary, to, sx } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'>>(function Link(
        itemProps,
        ref,
      ) {
        return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />;
      }),
    [to],
  );

  return (
    <li>
      <ListItem button component={renderLink} sx={sx} >
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

export function JobMgrHeader(props: PropsWithChildren<{ backTo?: string }>) {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const [expandReports, setExpandReports] = useState(false);
  const toggleExpandReports = () => {
    setExpandReports((v) => !v);
  }

  return <>
    <AppBar elevation={8}>
      <Toolbar sx={{ whiteSpace: 'nowrap' }}>
        {!props.backTo &&
          <IconButton
            size="small"
            edge="start"
            aria-label="menu"
            sx={{ mr: 2, color: 'white' }}
            onClick={() => setDrawerOpen(true)}
          >
            <MenuIcon />
          </IconButton>
        }
        {props.backTo &&
          <IconButton
            size="small"
            edge="start"
            aria-label="menu"
            sx={{ mr: 2, color: 'white' }}
            component={RouterLink}
            to={props.backTo}
          >
            <ArrowBack />
          </IconButton>
        }

        {props.children}

        <Box sx={{ flexGrow: 1 }}></Box>

      </Toolbar>
    </AppBar>
    <Drawer
      anchor="left"
      open={isDrawerOpen}
      onClose={() => setDrawerOpen(false)}
    >
      <img src={process.env.PUBLIC_URL + '/logo_w250.png'} width="200" style={{ marginTop: 5, marginLeft: 'auto', marginRight: 'auto' }} />
      <List style={{ minWidth: 250 }}>
        <ListItemLink key='Home' to="/" primary="Home" icon={<HomeIcon />} />
        <ListItemLink key='jobs' to="/app/jobtix/" primary="Jobs" icon={<WorkIcon />} />
        <ListItemLink key='sched' to="/jobboard/#search=&office=&groupby=" primary="Schedule" icon={<ScheduleIcon />} />
        <ListItemLink key='sagelog' to="/app/sagelog/" primary="Sage Log" icon={<AssessmentIcon />} />
        <ListItemButton onClick={toggleExpandReports}>
          <ListItemIcon>
            <SummarizeIcon />
          </ListItemIcon>
          <ListItemText primary="Reports" />
          {expandReports ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={expandReports} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemLink key='sagelog' to="/app/report/sagelog/" primary="Sage Log" icon={<SummarizeIcon />} sx={{ pl: 4 }} />
            <ListItemLink key='jobstart' to="/app/report/jobstart/" primary="Job Start Date" icon={<SummarizeIcon />} sx={{ pl: 4 }} />
            <ListItemLink key='jobswithsage' to="/app/report/jobwithsage/" primary="Job With Sage" icon={<SummarizeIcon />} sx={{ pl: 4 }} />
            <ListItemLink key='jobswithsage' to="/app/report/truckrate/" primary="Truck Rate" icon={<SummarizeIcon />} sx={{ pl: 4 }} />
            {/* <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary="Starred" />
            </ListItemButton> */}
          </List>
        </Collapse>

        <ListItemButton>
          <ListItemIcon>
            <SummarizeIcon />
          </ListItemIcon>
          <ListItemText primary="Tools" />
          {/* <ExpandLess /> */}
        </ListItemButton>
        <Collapse in={true} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemLink key='lien' to="/app/tools/lien/" primary="Lien Export" icon={<SummarizeIcon />} sx={{ pl: 4 }} />
          </List>
        </Collapse>
      </List>
    </Drawer>
    {/* Extra toolbar for spacing */}
    <Toolbar style={{ marginBottom: 16 }} />
  </>;
}
export default JobMgrHeader;