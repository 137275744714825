import React, { useState, useEffect, useMemo, ChangeEvent, useRef } from 'react';
import * as api from '../jobtix/apiCalls';
import axios from 'axios';

import * as datefns from 'date-fns';
import * as rcsv from 'react-csv';

import JobMgrHeader from '../utils/JobMgrHeader';
import { Link as RouterLink } from "react-router-dom";


import { Container } from '@mui/system';
import { LinearProgress, Typography, Link, Box, Checkbox, FormGroup, FormControlLabel, Button, Alert, AlertTitle, debounce, Tabs, Tab } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { z } from 'zod';
import { AsyncClickHandler } from 'react-csv/components/CommonPropTypes';
import { CheckBox as CheckBoxIcon } from '@mui/icons-material';
import { LIEN_DATA, LIEN_DATA_ZOD } from './LienExportEdit';

/** Used to detect multi-requests at the same time, use the last one */
let currentProcessCount = 0;

//le.orderno, le.start, le.status, le.exported, le.export, jt.JobName, cust.CustomerNo, cust.Customer, cust.nolien

const EXPORT_HEADERS: { label: string, key: keyof LIEN_DATA | "" }[] = [
  { label: "Customers Company Name", key: "Customer" },
  { label: "Customers Contact Name", key: "" },
  { label: "Customers Address", key: "CustAddress" },
  { label: "Customers City", key: "CustCity" },
  { label: "Customers State", key: "CustState" },
  { label: "Customers Postal Code", key: "CustZip" },
  { label: "Customer Phone Number", key: "CustPhNumber" },
  { label: "Credit Approved", key: "" },
  { label: "Job Name", key: "JobName" },
  { label: "Job Address", key: "JobAddress" },
  { label: "Job City", key: "JobCity" },
  { label: "Job State", key: "JobState" },
  { label: "Job Zip", key: "JobZip" },
  { label: "First Day On The Job", key: "start" },
  { label: "Account Number", key: "orderno" },
];


export default function LienExportPreview() {
  // Results from query data coming in & display data
  const [error, setError] = useState<string | null>();
  const [nonBlockingError, setNonBlockingError] = useState<string | null>();
  const [isBusy, setBusy] = React.useState(false);

  const [exportData, setExportData] = useState<(Omit<LIEN_DATA, 'start'> & { start: string | Date })[] | null>(null);
  const [exportDone, setExportDone] = React.useState(false);

  const [rerender, setRerender] = React.useState(0);
  const debounceRerender = debounce(() => setRerender((i) => i + 1), 500);

  const performQuery = () => {
    currentProcessCount++;
    let thisPCount = currentProcessCount;

    // console.log("jsonFetcher() start:", params);
    setBusy(true);
    axios.get('/api/lienreport.php', { params: { a: 'get', t: 'report', status: 2 } })
      .catch(api.catchUnauthorized)
      .catch((error) => { setError(JSON.stringify(error)) })
      .then((resp) => resp.data)//as LIST_RESP<DATA>
      .then((json) => {
        // console.log("jsonFetcher() complete:", params);

        // If another fetch is already in progress, abort this one
        if (currentProcessCount != thisPCount) {
          console.log('ABORTED');
          return;
        }

        let rawData: LIEN_DATA[] = json.data.map((d: any) => { return LIEN_DATA_ZOD.parse(d) });

        setExportData(rawData
          .filter((r) => r.status === 2)
          .map((r) => {
            let nr: Omit<LIEN_DATA, 'start'> & { start: Date | string } = Object.assign({}, r);
            if (r.start) { nr.start = datefns.format(r.start, "MM/dd/yy") };
            nr.JobName = r.JobName.replaceAll("\"", "\"\"");
            return nr;
          })
        );

      })
      .finally(() => {
        setBusy(false);

      });
  };

  useEffect(() => {
    performQuery();
  }, [])

  const performPreExport: AsyncClickHandler = async (event, done) => {
    // setNonBlockingError("API issues... something didn't work");

    let dataToExport = exportData || [];

    setBusy(true);
    axios.post('/api/lienreport.php', { a: 'export', data: dataToExport.map((d) => d.orderno) }, { params: { a: 'export' } })
      .catch(api.catchUnauthorized)
      .catch((error) => { done(false); setError(JSON.stringify(error)) })
      .then((resp) => resp.data)//as LIST_RESP<DATA>
      .then((json) => {
        console.log("onSave - resp:", json);

        done(true)
        setExportDone(true);
        // performQuery();
      })
      .finally(() => {
        setBusy(false);
      });




    // If we have something that works, re-query data for updates
  }

  return (<>
    <JobMgrHeader>
      <Typography variant="h6" component="h1">
        <RouterLink to="/app/tools/lien/" style={{ color: 'white', textDecoration: 'none' }}>Lien Export Tool</RouterLink> - Export
      </Typography>
    </JobMgrHeader>

    <Container>

      {nonBlockingError &&
        <Alert severity="error" style={{ marginBottom: '8px' }}>
          <AlertTitle>Export Error</AlertTitle>
          {nonBlockingError}
        </Alert>
      }
      {error && <Typography>ERROR: {error}</Typography>}
      {isBusy && <Typography>Loading... <LinearProgress /></Typography>}


      <Tabs value={1} onChange={() => { }} >
        <Tab
          component={RouterLink}
          label="Edit" to="/app/tools/lien"
        />
        <Tab
          component={RouterLink}
          label="Export" to="/app/tools/lien/preview"
        />
      </Tabs>

      {!error && exportData && <>
        <Box sx={{ float: 'right', paddingBottom: 1 }}>
          {exportData && exportData.length > 0 &&
            <rcsv.CSVLink
              style={{ fontSize: '14px', display: (exportDone) ? "none" : undefined }}
              filename={"lienexport-" + new Date().toISOString().split('T')[0] + ".csv"}
              data={exportData}
              headers={EXPORT_HEADERS}
              target="_blank"
              asyncOnClick={true}
              onClick={performPreExport}
            >
              <Button variant='contained'>{exportData.length} Records Processed: Confirm & Download</Button>
            </rcsv.CSVLink>
          }

          {((exportData && exportData.length === 0) || exportDone) &&
            <Button component={RouterLink} to="/app/tools/lien/" variant="outlined" style={{ marginLeft: '8px' }}>
              Return to Export Selection
            </Button>
          }


          {/* <RouterLink to="./" style={{ color: 'white', textDecoration: 'none' }}></RouterLink> */}

        </Box>

        {exportData &&
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell key={'jobno'}>Job#</TableCell>
                  {EXPORT_HEADERS.map((col, idx) => <TableCell key={idx}>{col.label}</TableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                {exportData.map((row) => {

                  return (
                    <TableRow key={row.orderno} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell key={'jobno'}>{row.orderno}</TableCell>
                      {EXPORT_HEADERS.map((col, idx) =>
                        <TableCell key={idx}>{col.key != "" && row[col.key]}</TableCell>
                      )}
                    </TableRow>
                  )
                })}


              </TableBody>
            </Table>
          </TableContainer>
        }





        {/* <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Export</TableCell>
                <TableCell>Job#</TableCell>
                <TableCell>Job Name</TableCell>
                <TableCell sx={{ textWrap: 'nowrap' }}> Job Start</TableCell>
                <TableCell>Customer</TableCell>
                <TableCell>NoNotice</TableCell>

                <TableCell>Status</TableCell>
                <TableCell>Export</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => {

                return (
                  <TableRow
                    key={row.orderno}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >

                    <TableCell>
                      <CheckBoxIcon />
                    </TableCell>
                    <TableCell>{row.orderno}</TableCell>
                    <TableCell><Box style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{row.JobName}</Box></TableCell>
                    <TableCell sx={{ textWrap: 'nowrap' }}>{row.start.toLocaleDateString()} </TableCell>
                    <TableCell><Box style={{ overflow: "hidden", textOverflow: "ellipsis" }}><Link component={RouterLink} to={"/customer.php/id=" + row.CustomerNo}> {row.Customer}</Link></Box></TableCell>
                    <TableCell>{row.nolien}</TableCell>

                    <TableCell>{row.status}</TableCell>
                    <TableCell>{row.exported ? row.exported : "N/A"}</TableCell>

                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer> */}
      </>
      }
    </Container>
  </>);
}
