import * as React from 'react';
import { Routes, Route, Link, BrowserRouter } from 'react-router-dom';


import { Box, Button, Input, InputProps, InputBase, Typography, CssBaseline, AppBar, Toolbar, IconButton, Slide } from '@mui/material';
import useScrollTrigger from '@mui/material/useScrollTrigger';

import { styled } from '@mui/material/styles';
import styles from './App.module.css';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import JobCost from './jobcost/JobCost';
import JobBoard from './jobboard/JobBoard';
import JobTicket from './jobtix/JobTicket';
import JobList from './jobtix/JobList';
import { SageLog } from './reports/SageLog';
import ReportJobStart from './reports/ReportJobStart';
import ReportJobsWithSage from './reports/ReportJobsWithSage';
import ReportTruckRate from './reports/ReportTruckRate';
import LienExport from './tools/LienExport';
import LeinRedirect from './tools/LeinRedirect';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, backgroundColor: "grey" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}



function HideOnScroll(props: any) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}


export default function App(props: any) {

  // React.useEffect(() => {
  //   fetch('/api/json.php?id=24363')
  //     .then((response) => response.json())
  //     .then((data) => console.log(data))
  //     .catch((error) => console.log(error.message));

  //   console.log();
  // }, []);

  const trigger = useScrollTrigger({
    // disableHysteresis: true,
    threshold: 0,
  });

  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <React.Fragment>
      <CssBaseline />
      {/* <Box id="shen-top-header" sx={{ flexGrow: 1 }}>
        <Slide appear={false} direction="down" in={!trigger}>
          <div>
            <AppBar elevation={trigger ? 8 : 0}>
              <Toolbar sx={{ whiteSpace: 'nowrap' }}>
                <Typography variant="h6" component="h1">Job Manager &nbsp; </Typography>
                <Typography variant="subtitle2" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }} overflow="hidden" textOverflow="ellipsis">&nbsp; Not used at the moment</Typography>
                <Box sx={{ flexGrow: 1 }}></Box>
                <Button variant="contained" color="warning" onClick={() => window.alert('Not yet implemented')}>Insert Login Status Here</Button>
              </Toolbar>
            </AppBar>
            <Toolbar />
          </div>
        </Slide>
      </Box> */}

      <BrowserRouter>
        <Routes>
          <Route path="app/jobcost/:orderid" element={<JobCost />} />
          <Route path="app/jobcost/" element={<h1>Missing Order No</h1>} />
          <Route path="app/jobboard/" element={<JobBoard />} />
          <Route path="app/jobtix/" element={<JobList />} />
          <Route path="app/jobtix/:id" element={<JobTicket />} />
          <Route path="app/report/sagelog/" element={<SageLog />} />
          <Route path="app/report/jobstart/" element={<ReportJobStart />} />
          <Route path="app/report/jobwithsage/" element={<ReportJobsWithSage />} />
          <Route path="app/report/truckrate/" element={<ReportTruckRate />} />
          <Route path="app/tools/lein/*" element={<LeinRedirect />} />
          <Route path="app/tools/lien/*" element={<LienExport />} />

          {/* <h1>Missing Order ID</h1> */}
          <Route path="*" element={<h1>Not implemented at this time. <a href="/">Click here to continue.</a></h1>} />
          {/* index */}
        </Routes>
      </BrowserRouter>


      {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
          <Tab label="Job Cost" {...a11yProps(0)} />
          <Tab label="Job Ticket" {...a11yProps(1)} />
          <Tab label="Charts Demo" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <DenseTable />
      </TabPanel>
      {/ * @ts-ignore-nextline * /}
      <TabPanel value={tabValue} index={1} style={{backgroundColor: 'lightgrey', height: '100%'}}>
          <JobTicket />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <ChartExamples />
      </TabPanel> */}


    </React.Fragment>
  );
}
