import { useSearchParams } from "react-router-dom";

export class SearchParamsWrapper<T extends { [key: string]: string | number }> {
  private _src: URLSearchParams;
  private _defaults: T;
  constructor(src: URLSearchParams, defaults: T) {
    this._src = src;
    this._defaults = defaults;
  }
  delete(name: keyof T) { this._src.delete(name as string) }
  has(name: keyof T) { return this._src.has(name as string) }
  keys() { return this._src.keys() }
  set(name: keyof T, value: string) {
    if (this._defaults[name] == value)
      this._src.delete(name as string);
    else
      this._src.set(name as string, value)
  }
  get<K extends keyof T, R extends T[K]>(name: K): R {
    let value = this.getOrNull(name);
    if (value === null) {
      return this._defaults[name] as R;
    } else {
      return value as R;
    }
  }
  getOrNull<K extends keyof T, R extends T[K]>(name: K): R | null {
    let value = this._src.get(name as string);
    if (value != null && typeof this._defaults[name] === 'number') {
      return Number.parseFloat(value) as R;
    } else {
      return value as R;
    }
  }
}

export default function useTSQueryParams<T extends { [key: string]: string | number }>(defaults: T, replaceDefault: boolean = true): [SearchParamsWrapper<T>, (nextInit: Record<string, string | number | undefined | null>, navigateOptions?: {
  replace?: boolean | undefined;
  state?: any;
} | undefined) => void] {
  let [searchParams, setSearchParams] = useSearchParams();

  const mySearchParams = new SearchParamsWrapper(searchParams, defaults);

  const mySetSearchParams = (nextInit: Record<string, string | number | undefined | null>, navigateOptions?: {
    replace?: boolean | undefined;
    state?: any;
  } | undefined): void => {
    let newParams = Object.fromEntries(searchParams.entries());
    for (let [key, value] of Object.entries(nextInit))

      if (value == defaults[key]) {
        delete newParams[key];
      } else if (value === null || value === undefined) {
        // console.log("useMySearchParams - delete ", key);
        delete newParams[key];
      } else if (typeof value === 'number') {
        newParams[key] = '' + value;
      } else {
        // console.log("useMySearchParams - set ", key, value);
        newParams[key] = value;
      }

    if (navigateOptions)
      setSearchParams(newParams, navigateOptions);
    else
      setSearchParams(newParams, { replace: replaceDefault });
  }

  return [mySearchParams, mySetSearchParams];
}
