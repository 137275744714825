import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import debounce from '@mui/utils/debounce';
import useSWR from 'swr';
import * as api from '../jobtix/apiCalls';
import { SxProps } from '@mui/material';

export default function JobName(props: { id: string, prefix?: string }) {
  let parsed = Number.parseInt(props.id);
  
  const jobNo = (Number.isNaN(parsed)) ? null : parsed;
  const prefix = props.prefix || "";

  const [name, setName] = React.useState<string>("");

  const swr = useSWR(['getJobCluster', jobNo], api.getJobCluster);
  const jobCluster = swr.data;

  React.useEffect(() => {
    if (jobCluster)
      setName(prefix + jobCluster.job.JobName);
    else
      setName("");
  }, [jobNo, jobCluster])

  return (
    <span>{name}</span>
  );
}
