import React from 'react';
import { Link as RouterLink, useSearchParams, URLSearchParamsInit } from "react-router-dom";
import axios from 'axios';
import useSWR from 'swr'
import useTSQueryParams from '../utils/useTSQueryParams';

import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import JobMgrHeader from '../utils/JobMgrHeader';
import { Badge, Button, Container, Divider, FormControl, InputLabel, LinearProgress, Link, ListItemIcon, ListItemText, MenuItem, MenuList, Popover, Select, TextField } from '@mui/material';

import CancelPresentationRoundedIcon from '@mui/icons-material/CancelPresentationRounded';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import Looks5Icon from '@mui/icons-material/Looks5';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import FilterListIcon from '@mui/icons-material/FilterList';

import './JobList.css';
import { randomInt } from 'crypto';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { Check } from '@mui/icons-material';

import { tryASDollars } from '../utils/Functions';
import { catchUnauthorized } from './apiCalls';

const THIS_YEAR = new Date().getFullYear();
const NOW = new Date();

export const parseFromISO = (input: string | null) => {
  if (input) {
    let match = input.match(/^(\d{4})-([0]\d|1[0-2])-([0-2]\d|3[01])$/);
    if (match) {
      let d = new Date();
      d.setFullYear(Number.parseInt(match[1]));
      d.setMonth(Number.parseInt(match[2]) - 1);
      d.setDate(Number.parseInt(match[3]));

      if (Number.isNaN(d) == false)
        return d;
    }
  }
  return null;
}
export const formatFromISO = (input: string | null) => {
  let date = parseFromISO(input);
  if (date) {
    let year = (date.getFullYear() === THIS_YEAR) ? "" : "/" + ("" + date.getFullYear()).substring(2);//date.getFullYear();
    input = (date.getMonth() + 1) + "/" + date.getDate() + year;
  }

  return input;
}


export const tryFormatDate = (input: string | null) => {
  let parsedTS = (input != null) ? Date.parse(input) : null;
  if (parsedTS != null && !Number.isNaN(parsedTS)) {
    let date = new Date(parsedTS);
    let year = (date.getFullYear() === THIS_YEAR) ? "" : "/" + ("" + date.getFullYear()).substring(2);//date.getFullYear();
    input = (date.getMonth() + 1) + "/" + date.getDate() + year;
  }
  return input;
}
const toDate = (input: string | null) => {
  let parsedTS = (input != null) ? Date.parse(input) : null;
  if (parsedTS != null && !Number.isNaN(parsedTS)) {
    return new Date(parsedTS);
  } else {
    return null;
  }
}
const toInt = (input: string | number): number => {
  return (typeof input === 'string') ? Number.parseInt(input) : input;
}

type FILTER_QUERY_IN = 'all' | 'jt.OrderNo' | 'jt.sage_export_id' | 'jt.JobName' | 'jt.JobCity' | 'jt.JobAddress' | 'o.Office_Name' | 'jt.Contact' | 'c.Customer';
function toFILTER_QUERY_IN(input: string | number | null): FILTER_QUERY_IN {
  const VALID = ['all', 'jt.OrderNo', 'jt.sage_export_id', 'jt.JobName', 'jt.JobCity', 'jt.JobAddress', 'o.Office_Name', 'jt.Contact', 'c.Customer'];
  return (typeof input === 'string' && VALID.indexOf(input) != -1) ? input as FILTER_QUERY_IN : 'all';
}

type FILTER = {
  stage: number,
  office: number,
  query: string,
  queryIn: FILTER_QUERY_IN;
}
const SEARCH_IN = [
  ["Job#", "jt.OrderNo"],
  ["SageID", "jt.sage_export_id"],
  ["Name", "jt.JobName"],
  ["City", "jt.JobCity"],
  ["Address", "jt.JobAddress"],
  ["Office_Name", "jt.Office_Name"],
  ["Contact", "jt.Contact"],
  ["Customer", "jt.Customer"],
  ["Estimator", "jt.Estimator"],
];
const OFFICES: [number, string][] = [[1, 'Pompano'], [2, 'Ft. Pierce'], [3, 'Ft. Myers'], [4, 'Grout'], [5, 'CIPP Storm'], [6, 'Tampa'], [7, 'CIPP San.']];

const COLUMNS = [
  ["SageID", "jt.sage_export_id"],
  ["Job#", "jt.OrderNo"],
  ["Name", "jt.JobName"],
  ["City", "jt.JobCity"],
  ["Office", "jt.Office"],
  ["Contact", "jt.Contact"],
  ["ProposalDate", "jt.ProposalDate"],
  ["StartDate", "jt.StartDate"],
  ["Stage", "jt.Stage"],
  ["Overdue", "jt.Overdue"], //Date
  ["Office_Name", "jt.Office_Name"],
  ["Customer", "jt.Customer"],
  ["CompletionDate", "jt.CompletionDate"],
  ["KilledDate", "jt.KilledDate"],
  ["Estimator", "jt.Estimator"],
  ["Proposed Rate", "jt.cacheproposedrate"],
  ["Actual Rate", "jt.cacheactualrate"],
  //CompletionDate - Added to Data type, but not as columns yet
  //KilledDate - Added to Data type, but not as columns yet
];
export type Data = [
  string | null,
  number,
  string,
  string,
  number,
  string,
  string | null,
  string | null,
  number,
  string,
  string,
  string,
  string | null,
  string | null,
  string | null,
  number | null,
  number | null,
];
type CONFIG = {
  label: string, sortCol?: string, show: boolean, display: (d: Data, onClick: undefined | ((e: any) => void)) => JSX.Element | null,
  align?: "center" | "left" | "right" | "justify" | "inherit" | undefined
}

const displayDiff = (d1: Date | null, d2: Date | null) => {
  if (d1 == null || d2 == null)
    return null;

  let diff = Math.abs(d1.getTime() - d2.getTime());
  let days = Math.floor(diff / (1000 * 3600 * 24));
  let weeks = Math.floor(days / 7);

  if (weeks > 0) return weeks + "w";
  else if (days > 0) return days + "d";
  else return "<1d";
}

const STAGE2ICON = [null, LooksOneIcon, LooksTwoIcon, Looks3Icon, Looks4Icon, Looks5Icon]
const STAGES = ['Need Call', 'Called', 'Proposed', 'Accepted', 'Scheduled', 'Finished'];
let COLUMN_CONFIG: CONFIG[] = [
  {
    label: "Stage",
    // sortCol: "jt.Stage",
    show: true,
    align: 'center',
    display: (d: Data, onClick: undefined | ((e: any) => void)) => {
      let stage = d[8];
      let overdue = toDate(d[9]);
      let overdueLabel = (overdue == null || overdue >= NOW) ? null : displayDiff(overdue, NOW);

      let out =
        (stage == 0) ? <CancelPresentationRoundedIcon /> :
          (stage == 1) ? <LooksOneIcon style={{ marginLeft: 'auto' }} /> :
            (stage == 2) ? <LooksTwoIcon /> :
              (stage == 3) ? <Looks3Icon /> :
                (stage == 4) ? <Looks4Icon /> :
                  (stage == 5) ? <Looks5Icon /> : '???';

      const title = ((stage == 0) ? 'Need to Call' : STAGES[stage] || "");
      // + ": " + d[2];

      return (
        <TableCell key='Stage' align='center' style={{ color: 'rgb(25, 118, 210)' }} title={title} onClick={onClick}>
          {/* <RouterLink to={'./' + d[1]} className="expandRowLink"></RouterLink> */}
          {/* This sends you back to the old jobtix edit for now... */}
          <a href={'/job.php?id=' + d[1]} className="expandRowLink"></a>
          <Badge color="error" badgeContent={(overdueLabel != null) ? overdueLabel : 0}>
            {out}
          </Badge>
        </TableCell>
      );
    }, //8,9for overdue
  },
  {
    label: "JobNo",
    sortCol: "jt.OrderNo",
    show: true,
    display: (d: Data) =>
      <TableCell key='JobNo' style={{ whiteSpace: 'nowrap' }}>
        <Link component={RouterLink} to={"./" + d[1]}>{d[1]}</Link>
        {d[0] && <div className="sageid">{d[0]}</div>}
      </TableCell>

    // <div style={{whiteSpace: 'nowrap'}}>
    // </div>,

  },
  {
    label: "SageID",
    sortCol: "jt.sage_export_id",
    show: false,
    display: (d: Data, onClick: undefined | ((e: any) => void)) => <TableCell key='SageID' onClick={onClick}>{d[0]}</TableCell>,
  },
  {
    label: "Office",
    sortCol: "o.Office_Name",
    show: false,
    display: (d: Data, onClick: undefined | ((e: any) => void)) => <TableCell key='Office' onClick={onClick}>{d[10]}</TableCell>,
  },
  {
    label: "Estimator",
    sortCol: "o.Estimator",
    show: false,
    display: (d: Data, onClick: undefined | ((e: any) => void)) => <TableCell key='Estimator' onClick={onClick}>{d[14]}</TableCell>,
  },
  {
    label: "City",
    sortCol: "jt.JobCity",
    show: true,
    display: (d: Data, onClick: undefined | ((e: any) => void)) => <TableCell key='City' className="joblistrestrict" onClick={onClick}>{d[3]}</TableCell>,
  },
  // [3, false],//["City", "jt."],
  {
    label: "Customer",
    sortCol: "jt.Customer",
    show: true,
    display: (d: Data, onClick: undefined | ((e: any) => void)) =>
      <TableCell key='Customer' className="joblistrestrict" onClick={onClick}>{d[11]}</TableCell>
    ,
  },
  // [11, true],//["Customer", "jt.Customer"],
  {
    label: "Contact",
    sortCol: "jt.Contact",
    show: false,
    display: (d: Data, onClick: undefined | ((e: any) => void)) => <TableCell key='Contact' className="joblistrestrict" onClick={onClick}>{d[5]}</TableCell>,
  },
  // [5, true],//["", "jt.Contact"],
  {
    label: "Name",
    sortCol: "jt.JobName",
    show: true,
    display: (d: Data, onClick: undefined | ((e: any) => void)) =>
      <TableCell key='Name' style={{ maxWidth: 300 }} className="joblistname" onClick={onClick}>
        <Box title={d[2]} style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{d[2]}</Box>
      </TableCell>,
  },
  // [2, true],//["Name", "jt.JobName"],
  {
    label: "Start",
    sortCol: "jt.StartDate",
    show: true,
    align: 'center',
    display: (d: Data, onClick: undefined | ((e: any) => void)) => (<TableCell key='Start' align="center" onClick={onClick}>{tryFormatDate(d[7])}</TableCell>)
  },
  // [7, true],//["StartDate", "jt.StartDate"],
  {
    label: "Proposal",
    sortCol: "jt.ProposalDate",
    show: true,
    align: 'center',
    display: (d: Data, onClick: undefined | ((e: any) => void)) => (<TableCell key='Proposal' align="center" onClick={onClick}>{tryFormatDate(d[6])}</TableCell>)
  },
  // [6, true],//["ProposalDate", "jt.ProposalDate"],

  {
    label: "Proposed Rate",
    sortCol: "jt.cacheproposedrate",
    show: true,
    align: 'center',
    display: (d: Data, onClick: undefined | ((e: any) => void)) => (<TableCell key='cacheproposedrate' align="center" onClick={onClick}>{tryASDollars(d[15], 0)}</TableCell>)
  },
  // 15: ["Propsed Rate", "jt.cacheproposedrate"],
  {
    label: "Actual Rate",
    sortCol: "jt.cacheactualrate",
    show: true,
    align: 'center',
    display: (d: Data, onClick: undefined | ((e: any) => void)) => (<TableCell key='cacheactualrate' align="center" onClick={onClick}>{tryASDollars(d[16], 0)}</TableCell>)
  },
  // 16: ["Actual Rate", "jt.cacheactualrate"],


  // // // [0,false],//["SageID", "jt.sage_export_id"],
  // // // [9,false],//["Overdue", "jt.Overdue"],
  // // // [4,false],//["Office", "jt.Office"],
];


// const headCells: readonly HeadCell[] = [
//   {
//     id: 'jt.OrderNo',
//     numeric: false,
//     disablePadding: true,
//     label: 'Job#',
//   },
//   {
//     id: 'calories',
//     numeric: true,
//     disablePadding: false,
//     label: 'Calories',
//   },
//   {
//     id: 'fat',
//     numeric: true,
//     disablePadding: false,
//     label: 'Fat (g)',
//   },
//   {
//     id: 'carbs',
//     numeric: true,
//     disablePadding: false,
//     label: 'Carbs (g)',
//   },
//   {
//     id: 'protein',
//     numeric: true,
//     disablePadding: false,
//     label: 'Protein (g)',
//   },
// ];
type Order = 'asc' | 'desc';
function toOrder(input: string | number | null): Order {
  return (input == 'asc') ? 'asc' : 'desc';
}
interface EnhancedTableProps {
  // numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: number) => void;
  // onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: number;
  columnConfig: CONFIG[];
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (colPos: number) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, colPos);
    };

  return (
    <TableHead>
      <TableRow>
        {props.columnConfig.map((colConfig, idx) => {
          if (colConfig.show) {
            return (
              <TableCell
                key={colConfig.label}
                // align={headCell.numeric ? 'right' : 'left'}
                // padding={headCell.disablePadding ? 'none' : 'normal'}
                align={colConfig.align}
                sortDirection={orderBy === idx ? order : false
                }
              >
                {(colConfig.sortCol !== undefined)
                  ? (
                    <TableSortLabel
                      active={orderBy === idx}
                      direction={orderBy === idx ? order : 'asc'}
                      onClick={createSortHandler(idx)}
                    >
                      {colConfig.label}
                      {orderBy === idx ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  ) : (
                    <>{colConfig.label}</>
                  )}

              </TableCell>
            );
          } else {
            return null;
          }
        })}
      </TableRow>
    </TableHead >
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
  config: CONFIG[];
  setColumnConfig: React.Dispatch<React.SetStateAction<CONFIG[]>>;
  filter: FILTER;
  setFilter: (filter: FILTER) => void;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const [stage, setStage] = React.useState(props.filter.stage);
  const [office, setOffice] = React.useState(props.filter.office);
  const [query, setQuery] = React.useState<string>(props.filter.query);
  const [queryIn, setQueryIn] = React.useState<FILTER_QUERY_IN>(props.filter.queryIn);

  const { numSelected } = props;

  React.useEffect(() => {
    setFilter();
  }, [stage, office, queryIn]);

  const setFilter = () => {
    props.setFilter({ stage, office, query, queryIn });
  }

  const toggleShowColumn = (index: number) => {
    props.setColumnConfig((prevConfig) => {
      let newConfig = [...prevConfig];
      newConfig[index].show = !newConfig[index].show;
      return newConfig;
    });
  }


  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >

      <Typography
        sx={{ flex: '1 1 100%' }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Job Tickets
      </Typography>


      <FormControl variant="standard" style={{ minWidth: 125, paddingRight: 10 }}>
        <InputLabel>Stages</InputLabel>
        <Select value={stage} name="Stage" label="Stage" onChange={(e) => { setStage(toInt(e.target.value)); }}>
          <MenuItem key={'all'} value={-1}>All Stages</MenuItem>
          {STAGES.map((s, idx) => <MenuItem key={idx} value={idx}>{(idx != 0) && '#' + idx} {s}</MenuItem>)}
        </Select>
      </FormControl>

      <FormControl variant="standard" style={{ minWidth: 125, paddingRight: 10 }}>
        <InputLabel>Office</InputLabel>
        <Select value={office} name="Offices" label="Offices" onChange={(e) => { setOffice(toInt(e.target.value)); }}>
          <MenuItem key={'0'} value={0}>All Offices</MenuItem>
          {OFFICES.map(o => <MenuItem key={o[0]} value={o[0]}>{o[1]}</MenuItem>)}
        </Select>
      </FormControl>

      <FormControl variant="standard" style={{ minWidth: 125, paddingRight: 10 }}>
        <InputLabel>Search In</InputLabel>
        <Select value={queryIn} name="Search In" label="Search In" onChange={(e) => { setQueryIn(e.target.value as any); }}>
          <MenuItem key={'all'} value={'all'}>All Fields</MenuItem>
          {SEARCH_IN.map(o => <MenuItem key={o[1]} value={o[1]}>{o[0]}</MenuItem>)}
        </Select>
      </FormControl>

      <TextField name="Search" label="Search" value={query}
        onChange={(e) => { setQuery(e.target.value); }}
        onBlur={(e) => { setFilter(); }}
        onKeyPress={(e) => { if (e.key === 'Enter') setFilter(); }}
        variant="standard" style={{ minWidth: 100 }}
      />

      <PopupState variant="popover">
        {(popupState) => (
          <div>
            <Tooltip title="View Columns">
              <IconButton {...bindTrigger(popupState)}>
                <ViewColumnIcon />
              </IconButton>
            </Tooltip>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
              transformOrigin={{ vertical: 'top', horizontal: 'center', }}
            >
              <MenuList dense>
                {props.config.map((col, idx) =>
                  <MenuItem key={idx} onClick={() => toggleShowColumn(idx)}>
                    {col.show && <ListItemIcon><Check /></ListItemIcon>}
                    <ListItemText inset={!col.show}>{col.label}</ListItemText>
                  </MenuItem>
                )}
              </MenuList>

            </Popover>
          </div>
        )}
      </PopupState>

    </Toolbar>
  );
};

const DEFAULTS = {
  stage: -1,
  order: 'desc' as Order,
  orderBy: 1,
  page: 0,
  rowsPerPage: 25,
  office: 0,
  query: "",
  queryIn: 'all' as 'all',
}
export type LIST_RESP = { totalsize: { count: number }, results: Data[] }

export default function JobList() {
  const [isBusy, setBusy] = React.useState(false);
  const dense = true;
  const [columnConfig, setColumnConfig] = React.useState(COLUMN_CONFIG);

  let [searchParams, setSearchParams] = useTSQueryParams(DEFAULTS, true);
  let order: Order = toOrder(searchParams.get('order'));
  let orderBy: number = searchParams.get('orderBy');
  let page: number = searchParams.get('page');
  let rowsPerPage: number = searchParams.get('rowsPerPage');

  let office: number = searchParams.get('office');
  let query: string = searchParams.get('query');
  let queryIn: FILTER_QUERY_IN = toFILTER_QUERY_IN(searchParams.get('queryIn'));
  let stage: number = searchParams.get('stage');

  let filter = { office, query, queryIn, stage };

  const setFilter = (newFilter: FILTER) => {
    setSearchParams(newFilter);
  }

  const makeFetcherParams = () => {
    let params: any = { a: 'lookup', t: 'jobs' };

    params['sortby'] = (orderBy != DEFAULTS.orderBy) ? COLUMN_CONFIG[orderBy].sortCol : undefined;
    params['order'] = (order != DEFAULTS.order) ? order : undefined;
    params['page'] = (page != DEFAULTS.page) ? page : undefined;
    params['pageSize'] = (rowsPerPage != DEFAULTS.rowsPerPage) ? rowsPerPage : undefined;
    params['office'] = (filter.office != DEFAULTS.office) ? filter.office : undefined;
    params['query'] = (filter.query != DEFAULTS.query) ? filter.query : undefined;
    params['queryIn'] = (filter.queryIn != DEFAULTS.queryIn) ? filter.queryIn : undefined;
    params['stage'] = (filter.stage != DEFAULTS.stage) ? filter.stage : undefined;

    return params;
  }

  React.useEffect(() => {
    console.log("JobList - enter render", filter.office);
    return () => {
      console.log("JobList - exit render", filter.office);
    }
  }, []);

  const jsonFetcher = (params: any) => {
    console.log("jsonFetcher() start:", params);

    setBusy(true);
    return axios.get('/api/json.php', { params: params })
      .catch(catchUnauthorized)
      .then((resp) => resp.data as LIST_RESP)
      .then((json) => {
        console.log("jsonFetcher() complete:", params);
        return json;
      })
      .finally(() => {
        setBusy(false);
      });
  }

  const { data, error } = useSWR(makeFetcherParams, jsonFetcher);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: number,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    const orderStr: string | undefined = isAsc ? undefined : 'asc';
    setSearchParams({ 'orderBy': property + '', order: orderStr })
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setSearchParams({ page: '' + newPage });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchParams({ rowsPerPage: event.target.value });
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = (page > 0 && data) ? Math.max(0, (1 + page) * rowsPerPage - data.results.length) : 0;

  return (<>
    <JobMgrHeader>
      <Typography variant="h6" component="h1">
        Job Tickets
      </Typography>
    </JobMgrHeader>
    {isBusy && <Grid item xs={12}><LinearProgress /></Grid>}
    <Container maxWidth="xl">
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <EnhancedTableToolbar
            numSelected={0}
            config={columnConfig} setColumnConfig={setColumnConfig}
            filter={filter} setFilter={setSearchParams}
          />
          {/* selected.length */}
          <TableContainer sx={{ maxWidth: '100%' }}>
            <Table
              sx={{ minWidth: 750, maxWidth: '100%' }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
              className="listtable"
            >
              <EnhancedTableHead
                // numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                // onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                columnConfig={columnConfig}
              />
              <TableBody>
                {data && data.results.map((row, index) => {
                  let onClick: null = null;
                  // () => {
                  //   navigate("./" + row[1]);
                  // }
                  return (
                    <TableRow hover tabIndex={-1} key={'' + row[1]}>
                      {columnConfig.map((cconfig) => cconfig.show ? cconfig.display(row, undefined) : null)}
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={data ? data.totalsize.count : 1}
            rowsPerPage={rowsPerPage}
            page={data ? page : 0}
            // Page must be 0 when we dont' have any data to show
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Container>
  </>);
}