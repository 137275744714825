import React from "react";
import { Backdrop, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Paper, PaperProps } from "@mui/material";
import Draggable from "react-draggable";
import useSWR, { Fetcher, SWRResponse } from "swr";
import { Field_WorkReport, JC_JOB_PART } from "../jobtix/Types";
import { isoToMMDDYYY } from "../utils/Functions";
import WorkReportDisplay from "./WorkReportDisplay";
import { Close as CloseIcon } from "@mui/icons-material";

export function DraggablePaperComponent(props: PaperProps) {
  const nodeRef = React.useRef(null);
  return (
    <Draggable
      handle="#draggable-dialog-title"
      nodeRef={nodeRef}
    // cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} ref={nodeRef} />
    </Draggable>
  );
}


export type jsonJobcostAPI = { target: string, action: string };
export type jsonJobcostAPIRsp = { success: boolean };
export function jsonJobcostApiFetcher<KEY extends jsonJobcostAPI, RSP extends jsonJobcostAPIRsp>(key: KEY) {
  return fetch('/api/json_jobcost_api.php', { method: "POST", body: JSON.stringify(key) }).then(r => r.json() as unknown as RSP);
}

export type WorkReportParams = jsonJobcostAPI & { target: 'workreport', action: 'getempname', workreportid: number };
export type WorkReportRsp = jsonJobcostAPIRsp & { workreports: Field_WorkReport[] };
export const wrFetcher: Fetcher<WorkReportRsp, WorkReportParams> = jsonJobcostApiFetcher;

export default function WorkReportDialog(props: { open: boolean, job: JC_JOB_PART | null, date: string | null, onClose: () => void }) {
  const jobid = (props.job && props.job.orderno && Number.parseInt(props.job.orderno) > 0) ? Number.parseInt(props.job?.orderno) : -1;
  const workreportSWR = useSWR({ target: 'workreport', action: 'get', order_id: jobid, date: props.date }, wrFetcher);
  // let t = workreportSWR.data


  // const jsonFetcher = (url: string) => fetch(url).then(r => r.json())
  // const { data, error } = useSWR("/api/json_jobcost_api.php?target=workreport&action=gettrucks&workreportid=" + props.wr.id, jsonFetcher);

  if (workreportSWR.error)
    return <div style={{ color: 'red' }}>{JSON.stringify(workreportSWR.error)}</div>;
  else if (workreportSWR.data)
    return (<>
      <Dialog
        open={props.open}
        PaperComponent={DraggablePaperComponent}
        onClose={() => { props.onClose() }}
      // hideBackdrop // Disable the backdrop color/image
      // disableEnforceFocus // Let the user focus on elements outside the dialog
      // disablePortal
      // disableEscapeKeyDown={false}
      // BackdropProps={{ open: false, style: { zIndex: 2000 } }}
      // disableScrollLock
      >
        {/* maxWidth="lg" fullWidth */}
        {/* BackdropProps={{ open: false }} disablePortal={true} hideBackdrop={true} */}
        {/* onClose={() => { setopenWR(null) }}  */}

        <DialogTitle>
          <IconButton onClick={props.onClose} size="large" style={{ paddingLeft: 0, paddingRight: 8 }}>
            <CloseIcon color="error" />
          </IconButton>
          <span style={{ display: 'inline-block', userSelect: 'none', cursor: 'move', top: 0, right: 0 }} id="draggable-dialog-title">
            {/* // @ts-ignore */}
            &nbsp; {`Work Report for Job #${props.job?.orderno} on ${isoToMMDDYYY(props.date, 'N/A')}`}
          </span>
        </DialogTitle>
        <DialogContent>
          {(workreportSWR.isValidating) && <LinearProgress />}
          {props.job && workreportSWR.data.workreports.map((wr) => {
            return <WorkReportDisplay key={wr.id} wr={wr} job={props.job} />
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>Close</Button>
        </DialogActions>
      </Dialog>

    </>);
  else if (workreportSWR.isValidating)
    return <LinearProgress />;
  else
    return <div style={{ color: 'red' }}>Error - nothing returned - should never happen</div>;
}
