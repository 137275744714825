import React, { useState, useEffect, useMemo, ChangeEvent } from 'react';
import * as api from '../jobtix/apiCalls';
import axios from 'axios';

import JobMgrHeader from '../utils/JobMgrHeader';
import { Data, LIST_RESP, tryFormatDate } from '../jobtix/JobList';
import { ExportToCsv } from 'export-to-csv';

import { Container, Typography, LinearProgress, Paper, Button, Link, TablePagination } from '@mui/material';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';


const options = {
  filename: 'JobStartReport-' + new Date().toISOString().split('T')[0],
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  showTitle: false,
  title: 'Report: Jobs with SageID and Start Date',
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true,
  // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
};

const csvExporter = new ExportToCsv(options);

type ExportedData = {
  JobNo: number;
  SageID: string | null;
  Name: string;
  OfficeName: string;
  StartDate: string | null;
  CompletionDate: string | null;
  KilledDate: string | null;
};

let currentProcessCount = 0;

export default function ReportJobStart() {

  // These are 'query' params
  const [reset, setReset] = useState(1);
  const [page, setPage] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(500);

  // Results from query data coming in & display data
  const [data, setData] = useState<ExportedData[] | null>(null);
  const [count, setCount] = useState(0);
  const [error, setError] = useState<string | null>();
  const [isBusy, setBusy] = React.useState(false);

  useEffect(() => {
    let params: any = { a: 'lookup', t: 'jobs', page: page, pageSize: rowPerPage, has: 'jt.sage_export_id,jt.StartDate' };

    currentProcessCount++;
    let thisPCount = currentProcessCount;

    // console.log("jsonFetcher() start:", params);
    setBusy(true);
    axios.get('/api/json.php', { params: params })
      .catch(api.catchUnauthorized)
      .catch((error) => { setError(JSON.stringify(error)) })
      .then((resp) => resp.data as LIST_RESP)
      .then((json) => {
        // console.log("jsonFetcher() complete:", params);

        // If another fetch is already in progress, abort this one
        if (currentProcessCount != thisPCount) {
          console.log('ABORTED');
          return;
        }


        let rawData = json.results;
        let processedData: ExportedData[] = [];

        for (let d of rawData) {
          let [SageID, JobNo, Name, city, office, contact, proposaldate, StartDate, stage, overdue, OfficeName, customer, CompletionDate, KilledDate]: Data = d;
          if (StartDate === null) StartDate = '';
          if (CompletionDate === null) CompletionDate = '';
          if (KilledDate === null) KilledDate = '';
          processedData.push({ JobNo, SageID, Name, OfficeName, StartDate, CompletionDate, KilledDate });
        }

        // const processedData2 = json.results.map((row) => {
        //   let [SageID, JobNo, Name, city, office, contact, proposaldate, StartDate, stage, overdue, OfficeName, customer, CompletionDate, KilledDate] = row;
        //   if (StartDate === null) StartDate = '';
        //   if (CompletionDate === null) CompletionDate = '';
        //   if (KilledDate === null) KilledDate = '';

        //   return { JobNo, SageID, Name, OfficeName, StartDate, CompletionDate, KilledDate }
        // });


        setData(processedData);
        setCount(json.totalsize.count);

      })
      .finally(() => {
        setBusy(false);
      });

  }, [reset, page, rowPerPage])

  const onDownloadButton = useMemo(() => () => {

    let params: any = { a: 'lookup', t: 'jobs', page: 0, pageSize: 100000, has: 'jt.sage_export_id,jt.StartDate' };

    // console.log("jsonFetcher() start:", params);
    setBusy(true);
    axios.get('/api/json.php', { params: params })
      .catch(api.catchUnauthorized)
      .catch((error) => { setError(JSON.stringify(error)) })
      .then((resp) => resp.data as LIST_RESP)
      .then((json) => {
        // console.log("jsonFetcher() complete:", params);

        let rawData = json.results;
        let processedData: any[] = [];
        for (let d of rawData) {
          let [SageID, JobNo, Name, city, office, contact, proposaldate, StartDate, stage, overdue, OfficeName, customer, CompletionDate, KilledDate]: Data = d;
          if (StartDate === null) StartDate = '';
          if (CompletionDate === null) CompletionDate = '';
          if (KilledDate === null) KilledDate = '';
          processedData.push({ JobNo, SageID, Name, OfficeName, StartDate, CompletionDate, KilledDate });
        }
        csvExporter.generateCsv(processedData);
      })
      .finally(() => {
        setBusy(false);
      });

  }, [])

  const onChangePage = useMemo(() => (ignore: any, newPage: number) => {
    if (Number.isFinite(newPage) && newPage > 0) {
      setPage(newPage);
    }
  }, [])
  const onChangeRowsPerPage: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = useMemo(() => (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newRPP = Number.parseInt(event.target.value);
    if (Number.isFinite(newRPP) && newRPP > 0) {
      setRowPerPage(newRPP);
    }
  }, [])

  // .textContainer {
  //   display: block;
  //   width: 200px;
  //   white-space: nowrap;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  // }

  return <>
    <JobMgrHeader>
      <Typography variant="h6" component="h1">
        Job Report: Sage & Start
      </Typography>
    </JobMgrHeader>

    <Container>
      {error && <Typography>ERROR: {error}</Typography>}
      {!error && !data && <Typography>Loading... <LinearProgress /></Typography>}
      {!error && data && <>
        <Button variant='contained' onClick={onDownloadButton} style={{ float: 'right' }}>Download CSV</Button>
        <h4>Report lists all jobs that have Start Date and SageID set.</h4>
        {/* Download and display only contains {data.length} records, out of a total {count}. */}
        {/* <br /> */}
        {/* display: (isBusy) ? "block" : "none" */}
        <LinearProgress variant='indeterminate' style={{ visibility: (isBusy) ? "visible" : "hidden" }} />
        <TableContainer component={Paper}>
          <TablePagination
            component="div"
            count={count}
            page={page}
            onPageChange={onChangePage}
            // event: React.MouseEvent<HTMLButtonElement> | null, page: number
            rowsPerPage={rowPerPage}
            rowsPerPageOptions={[100, 500, 1000]}
            onRowsPerPageChange={onChangeRowsPerPage}
          />
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="right">Job</TableCell>
                <TableCell>Sage</TableCell>
                <TableCell >Office</TableCell>
                <TableCell >Name</TableCell>
                <TableCell align="center">Start</TableCell>
                <TableCell align="center">Complete</TableCell>
                <TableCell align="center">Killed</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((data) => (
                <TableRow
                  key={data.JobNo}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row"><Link href={"/job.php?id=" + data.JobNo}>{data.JobNo}</Link></TableCell>
                  <TableCell style={{ whiteSpace: 'nowrap' }}>{data.SageID}</TableCell>
                  <TableCell style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.OfficeName}</TableCell>
                  <TableCell >{data.Name}</TableCell>
                  <TableCell align="center">{tryFormatDate(data.StartDate)}</TableCell>
                  <TableCell align="center">{tryFormatDate(data.CompletionDate)}</TableCell>
                  <TableCell align="center">{tryFormatDate(data.KilledDate)}</TableCell>
                </TableRow>



                // type ExportedData = {
                //   JobNo: number;
                //   SageID: string | null;
                //   Name: string;
                //   OfficeName: string;
                //   StartDate: string | null;
                //   CompletionDate: string | null;
                //   KilledDate: string | null;
                // };


              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>}
    </Container>
  </>;
}


