import axios, { AxiosError, AxiosResponse } from "axios";
import { JSON_JOBTICKET_ALL } from "./JobTicket";
import { EDIT_JC_ROW } from "./JobTixItems";
import { EQUIPMENT_TYPES as EQUIPMENT_TYPE, JC_ROW, UNIT_TYPE } from "./Types";
import { CUSTOMER, JOB } from "./Types";
import { SAGE_LOG } from "../reports/SageLog";


export function catchUnauthorized<E>(rsp: E): Promise<E> {
  if (rsp instanceof AxiosError && rsp.response?.status == 401) {
    // alert("assigning to: "+"/login.php?f=" + window.location.pathname + window.location.hash + window.location.search);
    // debugger;
    window.location.assign("/login.php?f=" + window.location.pathname + window.location.hash + window.location.search);
    return Promise.reject("Unauthorized, need login.");
  } else {
    return Promise.reject(rsp);
  }
}

export async function getSageLogs(keyPart: "getSageLogs") {
  return axios.get('/api/json.php', { params: { a: 'get', t: 'sagelogs' } })
    .catch(catchUnauthorized)
    .then((resp) => {
      return resp.data as { sagelogs: SAGE_LOG[] }
    })
    .then((json) => {
      console.log('/api/json.php sagelogs rsp:', json);
      return json;
    });
}


export async function getJobCluster(keyPart: "getJobCluster", jobNo: number | null) {
  if (jobNo == null)
    return null;

  return axios.get('/api/json.php', { params: { a: 'get', t: 'job', jobid: jobNo } })
    .catch(catchUnauthorized)
    .then((resp) => {
      return resp.data as JSON_JOBTICKET_ALL
    })
    .then((json) => {
      console.log('/api/json.php CUSTOMER RSP:', json);
      return json;
    });
}

export function getCustomer(keyPart: "getCustomer", custNo: number | null) {
  if (custNo == null)
    return null;
  return axios.get('/api/json.php', { params: { a: 'get', t: 'customer', id: custNo } })
    .catch(catchUnauthorized)
    .then((resp) => {
      console.log('/api/json.php cust- RESP:', resp);
      return resp.data as CUSTOMER
    })
    .then((json) => {
      console.log('/api/json.php CUSTOMER RSP:', json);
      return json;
    });
}

export function getJobsByNameOrNumber(keyPart: "jobsbynameornumber", query: string) {
  if (query == "")
    return null;
  return axios.get('/api/json.php', { params: { a: 'lookup', t: 'jobsbynameornumber', query: query } })
    .catch(catchUnauthorized)
    .then((resp) => {
      console.log('/api/json.php jobsbynameornumber- RESP:', resp);
      return resp.data.results as [number,string][]
    })
    .then((json) => {
      console.log('/api/json.php jobsbynameornumber RSP:', json);
      return json;
    });
}

export function saveJob(job: JOB) {
  return axios.post('/api/json.php', { a: 'save', t: 'job', job: job })
    .then((resp) => {
      return resp.data as { success: boolean, newOrderNo?: number, rowsAffected?: number }
    })
    .then((json) => {
      console.log('/api/json.php save <-> job RSP:', json);
      return json;
    });
}


export function getJobCostItems(keyPart: "getJobCostItems", jobNo: number) {
  return axios.get('/api/json.php', { params: { a: 'get', t: 'jobcostrows', jobid: jobNo } })
    .catch(catchUnauthorized).then((resp) => resp.data as { "jc_rows": JC_ROW[] })
}

export function getUnitTypes(keyPart: "getUnitTypes") {
  return axios.get('/api/json.php', { params: { a: 'list', t: 'unittypes' } })
    .catch(catchUnauthorized).then((resp) => resp.data.unittypes as UNIT_TYPE[])
}

export function getEquipmentTypes(keyPart: "getEquipmentTypes") {
  return axios.get('/api/json.php', { params: { a: 'list', t: 'equipmenttypes' } })
    .catch(catchUnauthorized).then((resp) => resp.data.equipmenttypes as EQUIPMENT_TYPE[])
}


export function saveJobItems(jobItems: EDIT_JC_ROW[]) {
  return axios.post('/api/json.php', { a: 'save', t: 'jobitems', jobitems: jobItems })
    .then((resp) => {
      console.log("saveJobItems()", jobItems, resp.data);
      // return resp.data as { success: boolean, newOrderNo?: number, rowsAffected?: number }
    })
  // .then((json) => {
  //   console.log('/api/json.php save <-> job RSP:', json);
  //   return json;
  // });
}