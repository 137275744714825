import { Paper } from "@mui/material";
import { useMemo } from "react";
import useSWR from "swr";
import { Field_WorkReport, JC_JOB_PART } from "../jobtix/Types";


type TruckFetchParams = { url: '/api/json_jobcost_api.php', target: 'workreport', action: 'gettrucks', workreportid: number };
type TruckFetchResponse = { trucks: { workreportid: string, truckid: string, equip_num: string }[], success: boolean }
function truckFetcher(key: { action: 'gettrucks', workreportid: number }) {
  const params: TruckFetchParams = { url: '/api/json_jobcost_api.php', target: 'workreport', action: 'gettrucks', workreportid: key.workreportid };
  return fetch(params.url, { method: "POST", body: JSON.stringify(params) }).then(r => r.json() as unknown as TruckFetchResponse).then((rsp) => rsp.trucks);
}

type EmployeeFetchParams = { url: '/api/json_jobcost_api.php', target: 'workreport', action: 'getempname', workreportid: number };
type EmployeeFetchResponse = { name: string, success: boolean }
function empNameFetcher(key: { action: 'getempname', workreportid: number }) {
  const params: EmployeeFetchParams = { url: '/api/json_jobcost_api.php', target: 'workreport', action: 'getempname', workreportid: key.workreportid };
  return fetch(params.url, { method: "POST", body: JSON.stringify(params) }).then(r => r.json() as unknown as EmployeeFetchResponse).then((rsp) => rsp.name);
}

export default function WorkReportDisplay(props: { wr: Field_WorkReport, job: JC_JOB_PART | null }) {

  const trucksSWR = useSWR({ action: 'gettrucks', workreportid: props.wr.id }, truckFetcher);
  const trucks = trucksSWR.data;
  const empNameSWR = useSWR({ action: 'getempname', workreportid: props.wr.id }, empNameFetcher);

  // const jsonFetcher = (url: string) => fetch(url).then(r => r.json())
  // const { data, error } = useSWR("/api/json_jobcost_api.php?target=workreport&action=gettrucks&workreportid=" + props.wr.id, jsonFetcher);

  return (<>
    {trucksSWR.error && <div style={{ color: 'red' }}>{trucksSWR.error}</div>}
    <div style={{ paddingLeft: "15px" }} data-workreport-id={props.wr.id}>
      <hr />
      <table style={{ borderSpacing: '10px', borderCollapse: 'separate' }}>
        <tbody>
          <tr>
            <th style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>Order #</th>
            <td>
              {/* <a href={`/app/jobcost/${props.wr.orderno}`} style={{ fontWeight: 600 }}> */}
                #{props.wr.orderno}:  {props.job?.jobname}
              {/* </a> */}
            </td>
            <th style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>Date:</th>
            <td>
              {props.wr.workdatestr}
            </td>
          </tr>
          <tr>
            <th style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>Created By</th>
            <td >{JSON.stringify(empNameSWR.data ? empNameSWR.data : props.wr.createdby)}</td>
            <th style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>Job Estimator</th>
            <td >{props.job && props.job.estimator}</td>
          </tr>
          <tr>
            <th style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>Time</th>
            <td >{props.wr.starttimestr} - {props.wr.endtimestr}</td>
          </tr>
          <tr>
            <th style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>Personnel</th>
            <td > {props.wr.personnel} </td>
          </tr>
          <tr>
            <th style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>Equipment</th>
            <td>
              {trucks?.map((t) => t.equip_num).join(', ')}
            </td>
          </tr>
          <tr>
            <th style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>Extra Equipment</th>
            <td colSpan={3}><Paper variant='outlined' sx={{ padding: 1 }}> <pre>{props.wr.extraequipment || "N/A"}</pre></Paper> </td>
          </tr>
          <tr>
            <th style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>Notes</th>
            <td colSpan={3}><Paper variant='outlined' sx={{ padding: 1 }}> <pre>{props.wr.notes}</pre></Paper></td>
          </tr>
        </tbody >
      </table >
    </div >

  </>);
}