import React, { useCallback, useMemo } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';

import AddIcon from '@mui/icons-material/Add';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import JoinLeftIcon from '@mui/icons-material/JoinLeft';
import JoinRightIcon from '@mui/icons-material/JoinRight';
import JoinInnerIcon from '@mui/icons-material/JoinInner';
import NumbersIcon from '@mui/icons-material/Numbers';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PeopleIcon from '@mui/icons-material/People';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import { FaToolbox as FaToolboxIcon } from 'react-icons/fa';
import { FaPencilRuler as FaPencilRulerIcon } from 'react-icons/fa';

import BillingHoldIcon from '@mui/icons-material/MoneyOff';
import BillingQueuedIcon from '@mui/icons-material/AttachMoney';
import BilledIcon from '@mui/icons-material/PriceCheck';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import { GiWoodBeam as GiWoodBeamIcon } from 'react-icons/gi';
// import { TbBuildingWarehouse as TbBuildingWarehouseIcon } from 'react-icons/tb';

import LinearProgress from '@mui/material/LinearProgress';
import { Box, Button, ToggleButtonGroup, ToggleButton, InputProps, Typography, AppBar, Toolbar, IconButton, Paper, Alert, AlertTitle, Snackbar, ListItemIcon, ListItemText, Select, SelectChangeEvent, FormControl, InputLabel, Input, Checkbox, FormGroup, FormControlLabel, Divider, Tooltip, debounce, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import { Menu, MenuItem, TextField, Modal, Fade } from '@mui/material';
import { useEffect, useState } from 'react';

import Popover from '@mui/material/Popover';
import { useParams } from 'react-router';

import RowCrewEditWidget from './RowCrewEditWidget';

import './jobcost.css';
import JobCostRow, { JOBTYPE2COLOR } from './JobCostRow';
import { asDollars, num2str } from '../utils/Functions'
import { COL, DATA, JOBCOST_BILL_COLS as JOBCOST_BILL_COLS, JC_JOB_PART, JSON_DATA, ROW, ROW_JOBCOST_TYPES } from '../jobtix/Types';
import AutoJobTicket from '../utils/AutoJobTicket';
import { tryFormatDate } from '../jobtix/JobList';
import { ColumnDateSelection } from './ColumnDateSelection';
import ColumnDateHeaderMenu from './ColumnDateHeaderMenu';
import WorkReportDialog from './WorkReportDialog';

export type ROWPLUS = ROW & { hasSub?: boolean };

function PlusMenu(props: {
  setOpenColumnModal: (in1: string | any) => void,
  setOpenRowAddlModal: (in1: string | any) => void,
  setOpenRowTruckModal: (in1: string | any) => void,
  setOpenRowCrewModal: (in1: string | any) => void,
  readonly: boolean,
}
): JSX.Element {
  const [plusMenuEl, setPlusMenuEl] = React.useState<null | HTMLElement>(null);

  return (
    <ToggleButtonGroup
      size="small"
      aria-label="view mode"
      onChange={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setPlusMenuEl((event.currentTarget != null) ? event.currentTarget : null);
      }}
      sx={{ marginLeft: 1 }}
      disabled={props.readonly}
    >
      <ToggleButton
        value="addColumn"
        // selected={selected}
        selected={false}
      // onMouseEnter={generateHandlePopoverOpen("Add New Entry")} onMouseLeave={handlePopoverClose}
      >
        <AddIcon />
      </ToggleButton>
      {/* const [, setPlusMenuEl] = React.useState<null|HTMLElement>(null); */}
      <Menu
        anchorEl={plusMenuEl}
        open={(plusMenuEl != null)}
        onClose={() => setPlusMenuEl(null)}
      >
        <MenuItem onClick={() => { setPlusMenuEl(null); props.setOpenColumnModal('new') }}>
          <ListItemIcon>
            <CalendarTodayIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Add Date Column</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => { setPlusMenuEl(null); props.setOpenRowTruckModal('newtruck') }}>
          <ListItemIcon>
            <LocalShippingIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Add Truck</ListItemText>
        </MenuItem>
        {/* <MenuItem onClick={() => { setPlusMenuEl(null); props.setOpenRowCrewModal('newcrew') }}>
          <ListItemIcon>
            <PeopleIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Add Special Crew</ListItemText>
        </MenuItem> */}
        <MenuItem onClick={() => { setPlusMenuEl(null); props.setOpenRowAddlModal('newaddl') }}>
          <ListItemIcon>
            <AddCircleIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Add Additional Row</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => { setPlusMenuEl(null); props.setOpenRowAddlModal('newaddlmat') }}>
          <ListItemIcon>
            <GiWoodBeamIcon style={{ width: 20, height: 20 }} />
          </ListItemIcon>
          <ListItemText>Add Material Cost Row</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => { setPlusMenuEl(null); props.setOpenRowAddlModal('newaddlsub') }}>
          <ListItemIcon>
            <PeopleIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Add Subcontractor Cost Row</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => { setPlusMenuEl(null); props.setOpenRowAddlModal('newaddlrent') }}>
          <ListItemIcon>
            <FaToolboxIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Add Rental Equipment Cost Row</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => { setPlusMenuEl(null); props.setOpenRowAddlModal('newaddlinteroffset') }}>
          <ListItemIcon>
            <AssignmentReturnIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Add Inter-Office Offset Row</ListItemText>
        </MenuItem>

      </Menu>
    </ToggleButtonGroup>

  );
}

function specialtyMatch(row: ROW, sType: 'all' | 'specialty' | 'nonspecialty'): boolean {
  if (sType === 'all')
    return true;

  const isSpec = (sType === 'specialty') ? "1" : "0";
  return (row.specialtyitem == isSpec);
}


export function parseISOLocal(dateStr: string) {
  var b: any[] = dateStr.split(/\D/);
  // @ts-ignore
  return new Date(b[0], b[1] - 1, b[2]);
}

export function parseISOToMDYY(dateStr: string) {
  var b: any[] = dateStr.split(/\D/);
  let year = Number.parseInt(b[0]);
  year = year % 100;
  let month = Number.parseInt(b[1]);
  let day = Number.parseInt(b[2]);

  if (Number.isNaN(year) || Number.isNaN(month) || Number.isNaN(day))
    return dateStr;
  else
    return `${month}/${day}/${year}`;

  // // @ts-ignore
  // return new Date(b[0], b[1] - 1, b[2]);
}

export function asColorDollars(input: number, dec: number = 2, reverse = false): JSX.Element {
  // let num = Math.round((input + Number.EPSILON) * 100) / 100;
  let numStr = asDollars(input, dec);
  let colorStr = (reverse == false) ? ((input > 0) ? "green" : "red") : ((input > 0) ? "red" : "green");
  if (Math.abs(input) < 0.01)
    return <>{numStr} </>;
  else
    return <Typography fontWeight='bold' color={colorStr}> {numStr} </Typography>;
}

export const dateToDay = (d: Date): string => {
  return (d == null) ? "" : d.toISOString().split("T")[0];
}

const isSameDay = (d1: Date, d2: Date): boolean => {
  if (d1 == d2) return true;
  if (d1 == null || d2 == null) return false;
  return dateToDay(d1) == dateToDay(d2);
}

  ;

function RowAddlEditWidget(props: InputProps & {
  row: 'newaddl' | 'newaddlmat' | 'newaddlsub' | 'newaddlrent' | 'newaddlinteroffset' | ROW,
  allRows: ROW[],
  order_id: number,
  toClose: Function,
  changeRow: (action: 'add' | 'edit' | 'del') => Promise<void>,
  showError: (error: any, message: string, temp: boolean) => void,
  readonly: boolean,
}) {
  const isNew: boolean = (props.row === 'newaddl' || props.row === 'newaddlmat' || props.row === 'newaddlsub' || props.row === 'newaddlrent' || props.row === 'newaddlinteroffset');

  let jobtype: ROW_JOBCOST_TYPES = 'additional';
  if (typeof props.row === 'string') {
    jobtype = (props.row === 'newaddlmat') ? 'addl_material' : (props.row === 'newaddlsub') ? 'addl_subcontractor' : (props.row === 'newaddlrent') ? 'addl_rentalequip' : (props.row === 'newaddlinteroffset') ? 'addl_interoffset' : 'additional';
  } else if (typeof props.row === 'object') {
    jobtype = props.row.jobcost_type;
  }
  let typeStr = (jobtype === 'addl_material') ? 'Material' : (jobtype === 'addl_subcontractor') ? 'Subcontractor' : (jobtype === 'addl_rentalequip') ? 'Rental Equipment' : (jobtype === 'addl_interoffset') ? 'Inter-Office Offset' : 'Additional';
  let addlSubType = (jobtype.match(/^addl_/) !== null);

  const row: ROW | null = (!isNew && props.row != null) ? props.row as ROW : null;

  const [parent, setParent] = React.useState<number>(row?.addl_cost_parent || 0);
  const [name, setName] = React.useState<string>(row?.name || "");
  const [rate, setRate] = React.useState<string>('' + (row?.rate || 0));
  const [cost, internalSetCost] = React.useState<string>('' + (row?.cost || 0));
  const [qty, setQty] = React.useState<string>('' + (row?.qty || 0));
  const [specialtyitem, setSpecialtyitem] = React.useState<boolean>(row?.specialtyitem != "0" && Boolean(row?.specialtyitem));

  const [isBusy, setBusy] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const setCost = internalSetCost;
  // const setCost = (jobtype !== 'addl_interoffset') ? internalSetCost :
  //   (input: string) => {
  //     if (Number.parseInt(input) > 0) {
  //       if (input.startsWith("0"))
  //         input = input.substring(1);
  //       internalSetCost('-' + input)
  //     } else if (input.startsWith("-0")) {
  //       internalSetCost('-' + input.substring(2))
  //     } else {
  //       internalSetCost(input)
  //     }
  //   };

  const performAction = (deleteAction: any) => {
    const formPostData: any = {
      target: 'row',
      action: (isNew) ? 'add' : (deleteAction) ? 'del' : 'edit',
      order_id: props.order_id,
      jobType: jobtype,
      name: name,
      rate: rate,
      cost: cost,
      qty: qty,
      addl_cost_parent: (parent == 0) ? null : parent,
      specialtyitem: specialtyitem,
    }
    if (typeof props.row === 'object' && 'row_id' in props.row) {
      formPostData['row_id'] = (props.row as ROW).row_id;
      formPostData['oldVersion'] = (props.row as ROW).version;
    }

    fetch('/api/json_jobcost_api.php', { method: "POST", body: JSON.stringify(formPostData) })
      .then(handleResponseToJson)
      .then((json: { success: boolean, row_id: string, version: string }) => {
        console.log('/api/json_jobcost_api.php RSP:', json);
        let newVersion = (json.version) ? json.version : undefined;
        props.changeRow(formPostData.action);
        props.toClose();
      })
      .catch((error) => {
        props.showError(error, "" + error, false);
      });
  };
  const handleSave = () => {
    performAction(false);
  }
  const handleDelete = () => {
    window.confirm('Are you sure you want to delete this row?') && performAction(true);
  }
  const handleOnBlur = () => {
  }

  const conditionalRateHidden = (rate == '0' || rate == '');

  const specialtyOnChange = useMemo(() => (e: React.ChangeEvent<HTMLInputElement>) => setSpecialtyitem(e.target.checked), [setSpecialtyitem, props.row]);

  return (
    <React.Fragment>
      <Typography fontSize="x-large" variant="overline">{isNew && "Add New " + typeStr + " Row" || "Edit " + typeStr + " Row"} </Typography>
      <Box marginBottom={1}>
        {addlSubType &&
          <FormControl sx={{ minWidth: 200 }} >
            <InputLabel>Parent Row</InputLabel>
            <Select variant="standard" sx={{ margin: 1 }} fullWidth={true}
              value={parent}
              onChange={(e: SelectChangeEvent<number | null>) => {
                const input = e.target.value;
                let value: number | null = (typeof input === 'number') ? input : 0;
                if (typeof input === 'string') {
                  const parsedInt = Number.parseInt(input);
                  value = (isNaN(parsedInt)) ? 0 : parsedInt;
                }
                setParent(value);
              }}
              error={error}
              disabled={props.disabled || props.readonly || error || isBusy}

              input={<Input />}
              renderValue={(selected) => {
                if (selected === 0 || selected === null) {
                  return <em>Select Parent Row to Apply Cost To</em>;
                }
                return props.allRows.find((r) => r.row_id == selected)?.name;
              }}
              // MenuProps={MenuProps}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem key={0} value={0}>
                <em>Select Parent Row to Apply Cost To</em>
              </MenuItem>
              {props.allRows.filter((r) => (r.jobcost_type === 'unique' || r.jobcost_type === 'equip') && r.addl_cost_type === row?.jobcost_type).map((r) =>
                <MenuItem key={r.row_id} value={r.row_id}>{r.name}</MenuItem>
              )}
              {props.allRows.filter((r) => (r.jobcost_type === 'unique' || r.jobcost_type === 'equip') && r.addl_cost_type !== row?.jobcost_type).map((r) =>
                <MenuItem key={r.row_id} value={r.row_id}>{r.name}</MenuItem>
              )}
            </Select>
          </FormControl>
        }
        <div>
          {(jobtype === 'addl_interoffset') &&
            <AutoJobTicket label='Offset Job' value={Number.parseInt(name)} onChange={(value) => setName('' + value)} readonly={props.readonly} sx={{ margin: 1 }} fullWidth={false} />
          }
          {!(jobtype === 'addl_interoffset') &&
            <TextField required size="medium" variant="standard" sx={{ margin: 1 }}
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              onBlur={handleOnBlur}
              error={error || (name.trim().length == 0)}
              disabled={props.disabled || props.readonly || error || isBusy}
            />
          }

        </div>
        {!conditionalRateHidden &&
          <div>
            <TextField required size="medium" variant="standard" sx={{ margin: 1 }} inputProps={{ inputMode: 'numeric', pattern: '[0-9]+' }}
              label="Rate"
              value={rate}
              onChange={(e) => setRate(e.target.value)}
              onBlur={handleOnBlur}
              error={error || !Number.isInteger(Number.parseInt(rate))}
              disabled={props.disabled || props.readonly || error || isBusy}
            />
          </div>
        }
        <div>
          <TextField required size="medium" variant="standard" sx={{ margin: 1 }} inputProps={{ inputMode: 'numeric', pattern: '[0-9]+' }}
            label="Cost"
            value={cost}
            onChange={(e) => { setCost(e.target.value) }}
            onBlur={handleOnBlur}
            error={error || false == /^-?\d+(?:.\d*)?$/.test(cost.trim())}
            disabled={props.disabled || props.readonly || error || isBusy}
          // helperText={(jobtype === 'addl_interoffset') ? "Only negative cost allowed for Inter-Office Offsets" : undefined}
          />
        </div>
        <div>
          <TextField required size="medium" variant="standard" sx={{ margin: 1 }} inputProps={{ inputMode: 'numeric', pattern: '[0-9]+' }}
            label="Qty"
            value={qty}
            onChange={(e) => setQty(e.target.value)}
            onBlur={handleOnBlur}
            error={error || !Number.isInteger(Number.parseInt(qty))}
            disabled={props.disabled || props.readonly || error || isBusy}
          />
        </div>
        <div>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox onChange={specialtyOnChange} disabled={true} />}
              label="Specialty Item"
              checked={specialtyitem}
            />
          </FormGroup>

          {/* <Checkbox required size="medium" variant="standard" sx={{ margin: 1 }} inputProps={{ inputMode: 'numeric', pattern: '[0-9]+' }}
            label="Qty"
            value={qty}
            onChange={(e) => setQty(e.target.value)}
            onBlur={handleOnBlur}
            error={error || !Number.isInteger(Number.parseInt(qty))}
            disabled={props.disabled || error || isBusy}
          /> */}
        </div>
      </Box>
      {/* 
      <Input size="small" fullWidth
        margin="none"
        value={value}
        {...props}
        style={forceWidth}
        onChange={(e) => setValue(e.target.value)}
        onBlur={onBlur}
        error={error || value != null && !Number.isInteger(Number.parseInt(value))}
        disabled={props.disabled || error || isBusy}
        inputProps={{ tabIndex: props.tab, inputMode: 'numeric', pattern: '[0-9]+' }}
      /> */}

      <Button variant="contained" sx={{ float: 'right' }} disabled={props.readonly} onClick={handleSave}>{isNew && "Add New " + typeStr + " Row" || "Save Values"}</Button>
      {!isNew && <Button variant="contained" color="error" disabled={props.readonly} onClick={handleDelete}>Delete</Button>}
    </React.Fragment>
  );
};


function RowTruckEditWidget(props: InputProps & { row: 'newtruck' | ROW, allRows: ROW[], order_id: number, toClose: Function, changeRow: (action: 'add' | 'edit' | 'del') => Promise<void>, showError: (error: any, message: string, temp: boolean) => void, readonly: boolean, }) {
  type TRUCK = { id: number, label: string, make: string, model: string, desc: string, equip_type: string, jctruck: 0 | 1 };
  const EMPTY_TRUCK = { id: -1, label: '', make: '', model: '', desc: '', equip_type: '', jctruck: 1 as 1 };

  const isNew: boolean = (props.row === 'newtruck');
  const row: ROW | null = (!isNew && props.row != null) ? props.row as ROW : null;

  const [value, setValue] = React.useState<TRUCK>(EMPTY_TRUCK);
  const [inputValue, setInputValue] = React.useState('');

  const [isBusy, setBusy] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [options, setOptions] = useState<TRUCK[]>([EMPTY_TRUCK]);

  useEffect(() => {
    setBusy(true);
    fetch('/api/json_jobcost_api.php', { method: "POST", body: JSON.stringify({ target: 'extratruck', action: 'list' }) })
      .then(handleResponseToJson)
      .then((json: { success: boolean, trucks: TRUCK[] }) => {
        // Filter available options, but add in 'invalid' if it's the value already selected
        let filteredTrucks = json.trucks.filter((t) => t.jctruck == 1);
        filteredTrucks = [EMPTY_TRUCK, ...filteredTrucks];

        // Attempt to set value to pre-selected eqid
        if (props.row !== 'newtruck') {
          let found = json.trucks.find((t) => t.id == row?.eqid);

          if (found) {
            let foundInFiltered = filteredTrucks.find((t) => t.id == found?.id);
            if (!foundInFiltered)
              filteredTrucks.push(found);
            setValue(found);
          }
          else {
            console.error("pre-selected truck NOT FOUND");
            setError("Unable to find existing selected truck in database. Locking changes.");
          }
        }

        setOptions(filteredTrucks); //[EMPTY_TRUCK, ...json.trucks]
      })
      .catch((error) => {
        props.showError(error, "" + error, false);
        setError("Saving truck");
      }).finally(() => {
        setBusy(false);
      });
  }, []);

  const performAction = (deleteAction: any) => {
    const formPostData: any = {
      target: 'row',
      action: (props.row === 'newtruck') ? 'addextra' : (deleteAction) ? 'del' : 'editextra',
      order_id: props.order_id,
      jobType: 'extratruck',
      name: value.label + ": " + value.make + " / " + value.model,
      // value.label,
      eqid: value.id,
    }
    if (props.row !== 'newtruck') {
      formPostData['row_id'] = (props.row as ROW).row_id;
      formPostData['oldVersion'] = (props.row as ROW).version;
    }

    setBusy(true);
    fetch('/api/json_jobcost_api.php', { method: "POST", body: JSON.stringify(formPostData) })
      .then(handleResponseToJson)
      .then((json: { success: boolean, row_id: string, version: string }) => {
        console.log('/api/json_jobcost_api.php RSP:', json);
        let newVersion = (json.version) ? json.version : undefined;
        props.changeRow(formPostData.action);
        props.toClose();
      })
      .catch((error) => {
        props.showError(error, "" + error, false);
      }).finally(() => {
        setBusy(false);
      });
  };
  const handleSave = () => {
    performAction(false);
  }
  const handleDelete = () => {
    window.confirm('Are you sure you want to delete this truck?') && performAction(true);
  }
  const handleOnBlur = () => {
  }

  return (
    <React.Fragment>
      <Typography fontSize="x-large" variant="overline">{isNew && "Add New Row" || "Edit Truck"} </Typography>
      <Box marginBottom={1}>
        <Autocomplete autoComplete autoHighlight autoSelect disableClearable style={{ width: '100%' }}
          value={value}
          onChange={(event: any, newValue: TRUCK | null) => {
            console.log('Autocomplete onChange ', newValue, event);
            setValue(newValue || EMPTY_TRUCK);
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => (option?.label) ? (option.label + ": " + option.make + " / " + option.model) : ''}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            console.log('Autocomplete onInputChange ', newInputValue, event);
            setInputValue(newInputValue);
          }}
          renderOption={(props, option) => (option.id === -1) ? "" : (
            <Box component="li" {...props}>
              {option.label}: {option.make} / {option.model}
            </Box>
          )}
          options={options}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Truck" />}
          disabled={props.disabled || props.readonly || (error != null) || isBusy}
        />
        {error != null && <Typography color="red">{error}</Typography>}
      </Box>

      <Button variant="contained" sx={{ float: 'right' }} disabled={value.id === -1} onClick={handleSave}>{isNew && "Add Truck" || "Edit Truck"}</Button>
      {!isNew && <Button variant="contained" color="error" onClick={handleDelete}>Delete</Button>}
    </React.Fragment>
  );
};

function RowNoteEditWidget(props: InputProps & { data: DATA, order_id: number, toClose: Function, changeNote: (data: DATA, newNoteValue: string) => void, showError: (error: any, message: string, temp: boolean) => void, readonly: boolean, }) {
  const isNoteEmpty = !(props.data.note);

  const [value, setValue] = React.useState<string>("");

  const [busy, setBusy] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let newValue = props.data.note || "";
    newValue = newValue.replaceAll("\\n", "\n");
    setValue(newValue);
    console.log("Note Value Changed: ", props.data.note, newValue);
  }, [props.data])

  const performAction = (deleteAction: any) => {
    const formPostData: any = {
      target: 'data',
      action: 'note',
      order_id: props.order_id,
      note: (deleteAction) ? '' : value,
      row_id: props.data.row_id,
      col_id: props.data.col_id,
    }

    setBusy(true);
    fetch('/api/json_jobcost_api.php', { method: "POST", body: JSON.stringify(formPostData) })
      .then(handleResponseToJson)
      .then((json: { success: boolean, row_id: string, version: string }) => {
        console.log('/api/json_jobcost_api.php RSP:', json);
        let newVersion = (json.version) ? json.version : undefined;
        props.changeNote(props.data, formPostData.note);
        props.toClose();
      })
      .catch((error) => {
        setError("Error saving Note!");
        props.showError(error, "" + error, false);
      }).finally(() => {
        setBusy(false);
      });
  };
  const handleSave = () => {
    performAction(false);
  }
  const handleDelete = () => {
    window.confirm('Are you sure you want to delete this note?') && performAction(true);
  }

  return (
    <React.Fragment>
      {busy && <LinearProgress />}
      <Typography fontSize="x-large" variant="overline">{(isNoteEmpty) && "Add Note" || "Edit Note"} </Typography>
      <Box marginBottom={1}>
        <TextField label="Note" multiline minRows={3} fullWidth value={value} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setValue(event.target.value);
        }} disabled={props.readonly} />
        {error != null && <Typography color="red">{error}</Typography>}
      </Box>

      <Button variant="contained" sx={{ float: 'right' }} disabled={(!value) || (props.data.note == value)} onClick={handleSave}>Save</Button>
      {(!isNoteEmpty) && <Button variant="contained" color="error" onClick={handleDelete}>Delete</Button>}
    </React.Fragment>
  );
};



export const handleResponseToJson = async (rsp: Response) => {
  if (rsp.status == 200) {
    let json = await rsp.json();
    if (json['success'] == true)
      return Promise.resolve(json);
    else {
      console.log("Server response success is false: ", json);
      return Promise.reject("Sucess false, msg: " + json['errormsg']);
    }
  } else if (rsp.status == 401) {
    window.location.assign("/login.php?f=" + window.location.pathname + window.location.hash + window.location.search);
    return Promise.reject("Unauthorized, need login.");
  } else {
    return Promise.reject(new Error(rsp.statusText));
  }
};

type COL_SUMM = { rate: number, cost: number, diff: number, matcost: number, subcost: number, eqrentcost: number, offcost: number };

export default function JobCost(props: any) {
  let { orderid } = useParams();

  let jobID = (orderid) ? Number.parseInt(orderid) || -1 : -1;

  const [isAdmin, setIsAdmin] = useState(false);
  const [hasAccountingPermission, setHasAccountingPermission] = useState(false);

  const [job, setJob] = useState<JC_JOB_PART | null>(null);
  const jobUnknownOrOtherwiseLocked = (job == null || job.completiondate != null || job.killeddate != null || job.lock_ticket_ts != null);
  const readOnly = (isAdmin || hasAccountingPermission) ? false : jobUnknownOrOtherwiseLocked;

  const [rows, setRows] = useState<ROWPLUS[]>([]);
  const [cols, setCols] = useState<COL[]>([]);
  const [uid, setUID] = useState<number | null>(null);
  const [uname, setUName] = useState<string | null>(null);
  const [mapData, setMapData] = useState<{ [rowid: string]: { [colid: string]: DATA } } | null>(null);
  const [colSumm, setColSumm] = useState<{ [colid: string]: COL_SUMM }>({});
  const [colEstSumm, setColEstSumm] = useState<{ rate: number, cost: number, diff: number }>({ rate: 0, cost: 0, diff: 0 });
  const [wrDates, setWRDates] = useState<string[]>([]);
  const [colBillStatus, setColBillStatus] = useState<Map<string, JOBCOST_BILL_COLS>>(new Map());

  const [popupAnchorEl, setPopupAnchorEl] = React.useState<HTMLElement | null>(null);
  const [popupText, setPopupText] = React.useState<string>("");

  const [openColumnModal, setOpenColumnModal] = React.useState<'new' | COL | null>(null);
  const [openRowAddlModal, setOpenRowAddlModal] = React.useState<'newaddl' | 'newaddlmat' | 'newaddlsub' | ROW | null>(null);
  const [openRowTruckModal, setOpenRowTruckModal] = React.useState<'newtruck' | ROW | null>(null);
  const [openRowCrewModal, setOpenRowCrewModal] = React.useState<'newcrew' | ROW | null>(null);
  const [openNoteModal, setOpenNoteModal] = React.useState<DATA | null>(null);
  const [workReportDialogDate, setWorkReportDialogDate] = React.useState<string | null>(null);

  const [colClickMenuEl, setColClickMenuEl] = React.useState<null | HTMLElement>(null);
  const [colClickMenu, setColClickMenu] = React.useState<null | COL>(null);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [infoMsg, setInfoMsg] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [errorTempShow, setErrorTempShow] = useState<boolean>(true);
  const [actionsDisabled, setActionsDisabled] = useState<boolean>(false);

  const [viewMode, setViewMode] = useState<'compact' | 'expand'>("expand");
  const [viewByCostOrCountToggle, setViewByCostOrCountToggle] = useState<'viewByCount' | 'viewByCost' | 'viewByProfit' | 'viewByRate'>("viewByRate");

  const [summaryType, setSummaryType] = useState<'all' | 'specialty' | 'nonspecialty'>("all");
  const [summaryExpand, setSummaryExpand] = useState(true);


  const viewByCount = (viewByCostOrCountToggle == "viewByCount");
  const viewByCost = (viewByCostOrCountToggle == "viewByCost");
  const viewByRate = (viewByCostOrCountToggle == "viewByRate");
  const viewByProfit = (viewByCostOrCountToggle == "viewByProfit");

  const compact = (viewMode == "compact");

  const virtTrigger = true; //useScrollTrigger({threshold: 0 });
  const trigger = true; //useHorzScrollTrigger({threshold: 0 });

  const scrollTopRight = (delay = 250) => { setTimeout(() => { window.scroll(window.outerWidth, 0) }, delay) };

  useEffect(() => {
    // Hide global top bar if found, re-show when leaving this page
    let element = document.getElementById("shen-top-header");
    if (element != null) {
      let prevDisplay = element.style['display'];
      element.style['display'] = "none";
      return () => {
        if (element != null) {
          element.style.display = prevDisplay;
        }
      }
    }
  }, [])

  useEffect(() => {
    // scrollTopRight(100);
    try {
      if (process.env.NODE_ENV === 'development')
        console.log("LOADING: ", orderid);

      loadAndProcess(jobID).then(() => {
        // scrollTopRight(250);
        // scrollTopRight(500);
      })
    } catch (error) {
      console.error("CAUGHT ERROR", error);
    }
  }, [orderid]);

  const loadAndProcess = async (jobID: number) => {
    setLoading(true);
    setActionsDisabled(true);

    return fetch('/api/json_jobcost.php?order_id=' + jobID)
      .then(handleResponseToJson)
      // .then(rsp => (rsp.status == 200) ? rsp : (rsp.status == 401) ? Promise.reject("Unauthorized, need login.") : Promise.reject(new Error(rsp.statusText)))
      // .then((rsp) => rsp.json()) //if (rsp.status != 200) throw new Error("Invalid Server Response"); 
      .then((json) => {
        console.log({ json })
        const data: JSON_DATA = json;

        for (let row of data.jc_rows) {
          if (typeof row.qty === 'string')
            row.qty = Number.parseFloat(row.qty);
          if (typeof row.cost === 'string')
            row.cost = Number.parseFloat(row.cost);
          if (typeof row.rate === 'string')
            row.rate = Number.parseFloat(row.rate);
        }

        for (let d of data.jc_data) {
          if (typeof d.qty === 'string')
            d.qty = Number.parseFloat(d.qty);
        }


        setIsAdmin(data.ADMIN == true);
        setHasAccountingPermission(data.ACCT_RW == true);
        setJob(data.job);
        setRows(sortProcessRows(data.jc_rows));
        setCols(data.jc_cols);
        setUID(data.uid);
        setUName(data.uname);

        let wrdatesOnly = data.jc_wr_dates.map((d) => d.workdate);
        setWRDates(wrdatesOnly);

        const jobcost_bill_cols: JOBCOST_BILL_COLS[] = data.jobcost_bill_cols.map((b) => ({ orderno: Number.parseInt(b.orderno), col_id: b.col_id, date: b.date, status: Number.parseInt(b.status) }));

        setColBillStatus(new Map<string, JOBCOST_BILL_COLS>(jobcost_bill_cols.map((b) => [b.col_id, b])));

        // setData(data.jc_data);

        let mapData: { [rowid: string]: { [colid: string]: DATA } } = {};
        for (let row of data.jc_rows) {
          let row_id = row.row_id;
          if (mapData[row_id] == null) mapData[row_id] = {};

          for (let col of data.jc_cols) {
            let col_id = col.col_id;
            let rowColData = data.jc_data.find((d) => (d.col_id == col_id && d.row_id == '' + row_id));
            if (rowColData != null) {
              mapData[row_id][col_id] = rowColData;
            }
            else {
              // console.error("DATA NOT FOUND!@!!!! ", row_id, col_id);
              let newData: DATA = {
                row_id: '' + row_id,
                col_id: col_id,
                qty: 0,
                note: null
              };
              mapData[row_id][col_id] = newData;
            }
          }
        }
        setMapData(mapData);

      }).catch((error) => {
        showError(error, "Unable to load Job information for: " + jobID, false);
      }).finally(() => {
        setLoading(false);
        setActionsDisabled(false);
      });
  }

  useEffect(() => {
    calculateColSumm(summaryType);
  }, [mapData, summaryType]);

  const calculateColSumm = (sType: 'all' | 'specialty' | 'nonspecialty') => {
    let colSumm: { [colid: string]: COL_SUMM } = {};

    // Just the proposal items, if 100% utilization, what would be the rate/cost/profit
    let colEstSumm: { rate: number, cost: number, diff: number } = { rate: 0, cost: 0, diff: 0 };

    if (mapData != null) {
      for (let row of rows) {
        if (specialtyMatch(row, sType) === false) {
          continue;
        }

        let row_id = row.row_id;
        const rowQty = (row.qty || 0);
        const rowRate = (row.rate || 0);
        const rowCost = ((row.hasSub ? 0 : row.cost) || 0);

        if (row.jobcost_type == "equip" || row.jobcost_type == "unique") {
          colEstSumm.rate += rowQty * rowRate;
          colEstSumm.cost += rowQty * (row.cost || 0)
          colEstSumm.diff += rowQty * (rowRate - (row.cost || 0));
        }

        for (let col_id of Object.keys(mapData[row_id])) {
          let rowColData = mapData[row_id][col_id] || { qty: 0 };
          const qty = rowColData.qty;

          let existingSumm = colSumm[col_id] || { rate: 0, cost: 0, diff: 0, matcost: 0, subcost: 0, eqrentcost: 0, offcost: 0 };
          existingSumm.rate += qty * rowRate;
          existingSumm.cost += qty * rowCost;
          existingSumm.diff += qty * (rowRate - rowCost);

          if (row.jobcost_type === 'addl_material' || row.addl_cost_type === 'addl_material') {
            existingSumm.matcost += qty * rowCost;
          } else if (row.jobcost_type === 'addl_subcontractor' || row.addl_cost_type === 'addl_subcontractor') {
            existingSumm.subcost += qty * rowCost;
          } else if (row.jobcost_type === 'addl_rentalequip' || row.addl_cost_type === 'addl_rentalequip') {
            existingSumm.eqrentcost += qty * rowCost;
          } else if (row.jobcost_type === 'addl_interoffset' || row.addl_cost_type === 'addl_interoffset') {
            existingSumm.offcost += qty * rowCost;
          }

          colSumm[col_id] = existingSumm;
        }
      }
    }
    setColSumm(colSumm);
    setColEstSumm(colEstSumm);

    // queue upload of summs
    const proposedRate = colEstSumm.rate;
    const proposedCost = colEstSumm.cost;
    const actualRate = Object.values(colSumm).reduce((prev, curr) => prev + curr.rate, 0);
    const actualCost = Object.values(colSumm).reduce((prev, curr) => prev + curr.cost, 0);
    queueUploadSumms(proposedRate, proposedCost, actualRate, actualCost);

    // If we have data and we haven't shown an alert yet...
    if (mapData != null && proposalPopup == null) {
      const lastproposal = Number.parseFloat(job?.lastproposedtotal ?? "-1");
      if (lastproposal != -1) {
        const warningLvl = -Math.max(lastproposal * .20, 10);
        const diff = (actualRate - lastproposal);
        if (diff >= 0) {
          // Error
          setProposalPopup({ type: 'error' })
        } else if (diff >= warningLvl) {
          setProposalPopup({ type: 'warn' })
          // WARN
        }
      }
    }
  }

  const queueUploadSumms = useCallback(debounce((proposedRate: number, proposedCost: number, actualRate: number, actualCost: number) => {
    console.log("uploadSumms: ", { proposedRate, proposedCost, actualRate, actualCost });

    const formPostData: any = { target: 'jobcache', action: 'update', order_id: jobID, proposedRate, proposedCost, actualRate, actualCost }
    fetch('/api/json_jobcost_api.php', { method: "POST", body: JSON.stringify(formPostData) })
      .then(handleResponseToJson)
      .then((json: { success: boolean, order_id: string }) => {
        // loadAndProcess(jobID);
      })
      .catch((error) => {
        showError(error, "Problem updating summary calculations: " + error, true);
      });

  }, 2000), []);


  const showError = (error: any, message: string, temp = true) => {
    console.error("Showing " + ((temp) ? 'temp' : 'non-temp') + " error message/error :", message, error);
    setErrorMsg("");
    setErrorTempShow(temp);

    setTimeout(() => {
      setErrorMsg(message);
    }, 1000);
  }

  const hideError = () => {
    setErrorMsg("");
  };


  const showInfo = (message: string, temp = true) => {
    console.info("Showing " + ((temp) ? 'temp' : 'non-temp') + " error message/error :", message);
    setInfoMsg(message);
  }
  const hideInfo = () => {
    setInfoMsg("");
  };


  const generateHandlePopoverOpen = (content: string) => {
    return (event: React.MouseEvent<HTMLElement>) => {
      // setPopupText(content);
      // setPopupAnchorEl(event.currentTarget);
    }
  };
  const handlePopoverClose = () => {
    setPopupAnchorEl(null);
  };

  const handleFinishProject = () => {
    const formPostData: any = { target: 'job', action: 'finish', order_id: jobID }

    fetch('/api/json_jobcost_api.php', { method: "POST", body: JSON.stringify(formPostData) })
      .then(handleResponseToJson)
      .then((json: { success: boolean, order_id: string }) => {
        loadAndProcess(jobID);
      })
      .catch((error) => {
        props.showError(error, "" + error, false);
      });
  }

  const sortCols = (toSortCols: COL[]) => {
    return toSortCols.sort((a, b) => ('' + b.date).localeCompare(a.date));
  }

  const getTypeSortValue = (val: ROW_JOBCOST_TYPES) => {
    return (val === 'addl_interoffset') ? 8 : (val === 'addl_rentalequip') ? 7 : (val === 'addl_subcontractor') ? 6 : (val === 'addl_material') ? 5 : (val === 'additional') ? 4 : (val === 'extracrew') ? 3 : (val === 'extratruck') ? 2 : 1
  };

  function sortProcessRows(toSortRows: ROW[]): ROW[] {
    // Sort grouping of rows by 'normal/all other', trucks, crews, addl. Within those groups, sort by natural row_id
    const sortedArray = [...toSortRows].sort((a, b) => {
      let aVal = getTypeSortValue(a.jobcost_type);
      let bVal = getTypeSortValue(b.jobcost_type);

      let diff = aVal - bVal;
      return (diff != 0) ? diff : ('' + a.row_id).localeCompare('' + b.row_id);
    });

    // Next, if a row has a parent id, re-shuffle that row under it's parent
    const orderedUnderParent: ROWPLUS[] = [];
    for (const row of sortedArray) {
      if (row.addl_cost_parent) {
        let pos = orderedUnderParent.findIndex((r) => r.row_id == row.addl_cost_parent);
        if (pos >= 0) {
          orderedUnderParent.splice(pos + 1, 0, row);
          continue;
        }
      }
      orderedUnderParent.push(row);
    }

    // Set hasSub where applicable
    for (const row of orderedUnderParent) {
      if (orderedUnderParent.find((r) => row.row_id == r.addl_cost_parent))
        row.hasSub = true;
    }

    return orderedUnderParent;
  }

  const changeData = (data: DATA) => {
    setMapData((mapData) => {
      let localMapData = { ...mapData };
      localMapData[data.row_id][data.col_id] = data;
      return localMapData;
    });
  }

  const changeAddlRow = (action: 'add' | 'edit' | 'del'): Promise<void> => {
    return loadAndProcess(jobID);
  }
  const changeTruckRow = (action: 'add' | 'edit' | 'del'): Promise<void> => {
    return loadAndProcess(jobID);
  }

  const changeNote = (data: DATA, noteValue: string): void => {
    if (mapData) {
      mapData[data.row_id][data.col_id].note = noteValue;
      setMapData({ ...mapData });
    }
  }

  const onSummaryTypeChange = useMemo(() => (event: SelectChangeEvent<"all" | "specialty" | "nonspecialty">, child: React.ReactNode) => {
    // @ts-ignore
    setSummaryType(event.target.value);
  }, [])

  const changeCol = (col: COL, action: 'add' | 'edit' | 'del'): Promise<void> => {
    // let localCols = [...cols];

    // if (action === 'add') {
    //   localCols.push(col)
    // } else if (action === 'edit') {
    //   let indexOf = localCols.findIndex(c => c.col_id == col.col_id);
    //   if (indexOf != -1)
    //     localCols[indexOf] = col;
    // } else if (action === 'del') {
    //   localCols = localCols.filter(c => c.col_id != col.col_id);
    // }

    // sortCols(localCols);
    // setCols(localCols);

    return loadAndProcess(jobID);
  };

  const onBillingStatusChange = async (col: COL, newStatus: 0 | 1): Promise<void> => {
    // a=updateBillStatus&id=' + col.order_id + '&date=' + col.date + "&newStatus=" + newStatus)

    const formPostData: any = {
      target: 'colbilling',
      action: 'setbilling',
      order_id: col.order_id,
      date: col.date,
      newStatus: newStatus,
    }

    return fetch('/api/json_jobcost_api.php', { method: "POST", body: JSON.stringify(formPostData) })
      .then(handleResponseToJson)
      .then((json: { success: boolean, rows_updated: number }) => {
        console.log('/api/json_jobcost_api.php RSP:', json);
        showInfo("Updated billing on " + json.rows_updated + " date(s).")
      })
      .catch((error) => {
        showError(error, "" + error, false);
      }).finally(() => {
        loadAndProcess(jobID);
      });
  }

  const noWrap: React.CSSProperties = { whiteSpace: 'nowrap' };

  const t0 = performance.now();


  const [proposalPopup, setProposalPopup] = React.useState<null | { type: 'warn' | 'error' | 'closed' }>(null);

  let output = (
    <React.Fragment>

      {proposalPopup != null && proposalPopup.type != 'closed' &&
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {proposalPopup.type == 'error' ? <ErrorIcon color="error" fontSize="large" style={{ verticalAlign: 'middle' }} /> : <WarningIcon color="warning" fontSize="large" style={{ verticalAlign: 'middle' }} />} &nbsp;
            {proposalPopup.type == 'error' ? "ERROR: Proposal amount has been exceeded" : "WARNING: Proposal amount has almost been used"}.
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText id="alert-dialog-description">
              The last proposal had an estimated total of {asDollars(Number.parseFloat(job?.lastproposedtotal ?? "???"))}, however the current actual rate is: {asDollars(Object.values(colSumm).reduce((prev, curr) => prev + curr.rate, 0))}.
              <br/><br/>
              <b>Create an updated proposal and secure approval before further spending!</b>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { setProposalPopup({ type: 'closed' }) }}>Acknowledged</Button>
          </DialogActions>
        </Dialog>
      }

      <Box sx={{ flexGrow: 1 }}>
        <AppBar elevation={virtTrigger ? 8 : 0}>
          <Toolbar sx={{ whiteSpace: 'nowrap' }}>
            <IconButton
              size="small"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              href={"/job.php?id=" + jobID}
            >
              <ArrowBackIcon />
            </IconButton>

            <Typography variant="h6" component="h1">JobCost #{jobID} &nbsp; {virtTrigger}</Typography>
            {(job?.specialtyjob === '1') && <><FaPencilRulerIcon fontSize="15px" title={"Job is marked Specialty"} style={{ marginRight: 3 }} /></>}
            <Typography variant="subtitle2" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }} overflow="hidden" textOverflow="ellipsis">&nbsp; {job?.jobname}</Typography>
            <Box sx={{ flexGrow: 1 }}></Box>

            {
              // If it's not locked or closed, no need to show the lock override
              jobUnknownOrOtherwiseLocked == false ?
                "" :
                isAdmin ?
                  <Tooltip title="Admin detected, allowing edits" placement="bottom">
                    <Button variant="contained" color="warning" style={{ color: "red", backgroundColor: "rgb(211, 202, 47, 0.85)" }} startIcon={<WarningAmberIcon color="error" />}>
                      Lock Override
                    </Button>
                  </Tooltip>
                  : hasAccountingPermission ?
                    <Tooltip title="Accounting Permission detected, allowing edits" placement="bottom">
                      <Button variant="contained" color="warning" style={{ color: "red", backgroundColor: "rgb(211, 202, 47, 0.85)" }} startIcon={<WarningAmberIcon color="error" />}>
                        Lock Override
                      </Button>
                    </Tooltip>
                    : ""
            }
            &nbsp;
            {
              (job?.completiondate || job?.killeddate || job?.lock_ticket_ts) &&
              (
                <Button variant="contained" color="warning" disabled style={{ color: "white", backgroundColor: "rgb(211, 47, 47, 0.85)" }}>
                  Project {(job?.lock_ticket_ts) ? "Locked" : (job?.killeddate) ? "Killed" : "Finished"}:&nbsp;
                  {(job?.lock_ticket_ts) ? job?.lock_ticket_ts : (job?.killeddate) ? job?.killeddate : job?.completiondate}
                </Button>
              )
              ||
              (
                <Button variant="contained" color="warning" onClick={handleFinishProject}>
                  Finish Project
                </Button>
              )
            }

          </Toolbar>
        </AppBar>
        <Toolbar />
      </Box>
      <Snackbar
        open={(errorMsg != "")} onClose={hideError}
        autoHideDuration={(errorTempShow) ? 4000 : null}
        anchorOrigin={(errorTempShow) ? { vertical: 'bottom', horizontal: 'left' } : { vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={hideError} severity="error" sx={{ width: '100%' }}>
          <AlertTitle>Error Notification</AlertTitle>
          {errorMsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={(infoMsg != "")} onClose={hideInfo}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={hideInfo} severity="info" sx={{ width: '100%' }}>
          <AlertTitle>Notification</AlertTitle>
          {infoMsg}
        </Alert>
      </Snackbar>
      {job && job.orderno &&
        <WorkReportDialog open={workReportDialogDate != null} job={job} date={workReportDialogDate} onClose={() => { setWorkReportDialogDate(null) }} />
      }
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={(popupAnchorEl != null)}
        anchorEl={popupAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>{popupText}</Typography>
      </Popover>
      {/* Column ADD / EDIT / DEL modal */}
      <Modal open={openColumnModal != null} onClose={() => setOpenColumnModal(null)} closeAfterTransition BackdropProps={{ timeout: 500, }}      >
        <Fade in={openColumnModal != null}>
          <Box sx={{ position: 'absolute' as 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 2 }}>
            {openColumnModal != null && <ColumnDateSelection wrDates={wrDates} columns={cols} col={openColumnModal} order_id={jobID} toClose={(optDate?: string) => { setOpenColumnModal(null); }} showError={showError} changeCol={changeCol} />}
          </Box>
        </Fade>
      </Modal>
      {/* Row addl modal */}
      <Modal open={openRowAddlModal != null} onClose={() => setOpenRowAddlModal(null)} closeAfterTransition BackdropProps={{ timeout: 500, }}      >
        <Fade in={openRowAddlModal != null}>
          <Box sx={{ position: 'absolute' as 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 500, bgcolor: 'background.paper', boxShadow: 24, p: 2 }}>
            {openRowAddlModal != null && <RowAddlEditWidget readonly={readOnly} row={openRowAddlModal} allRows={rows} order_id={jobID} toClose={() => setOpenRowAddlModal(null)} showError={showError} changeRow={changeAddlRow} />}
          </Box>
        </Fade>
      </Modal>
      {/* Row truck modal */}
      <Modal open={openRowTruckModal != null} onClose={() => setOpenRowTruckModal(null)} closeAfterTransition BackdropProps={{ timeout: 500, }}      >
        <Fade in={openRowTruckModal != null}>
          <Box sx={{ position: 'absolute' as 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 600, bgcolor: 'background.paper', boxShadow: 24, p: 2 }}>
            {openRowTruckModal != null && <RowTruckEditWidget readonly={readOnly} row={openRowTruckModal} allRows={rows} order_id={jobID} toClose={() => setOpenRowTruckModal(null)} showError={showError} changeRow={changeTruckRow} />}
          </Box>
        </Fade>
      </Modal>
      {/* Row crew modal */}
      <Modal open={openRowCrewModal != null} onClose={() => setOpenRowCrewModal(null)} closeAfterTransition BackdropProps={{ timeout: 500, }}      >
        <Fade in={openRowCrewModal != null}>
          <Box sx={{ position: 'absolute' as 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 600, bgcolor: 'background.paper', boxShadow: 24, p: 2 }}>
            {openRowCrewModal != null && <RowCrewEditWidget readonly={readOnly} row={openRowCrewModal} order_id={jobID} toClose={() => setOpenRowCrewModal(null)} showError={showError} changeRow={changeTruckRow} />}
          </Box>
        </Fade>
      </Modal>
      {/* Note Add/Edit modal*/}
      <Modal open={openNoteModal != null} onClose={() => setOpenNoteModal(null)} closeAfterTransition BackdropProps={{ timeout: 500, }}      >
        <Fade in={openNoteModal != null}>
          <Box sx={{ position: 'absolute' as 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 600, bgcolor: 'background.paper', boxShadow: 24, p: 2 }}>
            {openNoteModal != null && <RowNoteEditWidget readonly={readOnly} order_id={jobID} data={openNoteModal} toClose={() => setOpenNoteModal(null)} showError={showError} changeNote={changeNote} />}
          </Box>
        </Fade>
      </Modal>

      {isLoading && <LinearProgress sx={{ marginTop: .15 }} />}

      {/* This menu drops down from Column headers */}
      <ColumnDateHeaderMenu
        colClickMenu={colClickMenu}
        colClickMenuEl={colClickMenuEl}
        colBillStatus={colBillStatus.get(colClickMenu?.col_id || "")}
        wrDates={wrDates}
        onBillingStatusChange={onBillingStatusChange}
        onOpenDateChange={(col) => { setOpenColumnModal(colClickMenu); }}
        closeMenu={setColClickMenu}
        openWRForDate={(date: string) => { setWorkReportDialogDate(date) }}
        job={job}
        disableEdits={readOnly}
      />
      {/* <Menu
        anchorEl={colClickMenuEl}
        open={(colClickMenu != null)}
        onClose={() => setColClickMenu(null)}
      >
        <MenuItem component="a" href={"/workreport.php?job=" + colClickMenu?.order_id + "&date=" + colClickMenu?.date} target="_window" onClick={() => { setColClickMenu(null); }} disabled={wrDates.find((d) => colClickMenu?.date == d) === undefined}>
          <ListItemIcon>
            <DocumentScannerIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>View Work Report</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => { setColClickMenu(null); }} selected={(colClickMenu) ? colBillStatus.get(Number.parseInt(colClickMenu?.col_id))?.status === 0 || colBillStatus.get(Number.parseInt(colClickMenu?.col_id))?.status === undefined : false}>
          <ListItemIcon>
            <BillingHoldIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Hold Billing</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => { setColClickMenu(null); }} selected={(colClickMenu) ? colBillStatus.get(Number.parseInt(colClickMenu?.col_id))?.status === 1 : false}>
          <ListItemIcon>
            <BillingQueuedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Queue for Billing</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => { setColClickMenu(null); }} selected={(colClickMenu) ? colBillStatus.get(Number.parseInt(colClickMenu?.col_id))?.status === 2 : false}>
          <ListItemIcon>
            <BilledIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Billing Processed
            "{(colClickMenu) ? colBillStatus.get(Number.parseInt(colClickMenu?.col_id))?.status + "" : "false"}"
          </ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => { setOpenColumnModal(colClickMenu); setColClickMenu(null); }}>
          <ListItemIcon>
            <CalendarTodayIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Change Date</ListItemText>
        </MenuItem>
      </Menu> */}



      {job && (
        <>
          <Table sx={{ padding: 0, margin: 0, width: "max-content" }} stickyHeader size="small" className="jobcosttable"> {/* width: "min-content" */}
            <TableHead style={{ position: 'sticky', background: 'white', zIndex: 1101, top: '64px' }} >
              <TableRow>
                <TableCell width={compact ? 265 : 475} style={{ position: 'sticky', left: '0', zIndex: 10, transition: "width 1.5s" }} >
                  <div style={{ display: 'flex' }}>
                    <ToggleButtonGroup
                      value={viewMode}
                      exclusive
                      size="small"
                      onChange={(a, b) => { setViewMode(b) }}
                      aria-label="view mode"
                    >

                      <ToggleButton value="compact" aria-label="compact" title="Compact View" onMouseEnter={generateHandlePopoverOpen("Compact View")} onMouseLeave={handlePopoverClose}>
                        <UnfoldLessIcon style={{ transform: "rotate(-90deg)" }} />
                      </ToggleButton>
                      <ToggleButton value="expand" aria-label="expand" title="Expanded View" onMouseEnter={generateHandlePopoverOpen("Expanded View")} onMouseLeave={handlePopoverClose}>
                        <UnfoldMoreIcon style={{ transform: "rotate(-90deg)" }} />
                      </ToggleButton>
                      {/* <ToggleButton value="report" aria-label="report" title="Report View" onMouseEnter={generateHandlePopoverOpen("Report View")} onMouseLeave={handlePopoverClose}>
                      <FullscreenIcon />
                    </ToggleButton> */}
                    </ToggleButtonGroup>

                    <PlusMenu readonly={readOnly} setOpenColumnModal={setOpenColumnModal} setOpenRowAddlModal={setOpenRowAddlModal} setOpenRowTruckModal={setOpenRowTruckModal} setOpenRowCrewModal={setOpenRowCrewModal} />

                    <ToggleButtonGroup
                      value={viewByCostOrCountToggle}
                      exclusive
                      size="small"
                      onChange={(a, b) => { setViewByCostOrCountToggle(b); }}
                      style={{ minWidth: 10, maxWidth: 250, marginLeft: 'auto', order: 2, transform: "scaleX(" + (compact ? 0 : 1) + ")", transformOrigin: 'right', transition: "transform 1.5s ease" }}
                    // sx={{ float: 'right' }}
                    >
                      {/* <ToggleButton value="viewByCount" title="Quantity" onMouseEnter={generateHandlePopoverOpen("View by Qty")} onMouseLeave={handlePopoverClose}>
                      <AttachMoneyIcon />
                    </ToggleButton> */}
                      <ToggleButton value="viewByCount" title="Quantity" onMouseEnter={generateHandlePopoverOpen("View by Qty")} onMouseLeave={handlePopoverClose}>
                        <NumbersIcon />
                      </ToggleButton>
                      <ToggleButton value="viewByCost" title="Cost" onMouseEnter={generateHandlePopoverOpen("View by Cost")} onMouseLeave={handlePopoverClose}>
                        <JoinLeftIcon />
                      </ToggleButton>
                      <ToggleButton value="viewByProfit" title="Profit" onMouseEnter={generateHandlePopoverOpen("View by Profit")} onMouseLeave={handlePopoverClose}>
                        <JoinInnerIcon />
                      </ToggleButton>
                      <ToggleButton value="viewByRate" title="Rate" onMouseEnter={generateHandlePopoverOpen("View by Rate")} onMouseLeave={handlePopoverClose}>
                        <JoinRightIcon />
                      </ToggleButton>
                    </ToggleButtonGroup>

                    {/* {!viewByCount && <MoneyOffIcon />}
                    {viewByCount && <AttachMoneyIcon />}
                    {!viewByCount && <MoneyOffIcon />}
                    {viewByCount && <AttachMoneyIcon />}
                    {!viewByCost && <JoinLeftIcon />}
                    {viewByCost && <JoinFullIcon />} */}
                  </div>
                </TableCell>
                {cols.map((c) => {
                  let parsed = parseISOToMDYY(c.date);
                  let colValue = parsed || c.date;// (parsed) ? (parsed.getMonth() + 1) + "/" + parsed.getDate() + "/" + parsed.getFullYear() : c.date;
                  let colBillStatRow = colBillStatus.get(c.col_id);
                  let cbStatus = colBillStatRow ? colBillStatRow.status : 0;
                  return (
                    <TableCell key={c.col_id} align="center">
                      <Button variant="text" color="inherit" fullWidth onClick={(event) => {
                        // setOpenColumnModal(c)
                        setColClickMenuEl(event.currentTarget)
                        setColClickMenu(c);
                      }}
                        startIcon={(wrDates.indexOf(c.date) > -1) ? <DocumentScannerIcon /> : undefined}
                        endIcon={cbStatus == 0 ? <BillingHoldIcon htmlColor="red" /> : cbStatus == 1 ? <BillingQueuedIcon htmlColor="green" /> : cbStatus >= 2 ? <BilledIcon htmlColor="green" /> : <ErrorIcon htmlColor="red" />}
                      >
                        {colValue}
                      </Button>
                    </TableCell>
                  );
                })}

              </TableRow>
            </TableHead>
            <TableBody>
              {mapData && rows.map((row, rowIdx) => {
                //[...rows] before, why?

                // let dataArr = Object.values(mapData[row.row_id] || {});
                // let dataArr = data.filter((d) => (row.row_id == d.row_id));

                let dataArr: { [colid: string]: DATA } = mapData[row.row_id] || {};

                return <JobCostRow key={row.row_id}
                  row={row} rowIdx={rowIdx} dataArr={dataArr} cols={cols} jobspecialty={job.specialtyjob} colBillStatus={colBillStatus}
                  compact={compact} viewByCount={viewByCount} viewByCost={viewByCost} viewByRate={viewByRate} viewByProfit={viewByProfit} trigger={trigger}
                  setOpenRowAddlModal={setOpenRowAddlModal} setOpenRowTruckModal={setOpenRowTruckModal} setOpenRowCrewModal={setOpenRowCrewModal}
                  mapData={mapData} setOpenNoteModal={setOpenNoteModal} actionsDisabled={actionsDisabled} onDataChange={changeData}
                  uid={uid} uname={uname}
                  noWrap={noWrap} readonly={readOnly}
                />

              })// End Row Map
              }

              {/* Summary Row at bottom of table - ORIGINAL */}
              <TableRow
                key='summrow'
                sx={{ backgroundColor: 'rgba(176,196,222,.85)' }} //lightsteelblue
                style={{ left: 0, bottom: 0, zIndex: 2, position: 'sticky' }}
              >
                <TableCell component="th" scope="row" style={{ position: 'sticky', left: '0', zIndex: 1, backgroundColor: 'rgba(176,196,222,.85)' }}
                  sx={{ overflow: "clip", boxShadow: (!trigger) ? "" : "7px 0 9px -7px rgb(0 0 0 / 40%)", maxWidth: 300, paddingTop: 0, paddingBottom: 0 }}>
                  <table cellSpacing={0} width={475} style={{ width: 475 }}>
                    <tbody>
                      <tr>
                        <td valign="top" width="100%" colSpan={3}>
                          <Box title="Summary Row" paddingLeft={1}>
                            <Typography noWrap paddingLeft={0} fontWeight='bold'>
                              Summary
                            </Typography>

                            <Select
                              labelId="summary-type-select-label"
                              id="summary-type-select"
                              value={summaryType}
                              label="summaryType"
                              onChange={onSummaryTypeChange}
                              variant="standard"
                              sx={{ paddingLeft: 1 }}
                            >
                              <MenuItem value={"all"}>Show All Data</MenuItem>
                              <MenuItem value={"specialty"}>Specialty Only</MenuItem>
                              <MenuItem value={"nonspecialty"}>Non-Specialty</MenuItem>
                            </Select>
                            <FormGroup>
                              <FormControlLabel control={<Checkbox />} label="Expand Costs" name="summaryExpand" checked={summaryExpand} onChange={(ignore: any, checked: boolean) => setSummaryExpand(checked)} />
                            </FormGroup>

                          </Box>
                        </td>
                        <td width={2}></td>
                        <td rowSpan={2} height="0px" style={{ borderLeft: "1px solid darkgrey", minWidth: 175 }}>
                          <Box style={{ transform: "scale(" + (compact ? 0 : 1) + ")", transformOrigin: 'top', transition: "height 1.5s linear, transform 1.5s ease" }}>
                            {colSumm && (
                              <table cellPadding={0} cellSpacing={0} style={{ paddingLeft: 5 }}>
                                <tbody>
                                  <tr><td style={noWrap}>Total Rate:</td><td width={5}></td><td style={noWrap} align="right" title={Object.values(colSumm).reduce((prev, curr) => prev + curr.rate, 0) + ''}>{asDollars(Object.values(colSumm).reduce((prev, curr) => prev + curr.rate, 0))}</td></tr>
                                  <tr><td style={noWrap}>Total Cost:</td><td></td><td style={noWrap} align="right" title={Object.values(colSumm).reduce((prev, curr) => prev + curr.cost, 0) + ''}>{asDollars(Object.values(colSumm).reduce((prev, curr) => prev + curr.cost, 0))}</td></tr>
                                  {summaryExpand && <>
                                    <tr><td style={noWrap}>- Offset:</td><td></td><td style={noWrap} align="right" title={Object.values(colSumm).reduce((prev, curr) => prev + curr.cost, 0) + ''}>{asDollars(Object.values(colSumm).reduce((prev, curr) => prev + curr.offcost, 0))}</td></tr>
                                    <tr><td style={noWrap}>- Mat Cost:</td><td></td><td style={noWrap} align="right" title={Object.values(colSumm).reduce((prev, curr) => prev + curr.cost, 0) + ''}>{asDollars(Object.values(colSumm).reduce((prev, curr) => prev + curr.matcost, 0))}</td></tr>
                                    <tr><td style={noWrap}>- Sub Cost:</td><td></td><td style={noWrap} align="right" title={Object.values(colSumm).reduce((prev, curr) => prev + curr.cost, 0) + ''}>{asDollars(Object.values(colSumm).reduce((prev, curr) => prev + curr.subcost, 0))}</td></tr>
                                    <tr><td style={noWrap}>- Eq. Rent:</td><td></td><td style={noWrap} align="right" title={Object.values(colSumm).reduce((prev, curr) => prev + curr.cost, 0) + ''}>{asDollars(Object.values(colSumm).reduce((prev, curr) => prev + curr.eqrentcost, 0))}</td></tr>
                                  </>}
                                  <tr><td style={noWrap}>Total Profit:</td><td></td><td style={noWrap} align="right" title={Object.values(colSumm).reduce((prev, curr) => prev + curr.diff, 0) + ''}>{asColorDollars(Object.values(colSumm).reduce((prev, curr) => prev + curr.diff, 0))}</td></tr>
                                </tbody>
                              </table>
                            )}
                          </Box>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </TableCell>
                {
                  cols.map((c, idx) => {
                    let found = (colSumm[c.col_id]) ? colSumm[c.col_id] : null;
                    if (found)
                      return (
                        <TableCell key={c.col_id} align="right">
                          <table cellPadding={0} cellSpacing={0} style={{ paddingLeft: 5 }}>
                            <tbody>
                              <tr><td style={noWrap}>Rate:</td><td width={5}></td><td style={noWrap} title={num2str(found.rate)}>{asDollars(found.rate)}</td></tr>
                              <tr><td style={noWrap}>Cost:</td><td></td><td style={noWrap} title={num2str(found.cost)}>{asDollars(found.cost)}</td></tr>
                              {summaryExpand && <>
                                <tr><td style={noWrap}>- Off:</td><td></td><td style={noWrap} title={num2str(found.matcost)}>{asDollars(found.offcost)}</td></tr>
                                <tr><td style={noWrap}>- Mat:</td><td></td><td style={noWrap} title={num2str(found.matcost)}>{asDollars(found.matcost)}</td></tr>
                                <tr><td style={noWrap}>- Sub:</td><td></td><td style={noWrap} title={num2str(found.subcost)}>{asDollars(found.subcost)}</td></tr>
                                <tr><td style={noWrap}>- EqRent:</td><td></td><td style={noWrap} title={num2str(found.eqrentcost)}>{asDollars(found.eqrentcost)}</td></tr>
                              </>}
                              <tr><td style={noWrap}>Profit:</td><td></td><td style={noWrap} title={num2str(found.rate - found.cost)}>{asColorDollars(found.rate - found.cost)}</td></tr>
                            </tbody>
                          </table>
                        </TableCell>
                      );
                    else
                      return <TableCell key={c.col_id} width={85} align="right" color="red">ERROR</TableCell>

                  })
                }
              </TableRow>
            </TableBody>
          </Table>

          <div style={{ backgroundColor: '#E7EBF0', position: 'sticky', left: 15, padding: '15px', width: 'fit-content' }}>
            <Paper style={{ width: 'fit-content' }}>
              <h2 style={{ marginLeft: 10 }}>Estimated Summary {(summaryType == "specialty") ? " - Specialty Only" : (summaryType == "nonspecialty") ? " - Non-Specialty Only" : ""}</h2>
              <Table sx={{ width: 'initial' }} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="right" style={{ fontWeight: 'bold' }}>Proposed</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right" style={{ fontWeight: 'bold' }}>Actual</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right" style={{ fontWeight: 'bold' }}>Difference</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Rate</TableCell>
                    <TableCell align="right">{asDollars(colEstSumm.rate)}</TableCell>
                    <TableCell align="right">&nbsp;vs&nbsp;</TableCell>
                    <TableCell align="right">{asDollars(Object.values(colSumm).reduce((prev, curr) => prev + curr.rate, 0))}</TableCell>
                    <TableCell align="right">&nbsp;=&nbsp;</TableCell>
                    <TableCell align="right">{asColorDollars(-colEstSumm.rate + Object.values(colSumm).reduce((prev, curr) => prev + curr.rate, 0))}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Cost</TableCell>
                    <TableCell align="right">{asDollars(colEstSumm.cost)}</TableCell>
                    <TableCell align="right">&nbsp;vs&nbsp;</TableCell>
                    <TableCell align="right">{asDollars(Object.values(colSumm).reduce((prev, curr) => prev + curr.cost, 0))}</TableCell>
                    <TableCell align="right">&nbsp;=&nbsp;</TableCell>
                    <TableCell align="right">{asColorDollars(-colEstSumm.cost + Object.values(colSumm).reduce((prev, curr) => prev + curr.cost, 0), 2, true)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Profit</TableCell>
                    <TableCell align="right">{asColorDollars(colEstSumm.diff)}</TableCell>
                    <TableCell align="right">&nbsp;vs&nbsp;</TableCell>
                    <TableCell align="right">{asColorDollars(Object.values(colSumm).reduce((prev, curr) => prev + curr.diff, 0))}</TableCell>
                    <TableCell align="right">&nbsp;=&nbsp;</TableCell>
                    <TableCell align="right">{asColorDollars(-colEstSumm.diff + Object.values(colSumm).reduce((prev, curr) => prev + curr.diff, 0))}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </div>
        </>
      )}
    </React.Fragment >
  );
  if (process.env.NODE_ENV === 'development')
    console.log("JobCost render: ", (performance.now() - t0), 'ms');
  return output;
}

