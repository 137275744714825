import React, { useState, useEffect, useMemo, ChangeEvent } from 'react';
import * as api from '../jobtix/apiCalls';
import axios from 'axios';

import JobMgrHeader from '../utils/JobMgrHeader';
import { tryFormatDate } from '../jobtix/JobList';
import { ExportToCsv } from 'export-to-csv';

import { DataGrid, GridColDef, GridColumnVisibilityModel, GridSortModel, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridValueFormatterParams } from '@mui/x-data-grid';
import { Container, Typography, LinearProgress, Paper, Button, Link, TablePagination } from '@mui/material';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { tryASDollars, tryDispRound } from '../utils/Functions';
import { Download } from '@mui/icons-material';


type DATA = {
  Estimator: string,
  FullTruckField: string,
  JobName: string,
  Office_Name: string,
  TotalDayRate: number,
  TotalTruckHrs: number,
  TruckDayRatePart: number,
  TruckHourlyRate: number,
  customer: string,
  customerno: number,
  date: string,
  hours: number,
  orderno: number,
  truck: string,
}
export type LIST_RESP<DATA> = { totalsize: { count: number }, results: DATA[] }

const dispDollarFunc = (params: GridValueFormatterParams<any>) => tryASDollars(params.value, 2);
const columns: GridColDef[] = [
  { field: 'date', headerName: 'Date', width: 100, filterable: false },
  { field: 'orderno', headerName: 'Job #', width: 75, align: 'center', headerAlign: 'center', filterable: false },
  { field: 'JobName', headerName: 'Job Name', width: 150, filterable: false },
  { field: 'Office_Name', headerName: 'Office', width: 100, filterable: false },
  { field: 'Estimator', headerName: 'Estimator', width: 125, filterable: false },
  { field: 'truck', headerName: 'Truck', width: 75, filterable: false },
  { field: 'TruckDayRatePart', headerName: 'Truck Daily', width: 100, valueFormatter: dispDollarFunc, align: 'right', headerAlign: 'right', filterable: false },
  { field: 'hours', headerName: 'hrs', width: 50, align: 'center', headerAlign: 'center', filterable: false },
  { field: 'TruckHourlyRate', headerName: 'TruckHrRate', width: 100, valueFormatter: dispDollarFunc, align: 'right', headerAlign: 'right', filterable: false },
  { field: 'customer', headerName: 'Customer', width: 150, filterable: false },
  { field: 'customerno', headerName: 'Cust #', width: 100, filterable: false },
  { field: 'TotalDayRate', headerName: 'TotalDayRate', width: 100, valueFormatter: dispDollarFunc, filterable: false },
  { field: 'TotalTruckHrs', headerName: 'TotalTruckHrs', width: 100, filterable: false },
  { field: 'FullTruckField', headerName: 'FullTruckField', width: 150, filterable: false },
];

const options = {
  filename: 'TruckRateReport-' + new Date().toISOString().split('T')[0],
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  showTitle: false,
  title: 'Report: Truck rates based on hrs in job costing',
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true,
  // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
};

const csvExporter = new ExportToCsv(options);

type ExportedData = {
  JobNo: number;
  SageID: string | null;
  Name: string;
  OfficeName: string;
  StartDate: string | null;
  CompletionDate: string | null;
  KilledDate: string | null;
};

let currentProcessCount = 0;

export default function ReportTruckRate() {

  // These are 'query' params
  const [reset, setReset] = useState(1);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>({
      Estimator: true,
      FullTruckField: false,
      JobName: true,
      Office_Name: true,
      TotalDayRate: false,
      TotalTruckHrs: false,
      TruckDayRatePart: true,
      TruckHourlyRate: true,
      customer: true,
      customerno: true,
      date: true,
      hours: true,
      orderno: true,
      truck: true,
    });
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: 'date',
      sort: 'desc',
    },
  ]);

  // Results from query data coming in & display data
  const [data, setData] = useState<DATA[] | null>(null);
  const [count, setCount] = useState(0);
  const [error, setError] = useState<string | null>();
  const [isBusy, setBusy] = React.useState(false);


  useEffect(() => {
    let params: any = {
      t: 'report',
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
      sortby: sortModel[0]?.field,
      sortorder: sortModel[0]?.sort
    };

    currentProcessCount++;
    let thisPCount = currentProcessCount;

    // console.log("jsonFetcher() start:", params);
    setBusy(true);
    axios.get('/api/report_truck_rate.php', { params: params })
      .catch(api.catchUnauthorized)
      .catch((error) => { setError(JSON.stringify(error)) })
      .then((resp) => resp.data as LIST_RESP<DATA>)
      .then((json) => {
        // console.log("jsonFetcher() complete:", params);

        // If another fetch is already in progress, abort this one
        if (currentProcessCount != thisPCount) {
          console.log('ABORTED');
          return;
        }


        let idCount = 0;
        let rawData = json.results.map((d) => { (d as any)['id'] = idCount++; return d });

        setData(rawData);
        setCount(json.totalsize.count);
      })
      .finally(() => {
        setBusy(false);
      });

  }, [reset, paginationModel, sortModel])

  const CustomToolbar = useMemo(() => {
    const onDownload = () => {
      let params: any = { t: 'report', page: 0, pageSize: 100000 };

      // console.log("jsonFetcher() start:", params);
      setBusy(true);
      axios.get('/api/report_truck_rate.php', { params: params })
        .catch(api.catchUnauthorized)
        .catch((error) => { setError(JSON.stringify(error)) })
        .then((resp) => resp.data as LIST_RESP<DATA>)
        .then((json) => {
          // console.log("jsonFetcher() complete:", params);

          let idCount = 0;
          let rawData = json.results;// .map((d) => { (d as any)['id'] = idCount++; return d });
          csvExporter.generateCsv(rawData);
        })
        .finally(() => {
          setBusy(false);
        });

    };

    return (props: any) => {
      console.log("toobar props", props); return (
        <GridToolbarContainer>
          <div><b>Report:</b> Show truck utilization rates based on hrs in job costing</div>
          <GridToolbarColumnsButton style={{ marginLeft: 'auto' }} />
          {/* <GridToolbarFilterButton /> */}
          {/* <GridToolbarDensitySelector /> */}
          {/* <GridToolbarExport /> */}
          <Button size='small' startIcon={<Download />} onClick={onDownload}>Export as CSV</Button>
        </GridToolbarContainer>
      )
    };
  }, []);

  // const onChangePage = useMemo(() => (ignore: any, newPage: number) => {
  //   if (Number.isFinite(newPage) && newPage > 0) {
  //     setPage(newPage);
  //   }
  // }, [])
  // const onChangeRowsPerPage: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = useMemo(() => (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
  //   const newRPP = Number.parseInt(event.target.value);
  //   if (Number.isFinite(newRPP) && newRPP > 0) {
  //     setRowPerPage(newRPP);
  //   }
  // }, [])

  // .textContainer {
  //   display: block;
  //   width: 200px;
  //   white-space: nowrap;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  // }

  return <>
    <JobMgrHeader>
      <Typography variant="h6" component="h1">
        Job Report: Truck Rate
      </Typography>
    </JobMgrHeader>

    <Container>
      {error && <Typography>ERROR: {error}</Typography>}
      {!error && !data && <Typography>Loading... <LinearProgress /></Typography>}
      {!error && data && <>
        {/* Download and display only contains {data.length} records, out of a total {count}. */}
        {/* <br /> */}
        {/* display: (isBusy) ? "block" : "none" */}
        <LinearProgress variant='indeterminate' style={{ visibility: (isBusy) ? "visible" : "hidden" }} />

        <div style={{ width: '100%', height: 700 }}>
          <DataGrid
            rows={data}
            columns={columns}
            rowCount={count}
            loading={isBusy}
            pageSizeOptions={[10, 50, 500]}
            paginationModel={paginationModel}
            paginationMode="server"
            onPaginationModelChange={setPaginationModel}
            slots={{
              toolbar: CustomToolbar,
            }}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            sortModel={sortModel}
            onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          />
        </div>

      </>}
    </Container>
  </>;
}


