import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { IconButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { CheckBox as CheckBoxIcon, CheckBoxOutlineBlank as UnCheckBoxIcon, DisabledByDefault as DisabledBoxIcon, ExitToApp as ExitToAppIcon } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';

const options = ['Create a merge commit', 'Squash and merge', 'Rebase and merge'];

const STATUS_TO_ICON = [
  ClearIcon,
  UnCheckBoxIcon,
  CheckBoxIcon, // color="primary"
  DisabledBoxIcon,
  ExitToAppIcon
];
export const STATUS_TO_TEXT = [
  "No start date",
  "Can be exported",
  "Queued for export",
  "Excluded from export",
  "Previously exported"
];
export const STATUS_TO_TEXT2 = [
  "No Start",
  "Ready",
  "Queued",
  "Excluded",
  "Exported"
];

export default function ComponentLienActionBtn(props: React.PropsWithoutRef<{ value: number, original: number, onChange: (newValue: number) => void }>) {
  const [open, setOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(props.value);

  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleClick = () => {
    // console.info(`You clicked ${options[selectedIndex]}`);
    // if (selectedIndex != 3)
    // setSelectedIndex()

    let newSelected = Math.max(1, ((selectedIndex + 1) % 4));
    setSelectedIndex(newSelected);
    props.onChange(newSelected);

  };
  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    setOpen(false);
    setSelectedIndex(index);
    props.onChange(index);
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const disabled = (selectedIndex === 0 || selectedIndex === 4);
  const StatusIcon = STATUS_TO_ICON[selectedIndex];
  const isChanged = (selectedIndex !== props.original);

  return (
    <React.Fragment>
      <ButtonGroup variant="text" ref={anchorRef} aria-label="split button">
        <IconButton onClick={handleClick} disabled={disabled}>
          <StatusIcon color={isChanged ? "primary" : undefined} /> &nbsp; <Typography color={isChanged ? "primary" : undefined} fontSize="medium">{STATUS_TO_TEXT2[selectedIndex]}</Typography>
        </IconButton>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          disabled={disabled}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {STATUS_TO_ICON.map((StatusIcon, index) => (
                    <MenuItem
                      key={index}
                      disabled={index === 0 || index === 4}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      <ListItemIcon>
                        <StatusIcon />
                      </ListItemIcon>
                      <ListItemText>{STATUS_TO_TEXT[index]}</ListItemText>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
