import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { Typography, LinearProgress, TableContainer } from '@mui/material';
import { Grid, Paper } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export type JOB_SCHED = {
  id: number,
  OrderNo: number,
  employee_id: number,
  StartDate: string,
  EstmDays: number,
  nick_name: string,
  name: string,
}

function JobSchedule(props: { id: number }) {
  const [isBusy, setBusy] = useState(false);
  const [rows, setRows] = useState<JOB_SCHED[]>([]);

  useEffect(() => {
    setBusy(true);

    axios.get('/api/json.php', { params: { a: 'get', t: 'jobsched', jobid: props.id } })
      .then((resp) => resp.data as { "job_sched": JOB_SCHED[] })
      .then((json) => {
        setRows(json.job_sched);
        return json.job_sched;
      })
      .catch((error) => {
        console.error("ERROR in looking up customer: ", error);
      }).finally(() => {
        setBusy(false);
      })
  }, [props.id]);

  return (
    <Grid container spacing={2}>
      {isBusy && <Grid item xs={12}><LinearProgress /></Grid>}
      <Grid item xs={12}><Typography variant="h4">Schedule</Typography></Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>

                <TableCell>Foreman</TableCell>
                <TableCell align="center">Start Date</TableCell>
                <TableCell align="center">Duration</TableCell>
                <TableCell align="center">End Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows && rows.length > 0 && rows.map((row: JOB_SCHED) => (
                <TableRow key={row.id}>
                  <TableCell>{(row.nick_name) ? row.nick_name : row.name}</TableCell>
                  <TableCell align="center">{row.StartDate}</TableCell>
                  <TableCell align="center">{row.EstmDays} days</TableCell>
                  <TableCell align="center">{row.StartDate} + {row.EstmDays}</TableCell>
                </TableRow>
              ))}
              {(!rows || rows.length == 0) && (
                <TableRow key='empty'>
                  <TableCell colSpan={4}><Typography variant="h6"> No results</Typography></TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
export default JobSchedule;