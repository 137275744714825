
export function toMMDDYYY(input: Date | null | undefined, ifNull = "") {
  if (input === null || input === undefined || Number.isNaN(input)) return ifNull;

  return (input.getUTCMonth() + 1) + '/' + input.getUTCDate() + '/' + input.getUTCFullYear();
}

export function isoToMMDDYYY(input: string | null | undefined, ifNull = "") {
  if (input === null || input === undefined) return ifNull;
  const parsed = input?.match(/^(\d\d\d\d)-(\d\d)-(\d\d)/);
  if (parsed === null || parsed === undefined) return input;

  return parsed[2] + '/' + parsed[3] + '/' + parsed[1];
}

export function tryDispRound(input: number | string, dec: number = 2): number | string {
  let parsed = input;
  if (typeof parsed === 'string')
    parsed = Number.parseFloat(parsed);

  if (typeof parsed === 'number' && !Number.isNaN(parsed))
    return dispRound(parsed, dec);
  else
    return input;
}

export function dispRound(input: number, dec: number = 2): number {
  let pos = 1;
  if (dec > 0)
    pos = Math.pow(10, dec);

  return Math.round((input + Number.EPSILON) * pos) / pos;
  // return new Number(input).toFixed(dec);
}

function internal2Round(input: number): number {
  return Math.round((input + Number.EPSILON) * 100) / 100;
}

let asDollarsCache: { [key: number]: string } = {};

export function tryASDollars(input: number | string | null, dec: number = 2): string {
  if (typeof input === 'string') {
    let parsed = Number.parseFloat(input);
    if (typeof parsed === 'number' && !Number.isNaN(parsed))
      return parsed.toLocaleString('en-US', { minimumFractionDigits: dec, maximumFractionDigits: dec, style: 'currency', currency: 'USD' });
    else
      return input;
  } else if (input !== null) {
    return input.toLocaleString('en-US', { minimumFractionDigits: dec, maximumFractionDigits: dec, style: 'currency', currency: 'USD' });;
  } else {
    return "";
  }
}

/* This version ignores dec, always returns 4 decimals... need to hunt down where it's used before fixing */
export function asDollars(input: number | null, dec: number = 2): string {
  if (input === null) return "";

  if (dec === 2) {
    if (asDollarsCache[input])
      return asDollarsCache[input];
  }

  // let num = Math.round((input + Number.EPSILON) * 100) / 100;
  let rounded = input;//internal2Round(input);
  let out = rounded.toLocaleString('en-US', { minimumFractionDigits: dec, maximumFractionDigits: 4, style: 'currency', currency: 'USD' });
  if (dec === 2) {
    asDollarsCache[input] = out;
  }

  return out;
}

export function num2str(input: number | null | undefined) {
  return (input && Number.isNaN(input) == false) ? '' + input : undefined;
}

export function toIntOr(input: string | number | null | undefined, defaultValue: number): number {
  if (typeof input === 'number' && !Number.isNaN(input)) {
    return input;
  } else if (typeof input === 'string') {
    let parsed = Number.parseInt(input);
    if (!Number.isNaN(parsed)) {
      return parsed;
    }
  }

  return defaultValue;
}

export const sageCodes = [
  ['10', "Vacuum"],
  ['20', "CCTV"],
  ['30', "Lining"],
  ['40', "Grouting"],
  ['50', "Specialty"],
  ['60', "Sag Removal"],
]

export function sagecodetotext(code: string) {
  if (code == '10') return "Vacuum"
  if (code == '20') return "CCTV"
  if (code == '30') return "Lining"
  if (code == '40') return "Grouting"
  if (code == '50') return "Specialty"
  if (code == '60') return "Sag Removal"
  return "";
}