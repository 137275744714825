import { useEffect, useState } from 'react';
import useSWR from 'swr';
import * as api from './apiCalls';

import { Button, Typography, LinearProgress, Link, TableContainer, Stack, Box, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, TextField, Checkbox, InputAdornment, IconButton } from '@mui/material';
import { Grid, Paper } from '@mui/material';

import ReplayIcon from '@mui/icons-material/Replay';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PeopleIcon from '@mui/icons-material/People';
import AddIcon from '@mui/icons-material/Add';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { JC_ROW, JOB_ITEM_ROW } from "./Types";
import { asDollars, sageCodes, sagecodetotext } from '../utils/Functions';
import { EDIT_JC_ROW } from './JobTixItems';
import DeleteIcon from '@mui/icons-material/Delete';


// TODO: Add isGrout filtering
function EquipmentTypeSelect(props: { value: number | null, isDeleted: boolean }) {
  const equipTypeSWR = useSWR("getEquipmentTypes", api.getEquipmentTypes);
  const v = props.value;

  if (equipTypeSWR.data)
    return (
      <Select value={(v) ? '' + v : null} onChange={() => { }} variant='standard' disabled={props.isDeleted}
        renderValue={(v) => equipTypeSWR.data?.find((d) => ('' + d.EquipmentTypeId == v))?.EquipmentName || v}
      >
        {equipTypeSWR.data.filter((e) => e.active).map((e) => <MenuItem value={e.EquipmentTypeId}>{e.EquipmentName}</MenuItem>)}
      </Select>
    );
  else
    return null;
}


export function JTItemRow(props: { row: JC_ROW, editRow: EDIT_JC_ROW | null, isEditMode: boolean, notifyChange: (row_id: number) => void }) {
  const [update, setUpdate] = useState(1);
  const origRow = props.row;
  const { editRow, isEditMode } = props;

  const unitTypeSWR = useSWR("getUnitTypes", api.getUnitTypes);


  const neChange = (key: keyof EDIT_JC_ROW) => {
    return (event: SelectChangeEvent | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (editRow != null) {
        let value = event.target.value;
        //@ts-ignore
        let booleanValue: boolean = event.target.checked;

        if (key == 'qty') {
          editRow['qty'] = (value == '') ? 0 : Number.parseFloat(value);
        } else if (key == 'cost' || key == 'rate' || key == 'unit_type') {
          editRow[key] = (value == '') ? null : Number.parseFloat(value);
        } else if (key == 'show_proposal' || key == 'info_on_proposal') {
          editRow[key] = (booleanValue === true) ? 1 : 0;
        } else {

          // @ts-ignore
          editRow[key] = value;
        }

        // delete: boolean
        // show_proposal: null | 0 | 1,
        // info_on_proposal: null | 0 | 1,

        setUpdate((old) => old + 1);
        props.notifyChange(props.row.row_id);
      }
    };
  }

  const toggleDeleted = () => {
    if (editRow) {
      editRow['delete'] = (editRow['delete']) ? false : true;
      setUpdate((old) => old + 1);
      props.notifyChange(props.row.row_id);
    }
  }

  // const row: EDIT_JC_ROW = editRow || origRow;

  if (isEditMode && editRow) {
    const row = editRow;
    const isDeleted = row['delete'] == true;

    return (<>
      <TableRow key={row.row_id} style={{ position: 'relative' }}>
        <TableCell>
          <div style={{ display: (editRow['delete']) ? 'block' : 'none', position: 'absolute', top: '50%', left: 10, right: 40, height: '1px', backgroundColor: 'red' }} />
          <Select value={row.costcode} onChange={neChange('costcode')} variant='standard' disabled={isDeleted}>
            <MenuItem key={0} value={0} disabled></MenuItem>
            {sageCodes.map((e) => <MenuItem key={e[0]} value={e[0]}>{e[1]}</MenuItem>)}
          </Select>
        </TableCell>
        <TableCell>
          {(editRow.jobcost_type === 'equip')
            ? <EquipmentTypeSelect value={row.equip_type} isDeleted={isDeleted} />
            : <TextField name='name' value={row.name} onChange={neChange('name')} variant='standard' disabled={isDeleted} />
          }
        </TableCell>
        {/* {row.jobcost_type}  */}
        {/* -/- {row.equipmentname} */}
        <TableCell align="right" title={(row.info) ? row.info : undefined} style={{ maxWidth: '200px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
          <TextField name='info' value={row.info} onChange={neChange('info')} variant='standard' disabled={isDeleted} />
        </TableCell>
        <TableCell align="right" padding='none'><Checkbox name="info_on_proposal" checked={row.info_on_proposal == 1} onChange={neChange('info_on_proposal')} disabled={isDeleted} /></TableCell>
        <TableCell align="right">
          {unitTypeSWR.data &&
            <Select value={(row.unit_type) ? '' + row.unit_type : undefined} onChange={neChange('unit_type')} variant='standard' disabled={isDeleted}
              renderValue={(v) => unitTypeSWR.data?.find((d) => ('' + d.UnitTypeID == v))?.Units || v}
            >
              <MenuItem key='' value='' disabled></MenuItem>
              {unitTypeSWR.data.map((e) => <MenuItem key={e.UnitTypeID} value={e.UnitTypeID}>{e.UnitTypeName} / {e.Units}</MenuItem>)}
            </Select>
          }
        </TableCell>
        {/* {row.unit_type} */}
        <TableCell align="right">
          <TextField name="cost" value={row.cost} autoComplete='off' variant='standard' sx={{ 'input': { textAlign: 'right' } }}
            onChange={neChange('cost')} disabled={isDeleted}
            inputProps={{ type: 'number', step: '.01' }}
            InputProps={{ inputMode: 'numeric', startAdornment: (<InputAdornment position="start">$</InputAdornment>) }}
          />
        </TableCell>
        <TableCell align="right">
          <TextField name="rate" value={row.rate} autoComplete='off' variant='standard' sx={{ 'input': { textAlign: 'right' } }}
            onChange={neChange('rate')} disabled={isDeleted}
            inputProps={{ type: 'number', step: '.01' }}
            InputProps={{ inputMode: 'numeric', startAdornment: (<InputAdornment position="start">$</InputAdornment>) }}
          />
        </TableCell>
        <TableCell align="right">
          <TextField name="qty" value={row.qty} autoComplete='off' variant='standard' sx={{ 'input': { textAlign: 'right' } }}
            onChange={neChange('qty')} disabled={isDeleted}
            // defaultValue={0} 
            inputProps={{ type: 'number', step: '.01' }}
            InputProps={{ inputMode: 'numeric' }}
          />
        </TableCell>
        <TableCell align="right">{asDollars((row.qty || 0) * (row.rate || 0))}</TableCell>
        <TableCell align="right" padding='none'><Checkbox name="show_proposal" checked={row.show_proposal == 1} onChange={neChange('show_proposal')} disabled={isDeleted} /></TableCell>
        <TableCell align="right" padding='none'>
          <IconButton name="deleteBtn" size='small' color={row.delete ? 'default' : 'error'} onClick={toggleDeleted}><DeleteIcon /></IconButton>
        </TableCell>
      </TableRow>
    </>);
  }
  else {
    const row = origRow;
    return (<>
      <TableRow key={row.row_id} style={{ position: 'relative' }}>
        <TableCell>{sagecodetotext(row.costcode)}</TableCell>
        <TableCell>{row.name}</TableCell>
        {/* {row.jobcost_type}  */}
        {/* -/- {row.equipmentname} */}
        < TableCell align="right" title={(row.info) ? row.info : undefined} style={{ maxWidth: '200px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{row.info}</TableCell>
        <TableCell align="right">{(row.info_on_proposal) ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankIcon />}</TableCell>
        <TableCell align="right">{row.unit_type_name}</TableCell>
        <TableCell align="right">{asDollars(row.cost)}</TableCell>
        <TableCell align="right">{asDollars(row.rate)}</TableCell>
        <TableCell align="right">{row.qty}</TableCell>
        <TableCell align="right">{asDollars((row.qty || 0) * (row.rate || 0))}</TableCell>
        <TableCell align="right">{(row.show_proposal) ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankIcon />}</TableCell>
        {/* <TableCell align="right">x</TableCell> */}
      </TableRow>
    </>);
  }
}
export default JTItemRow;