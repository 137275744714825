import { TaskData, Options } from './index';
import { useEffect, useMemo, useRef, useState } from 'react';
import { propsToClassKey } from '@mui/styles';
import { Task } from './Task';

const HANDLE_WIDTH = 8;

type TaskFuncProps = {
  // Add callbacks for mouse events, existent of the callback creates and uses feature: resize, progress, etc
  name: string,
  id: string,
  options: Options,
  x: number,
  y: number,
  width: number,
  height: number,
  progress: number | null, // If null, will display as 100%, no handle to resize 
  className?: string,
  onClick?: React.MouseEventHandler<SVGGElement>,
  selected?: Task | null,
  active: boolean,
  taskClass?: string,
};

export function TaskFunc(props: TaskFuncProps) {

  const [textIsBig, setTextIsBig] = useState<boolean>(false);
  const barLabelRef = useRef<SVGTextElement>(null);
  let timer: ReturnType<typeof setTimeout> | null = null;

  useEffect(() => {
    if (barLabelRef.current) {
      if (timer !== null) {
        clearTimeout(timer);
        timer = null;
      }

      timer = setTimeout(() => {
        let label: SVGGraphicsElement | null | undefined = document.getElementById(props.id)?.querySelector('.bar-label');
        if (label) {
          setTextIsBig(label.getBBox().width > props.width);
        }
      }, 100);
    }

  }, [barLabelRef, props.name, props.width]);

  const calcProgressTrianglePoints2 = (): string => {
    const endProgressX = props.x + (props.progress || 0);
    const progressY = props.y;
    const height = props.options.bar_height;

    return [
      endProgressX - 5,
      progressY + height,
      endProgressX + 5,
      progressY + height,
      endProgressX,
      progressY + height - 8.66
    ].toString();
  }

  let barWrapperClassNames = "bar-wrapper";
  if (props.active) barWrapperClassNames += " active";
  if (props.taskClass) barWrapperClassNames += " " + props.taskClass;


  return (
    <g className={barWrapperClassNames} id={props.id} data-mt={'bar:'+props.id}>
      {/* bar_group */}
      <g className="bar-group">
        {/* $bar */}
        <rect x={props.x - 1} y={props.y - 1} width={props.width + 2} height={props.options.bar_height + 2} rx={props.options.bar_corner_radius} ry={props.options.bar_corner_radius} className="bar-active-border"></rect>

        {/* $bar */}
        <rect x={props.x} y={props.y} width={props.width} height={props.options.bar_height} rx={props.options.bar_corner_radius} ry={props.options.bar_corner_radius} className="bar grab"></rect>
        {/* $bar_progress */}
        <rect x={props.x} y={props.y} width={props.progress || props.width} height={props.options.bar_height} rx={props.options.bar_corner_radius} ry={props.options.bar_corner_radius} className="bar-progress grab"></rect>
        <text ref={barLabelRef} x={(textIsBig) ? (props.x + props.width + 5) : (props.x + props.width / 2)} y={props.y + props.height / 2} className={(textIsBig) ? "bar-label big" : "bar-label grab"}>{props.name}</text>
      </g>
      <g className="handle-group">
        <rect className="handle grab right" x={props.x + props.width - 9} y={props.y + 1} width={HANDLE_WIDTH} height={props.options.bar_height - 2} rx={props.options.bar_corner_radius} ry={props.options.bar_corner_radius}></rect>
        {/* <rect className="handle left" x={props.x + 1} y={props.y + 1} width={HANDLE_WIDTH} height={props.options.bar_height - 2} rx={props.options.bar_corner_radius} ry={props.options.bar_corner_radius}></rect> */}
        {props.progress && <polygon className="handle grab progress" points={calcProgressTrianglePoints2()}></polygon>}
      </g>
    </g>
  );
}
export default TaskFunc;
