import React, { ChangeEvent, ChangeEventHandler, Component, useEffect, useMemo, useState } from 'react';
import axios from 'axios';

import { Box, Button, Input, InputProps, InputBase, CssBaseline, AppBar, Toolbar, IconButton, Divider, Typography, LinearProgress, StandardTextFieldProps, SelectChangeEvent, InputAdornment, FormHelperText, Link, Stack, Avatar, Chip, ButtonGroup, TableContainer, Menu } from '@mui/material';
import { Container, Grid, Paper, Tab, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';

import { Cancel, Email, EmailRounded, ExpandMore, KeyboardArrowDown, PlusOne, Save } from '@mui/icons-material';

import { Edit as EditIcon } from '@mui/icons-material';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { render } from '@testing-library/react';
import { useLocation, useNavigate, useParams } from 'react-router';
import AutoCustomerField from '../utils/AutoCustomer';

import { IMaskInput, IMaskMixin } from 'react-imask';
import { CUSTOMER, JOB, JC_ROW } from './Types';

import { isEqual } from "@ngard/tiny-isequal";
import { asDollars } from '../utils/Functions';
import Add from '@mui/icons-material/Add';

type PROPOSAL = {
  "ProposalLetterId": number,
  "OrderNo": number,
  "DocPathName": string,
  "DocFileName": string,
  "Paragraph1": string,
  "Paragraph2": string,
  "Paragraph3Id": number,
  "Paragraph3": string,
  "Paragraph4": string,
  "Paragraph5": string,
  "Paragraph6": null,
  "IncludeAcceptance": 0 | 1,
  "IncludeTotal": 0 | 1,
  "SignedBy": number,
  "CreatedBy": number,
  "RequiresReview": number,
  "Accepted": number, // 2 bits, but only boolean / 0 or 1?
  "Reason": ""
  "details"?: any | null,
  "created": string,
  "SignedByImg": string | null,
  "SignedByName": string | null,
  "officeLetterhead": string | null,
}

const EMPTY: PROPOSAL = {
  "ProposalLetterId": -1,
  "OrderNo": -1,
  "DocPathName": "",
  "DocFileName": "",
  "Paragraph1": "",
  "Paragraph2": "",
  "Paragraph3Id": 0,
  "Paragraph3": "",
  "Paragraph4": "",
  "Paragraph5": "",
  "Paragraph6": null,
  "IncludeAcceptance": 0,
  "IncludeTotal": 0,
  "SignedBy": 0,
  "CreatedBy": 0,
  "RequiresReview": 0,
  "Accepted": 0, // 2 bits, but only boolean / 0 or 1?
  "Reason": "",
  "details": null,
  "created": "",
  "SignedByImg": null,
  "SignedByName": null,
  "officeLetterhead": null,
};

type JOB_DETAILS = Pick<JOB, "OrderNo" | "JobName" | "Contact" | "ProposalDate">;
type CUST_DETAILS = Pick<CUSTOMER, "Customer" | "BillingAddress" | "City" | "State" | "Zip" | "PhNumber" | "Fax" | "Mobile" | "Email">;
type ROW_DETAILS = Pick<JC_ROW, "row_id" | "name" | "unit_type_abbr" | "unit_type_name" | "qty" | "cost" | "rate" | "show_proposal" | "info" | "info_on_proposal">;

type PRO_DETAILS = {
  version: number,
  job: JOB_DETAILS,
  customer: CUST_DETAILS,
  rows: { [rowID: string]: ROW_DETAILS },
  esttotal: number,
}

function toDetails(job: JOB, customer: CUSTOMER, rows: JC_ROW[], version: number = 1): PRO_DETAILS {
  const jobDetails: JOB_DETAILS = {
    OrderNo: job.OrderNo, JobName: job.JobName, Contact: job.Contact, ProposalDate: job.ProposalDate
  };

  const custDetails: CUST_DETAILS = {
    Customer: customer.Customer,
    BillingAddress: customer.BillingAddress, City: customer.City, State: customer.State, Zip: customer.Zip,
    PhNumber: customer.PhNumber, Fax: customer.Fax, Mobile: customer.Mobile, Email: customer.Email
  };

  const filteredRows = rows.filter((r) => r.jobcost_type === "equip" || r.jobcost_type === "unique")
  let rowsMap: { [rowID: string]: ROW_DETAILS } = {};
  for (let r of filteredRows) {
    rowsMap[r.row_id] = {
      row_id: r.row_id, name: r.name, unit_type_abbr: r.unit_type_abbr, unit_type_name: r.unit_type_name,
      qty: r.qty, cost: r.cost, rate: r.rate,
      show_proposal: r.show_proposal, info: r.info, info_on_proposal: r.info_on_proposal,
    };
  }

  const esttotal = filteredRows.reduce((prev, curr) => prev + (curr.rate != null ? curr.qty * curr.rate : 0), 0);
  console.log("Rows: ", filteredRows, esttotal);

  return {
    version: version,
    job: jobDetails,
    customer: custDetails,
    rows: rowsMap,
    esttotal: esttotal,
  }
}

const PAYMENT_TERMS = [
  ["", ""],
  ["#1 Net 30", "This proposal may be withdrawn if not accepted within 30 days.  Payment terms net 30 days. \n(If we encounter an Insurance compliance fee requirement, this fee will be invoiced in addition to the above rates.)"],
  ["#2 50% Deposit", "This proposal may be withdrawn if not accepted within 30 days.  Payment terms are 50%  deposit upon acceptance and 50% balance 30 days after completion.\n(If we encounter an Insurance compliance fee requirement, this fee will be invoiced in addition to the above rates.)"],
  ["#3 25% Deposit", "This proposal may be withdrawn if not accepted within 30 days.  Payment terms are 25%  deposit upon acceptance and 75% balance 30 days after completion.\n(If we encounter an Insurance compliance fee requirement, this fee will be invoiced in addition to the above rates.)"],
  ["#4 COD or CC advance payment", "This proposal may be withdrawn if not accepted within 30 days.  Payment terms are COD (cash delivery) or Credit Card payment prior to job scheduling.\n(If we encounter an Insurance compliance fee requirement, this fee will be invoiced in addition to the above rates.)"],
  ["#5 Custom", "This proposal may be withdrawn if not accepted within 30 days.  Payment terms are _______________.\n(If we encounter an Insurance compliance fee requirement, this fee will be invoiced in addition to the above rates.)"],
];

function JobTixProposal(props: { id: number, job: JOB, customer: CUSTOMER }) {
  const [isBusy, setBusy] = useState(false);
  const [allProposals, setAllProposals] = useState<PROPOSAL[]>([]);
  const [rowItems, setItems] = useState<JC_ROW[]>([]);

  const [selectedProposalID, setSelectedIDProposal] = useState<string>("-1");
  const [selectedProposal, setSelectedProposal] = useState<PROPOSAL | undefined>(undefined);

  const [editProposal, setEditProposal] = useState<PROPOSAL | null>(null);
  const [reloadCounter, setReloadCounter] = useState(0);

  const currentProDetails: PRO_DETAILS = useMemo(() => toDetails(props.job, props.customer, rowItems), [props.job, props.customer, rowItems]);


  // Paragraph 4 menu stuff
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setBusy(true);
    axios.get('/api/json.php', { params: { a: 'get', t: 'proposalletters', jobid: props.id } })
      .then((resp) => resp.data as { proposalletters: PROPOSAL[] })
      .then((json) => {
        setAllProposals(json.proposalletters);
        return json.proposalletters;
      })
      .catch((error) => {
        console.error("ERROR in looking up proposals: ", error);
      }).finally(() => {
        setBusy(false);
      })
  }, [props.id, reloadCounter]);


  useEffect(() => {
    setBusy(true);
    axios.get('/api/json.php', { params: { a: 'get', t: 'jobcostrows', jobid: props.id } })
      .then((resp) => resp.data as { "jc_rows": JC_ROW[] })
      .then((json) => {
        setItems(json.jc_rows);
        return json.jc_rows;
      })
      .catch((error) => {
        console.error("ERROR in looking up jobcost rows: ", error);
      }).finally(() => {
        setBusy(false);
      })
  }, [props.id]);


  const onEditProposal = () => {
    let firstOfAllProposals = (allProposals.length > 0) ? allProposals[0] : undefined;

    let editProposal = Object.assign({}, selectedProposal, firstOfAllProposals, { ProposalLetterId: -1 });
    editProposal.details = Object.assign({}, currentProDetails);
    setEditProposal(editProposal);
  }

  let onChangeStrGen = (name: keyof PROPOSAL) => {
    return (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (editProposal) {
        let v = editProposal[name];
        // @ts-ignore
        editProposal[name] = event.target.value;
        // console.log("old", v, "new", event.target.value);
        setEditProposal(Object.assign({}, editProposal));
      }
    }
  };

  let onSave = () => {

  }

  //onChange: this.handleFormChange, 
  const textAProps: StandardTextFieldProps = { fullWidth: true, variant: "standard", multiline: true };

  return (
    <Grid container spacing={2}
    >
      {isBusy && <Grid item xs={12}><LinearProgress /></Grid>}

      {!editProposal && (<>
        <Grid item xs={12}><Typography variant="overline">Proposals</Typography></Grid>

        <Grid item xs={12}>
          <Grid container>

            <Grid item xs={6}>
              Proposal: &nbsp;
              <Select variant="standard"
                value={selectedProposalID}
                label="Proposals"
                onChange={(event: SelectChangeEvent<string>) => {
                  setSelectedIDProposal(event.target.value + "");
                  setSelectedProposal(allProposals.find((p) => "" + p.ProposalLetterId == event.target.value))
                }}
              >
                <MenuItem key={"-1"} value={"-1"}>Preview Current</MenuItem>
                {allProposals.map((proposal) =>
                  <MenuItem key={proposal.ProposalLetterId} value={proposal.ProposalLetterId}>
                    {proposal.details && <>Past Version: {proposal.created}</>}
                    {!proposal.details && <>Past PDF Version: {proposal.DocFileName}</>}
                  </MenuItem>
                )}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <Button variant="outlined" startIcon={<EditIcon />} onClick={onEditProposal} style={{ float: 'right' }}>
                New Version
              </Button>
            </Grid>

            <Grid item xs={12}>
              {/* {proposals.map((pro: PROPOSAL) => <ProposalOutput key={pro.ProposalLetterId} pro={pro} details={currentProDetails} />)} */}
              {selectedProposalID === "-1" && selectedProposal == undefined && (allProposals.length > 0) && <ProposalOutput key={"-1"} pro={allProposals[0]} details={currentProDetails} />}
              {selectedProposalID !== "-1" && selectedProposal && (
                (selectedProposal.details)
                  ? <ProposalOutput key={selectedProposal.ProposalLetterId} pro={selectedProposal} details={selectedProposal.details} />
                  : <iframe width='100%' height='800' src={"https://jobmgr.shenandoahconstruction.com/upload/proposals/" + selectedProposal.DocFileName}></iframe>
              )}
            </Grid>

          </Grid>
        </Grid>
      </>)}

      {/* Edit Mode */}
      {editProposal && (<>
        <Grid item xs={6}><Typography variant="h5">Edit Proposal</Typography></Grid>
        <Grid item xs={6}>
          <Button variant="outlined" startIcon={<Cancel />} style={{ float: 'right', marginLeft: 5 }} onClick={() => setEditProposal(null)}>Cancel</Button>
          <Button variant="contained" startIcon={<Save />} style={{ float: 'right' }}>Save</Button>
        </Grid>

        <Grid item xl={5} lg={8} xs={12}>
          <Grid container spacing={2} sx={{ alignItems: "center", justifyContent: "center" }}>

            <Grid item xs={12}><TextField name="Paragraph1" onChange={onChangeStrGen('Paragraph1')} label="Job Description" value={editProposal.Paragraph1} {...textAProps} /></Grid>
            <Grid item xs={12}><TextField name="Paragraph2" onChange={onChangeStrGen('Paragraph2')} label="Job Details" value={editProposal.Paragraph2} {...textAProps} /></Grid>
            <Grid item xs={12}>
              <TextField name="Paragraph3" onChange={onChangeStrGen('Paragraph3')} label="Disclaimer" value={editProposal.Paragraph3} {...textAProps} />
            </Grid>
            <Grid item xs={12}>
              <Button
                aria-haspopup="true" onClick={handleClick}
                variant={editProposal.Paragraph4 == "" ? "contained" : "outlined"}
                startIcon={<Add />} endIcon={<KeyboardArrowDown />} style={{ float: 'right' }}
              >
                Set Terms
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {PAYMENT_TERMS.map((entry) =>
                  <MenuItem key={entry[1]} onClick={() => { editProposal.Paragraph4 = entry[1]; setEditProposal(Object.assign({}, editProposal)); handleClose() }}>{entry[0]}</MenuItem>
                )}
              </Menu>
              <TextField name="Paragraph4" onChange={onChangeStrGen('Paragraph4')} label="Payment Terms" contentEditable={false} InputLabelProps={{ shrink: (editProposal.Paragraph4 != "") }} value={editProposal.Paragraph4} {...textAProps} />
            </Grid>
            <Grid item xs={12}><TextField name="Paragraph5" onChange={onChangeStrGen('Paragraph5')} label="Notes" value={editProposal.Paragraph5} {...textAProps} /></Grid>

          </Grid>
        </Grid>

        <Grid item xl={7} xs={12}>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}>
            <Grid item lg={6} md={8} xs={12}>
              <ProposalOutput key="edit" pro={editProposal} details={editProposal.details} />
            </Grid>
          </Grid>
        </Grid>

      </>)
      }


    </Grid >
  );
}
export default JobTixProposal;

function ProposalOutput(props: { pro: PROPOSAL, details: PRO_DETAILS }) {
  // , job: JOB, customer: CUSTOMER,  rowItems: JC_ROW[]
  let pro = props.pro;
  if (!pro)
    pro = Object.assign({}, EMPTY, { "OrderNo": props.details.job.OrderNo });

  console.log("ProposalOutput", pro.Paragraph1);

  const { job, customer } = props.details;
  const rowItems = props.details.rows;

  const outputText = (input: string) => {
    return <div style={{ paddingLeft: 40 }} dangerouslySetInnerHTML={{ __html: input.replaceAll('\n', '<br/>') }} />;
  }

  return <>
    <div style={{ width: '100%' }}>
      <TableContainer component={Paper} key={pro.ProposalLetterId} sx={{ minWidth: 650, maxWidth: 1240, marginLeft: 'auto', marginRight: 'auto' }} >
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {/* <TableCell>Key</TableCell>
            <TableCell>Value</TableCell> */}
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell colSpan={2}>
                <img
                  src={"http://jobmgrdev.shenandoahconstruction.com/upload/offices/" + (pro.officeLetterhead || "Header-South.jpg")}
                  style={{
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                />
                <table cellSpacing="2" cellPadding="2" width="100%">
                  <tbody>
                    <tr>
                      <td>
                        <table width="100%">
                          <tbody>
                            <tr>
                              <td width="20%">&nbsp;</td>
                              <td width="60%" align="left">
                                <table width="100%">
                                  <tbody>
                                    <tr><td align="right" width="50%">DATE:</td><td width="50%"> {job.ProposalDate}</td></tr>
                                    <tr><td align="right">SUBMITTED TO:</td><td> {customer.Customer}</td></tr>
                                    <tr><td align="right">STREET:</td><td> {customer.BillingAddress}</td></tr>
                                    <tr><td align="right">CITY, STATE &amp; ZIP:</td><td> {customer.City}, {customer.State} {customer.Zip}</td></tr>
                                    <tr><td align="right">PHONE:</td><td> {customer.PhNumber}</td></tr>
                                    <tr><td align="right">FAX:</td><td> {customer.Fax}</td></tr>
                                    <tr><td align="right">EMAIL:</td><td> {customer.Email}</td></tr>
                                    <tr><td align="right">JOB NAME:</td><td> {job.JobName}</td></tr>
                                    <tr><td align="right">ATTENTION:</td><td> {job.Contact} </td></tr>
                                  </tbody>
                                </table>
                              </td>
                              <td width="20%" align="right" valign="top">PROPOSAL P{job.OrderNo}-{pro.ProposalLetterId}</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    {!pro.Paragraph1 ? null : <tr><td>{outputText(pro.Paragraph1)}</td></tr>}
                    <tr>
                      <td>
                        <table width="100%">
                          <tbody>
                            {rowItems && Object.values(rowItems).filter((item) => item.show_proposal).map((item: ROW_DETAILS) => {
                              return (
                                <tr key={item.row_id}>
                                  <td width="10%"></td>
                                  <td width="40%">{item.name}</td>
                                  <td width="25%">(at {asDollars(item.rate)} {item.unit_type_name})</td>
                                  <td width="15%">{item.qty} {item.unit_type_abbr}</td>
                                  <td width="10%" align="right">${(item.rate || 0) * item.qty}</td>
                                </tr>
                              );
                            })}
                            <tr>
                              <td colSpan={3}><b>Estimated Total:</b></td>
                              <td align="right" colSpan={2}><b>{asDollars(props.details.esttotal)}</b></td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    {pro.Paragraph2 ? <tr><td>{outputText(pro.Paragraph2)}</td></tr> : null}
                    {pro.Paragraph3 ? <tr><td>{outputText(pro.Paragraph3)}</td></tr> : null}
                    {pro.Paragraph4 ? <tr><td>{outputText(pro.Paragraph4)}</td></tr> : null}
                    {pro.Paragraph5 ? <tr><td>{outputText(pro.Paragraph5)}</td></tr> : null}
                  </tbody>
                </table>
                <table cellSpacing="2" cellPadding="2">
                  <tbody>
                    <tr>
                      <td>SIGNATURE: {pro.SignedByImg && <img src={"http://jobmgrdev.shenandoahconstruction.com/upload/signature/" + pro.SignedByImg} width="110" />}</td>
                    </tr>
                    <tr>
                      <td>
                        <table>
                          <tbody>
                            <tr>
                              <td width="60%">SHENANDOAH GENERAL CONSTRUCTION CO.</td>
                              <td width="20%">TITLE</td>
                              <td width="20%">DATE</td>
                            </tr>
                            <tr>
                              <td>{pro.SignedByName}</td>
                              <td>Estimator</td>
                              <td>{(pro.SignedBy > 0) ? job.ProposalDate : ""}</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td align="center">ACCEPTANCE OF PROPOSAL / SIGN &amp; RETURN</td>
                    </tr>
                    <tr>
                      <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The above prices, specification and conditions are satisfactory and are hereby accepted.  You are authorized to do the work as specified.</td>
                    </tr>
                    <tr>
                      <td>SIGNATURE: ____________________________________________________________________</td>
                    </tr>
                    <tr>
                      <td>
                        <table>
                          <tbody>
                            <tr>
                              <td width="15%">&nbsp;</td>
                              <td width="45%">COMPANY NAME:</td>
                              <td width="40%">DATE:</td>
                            </tr>
                            <tr>
                              <td width="15%">&nbsp;</td>
                              <td width="45%">REPRESENTATIVE:</td>
                              <td width="40%">TITLE:</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <br />
    </div>
  </>
};