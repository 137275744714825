import TaskChart from "./ChartTasks";

export type ViewModeType = 'Quarter Day' | 'Half Day' | 'Day' | 'Week' | 'Month' | 'Year';

export interface Options {
  header_height: number;
  column_width: number;
  step: number;
  bar_height: number;
  bar_corner_radius: number;
  arrow_curve: number;
  padding: number;
  view_mode: ViewModeType;
  popup_trigger: keyof HTMLElementEventMap;
}

export interface TaskData {
  id: string;
  name: string;
  start: Date;
  end: Date;
  progress?: number;
  dependencies?: string[];
  groupID?: string;
  class?: string;
}

export interface GroupData {
  id: string;
  name: string;
}

export default TaskChart;