import React, { useState, useEffect, useMemo, ChangeEvent, useRef } from 'react';
import * as api from '../jobtix/apiCalls';
import axios from 'axios';

import * as rcsv from 'react-csv';

import JobMgrHeader from '../utils/JobMgrHeader';
import { Link as RouterLink } from "react-router-dom";


import { Container } from '@mui/system';
import { LinearProgress, Typography, Link, Box, Checkbox, FormGroup, FormControlLabel, Button, Alert, AlertTitle, debounce, Select, MenuItem, InputLabel, FormControl, Tabs, Tab, Grid } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { z } from 'zod';
import { AsyncClickHandler } from 'react-csv/components/CommonPropTypes';
import ComponentLienActionBtn, { STATUS_TO_TEXT2 } from '../utils/ComponentLienActionBtn';
import { toMMDDYYY } from '../utils/Functions';
/** Used to detect multi-requests at the same time, use the last one */
let currentProcessCount = 0;

//le.orderno, le.start, le.status, le.exported, le.export, jt.JobName, cust.CustomerNo, cust.Customer, cust.nolien

export const LIEN_DATA_ZOD = z.object({
  "orderno": z.number(),
  "start": z.coerce.date(),
  "status": z.number(),
  "exported": z.coerce.date().nullable(),
  "export": z.string().nullable(),
  "JobName": z.string(),
  "CustomerNo": z.number(),
  "Customer": z.string(),
  "nolien": z.number(),
  "CustAddress": z.string(),
  "CustCity": z.string(),
  "CustState": z.string(),
  "CustZip": z.string(),
  "CustPhNumber": z.string(),
  "JobAddress": z.string(),
  "JobCity": z.string(),
  "JobState": z.string(),
  "JobZip": z.string(),
  "Estimator": z.string(),
});
export type LIEN_DATA = z.infer<typeof LIEN_DATA_ZOD>;

export default function LienExportEdit() {
  // Results from query data coming in & display data
  const [error, setError] = useState<string | null>();
  const [nonBlockingError, setNonBlockingError] = useState<string | null>();
  const [isBusy, setBusy] = React.useState(false);

  const [filterStatus, setFilterStatus] = useState<number>(1);

  // const [newJobsAdded, setNewJobsAdded] = useState(0);

  const [data, setData] = useState<LIEN_DATA[] | null>(null);
  const valuesMap = useRef(new Map<number, number>());

  const [rerender, setRerender] = React.useState(0);

  const debounceRerender = debounce(() => setRerender((i) => i + 1), 500);

  useEffect(() => {
    window.onbeforeunload = function () {
      if (valuesMap.current.size > 0)
        return "Unsaved changes may be lost.";
      else
        return null;
    }
    return () => { window.onbeforeunload = null; }
  }, [])

  useEffect(() => {
    axios.get('/api/lienreport.php', { params: { a: 'loadnext' } })
      .catch(api.catchUnauthorized)
      .catch((error) => { setError(JSON.stringify(error)) })
      .then((resp) => resp.data)//as LIST_RESP<DATA>
      .then((json) => {
        console.log("newRowCount() complete:", json);

        let newRowCount = json['newrows'];
        // setNewJobsAdded(newRowCount);

        if (newRowCount > 0) {
          performQuery();
        }
      })
      .finally(() => {
        setBusy(false);
      });
  }, [])

  const performQuery = () => {
    currentProcessCount++;
    let thisPCount = currentProcessCount;

    // console.log("jsonFetcher() start:", params);
    setBusy(true);
    axios.get('/api/lienreport.php', { params: { a: 'get', t: 'report', status: filterStatus } })
      .catch(api.catchUnauthorized)
      .catch((error) => { setError(JSON.stringify(error)) })
      .then((resp) => resp.data)//as LIST_RESP<DATA>
      .then((json) => {
        // console.log("jsonFetcher() complete:", params);

        // If another fetch is already in progress, abort this one
        if (currentProcessCount != thisPCount) {
          console.log('ABORTED');
          return;
        }

        let rawData = json.data.map((d: any) => { return LIEN_DATA_ZOD.parse(d) });

        setData(rawData);
        // setCount(json.totalsize.count);
      })
      .finally(() => {
        setBusy(false);
      });
  };

  useEffect(() => {
    performQuery();
  }, [filterStatus])

  const onSave = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setBusy(true);
    console.log("onSave - values map: ", valuesMap.current);
    axios.post('/api/lienreport.php', { a: 'save', data: Object.fromEntries(valuesMap.current.entries()) }, { params: { a: 'save' } })
      .catch(api.catchUnauthorized)
      .catch((error) => { setError(JSON.stringify(error)) })
      .then((resp) => resp.data)//as LIST_RESP<DATA>
      .then((json) => {
        console.log("onSave - resp:", json);
        valuesMap.current.clear();
      })
      .finally(() => {
        setBusy(false);
        performQuery();
      });
  }

  return (<>
    <JobMgrHeader>
      <Typography variant="h6" component="h1">
        Lien Export Tool - Edit
      </Typography>
    </JobMgrHeader>

    <Container>
      {nonBlockingError &&
        <Alert severity="error" style={{ marginBottom: '8px' }}>
          <AlertTitle>Export Error</AlertTitle>
          {nonBlockingError}
        </Alert>
      }
      {error && <Typography>ERROR: {error}</Typography>}
      {isBusy && <Typography>Loading... <LinearProgress /></Typography>}



      <Grid container direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        style={{ paddingBottom: '8px' }}
      >
        <Grid item >
          <Tabs value={0} onChange={() => { }} >
            <Tab
              component={RouterLink}
              label="Edit" to="/app/tools/lien"
            />
            <Tab
              component={RouterLink}
              label="Export" to="/app/tools/lien/preview"
              disabled={valuesMap.current.size > 0}
            />
          </Tabs>
        </Grid>
        <Grid>
          {/* <FormControl style={{ minWidth: '150px', marginRight: 1 }}>
                <InputLabel variant="standard">
                  Filter by Status
                </InputLabel> */}
          <Typography variant="caption">Filter by Status: &nbsp;</Typography>
          <Select value={filterStatus}
            label="Filter by Status"
            variant="standard"
            onChange={(event) => { let asNum = (typeof event.target.value === 'string') ? Number.parseInt(event.target.value) : event.target.value; setFilterStatus(asNum); }}>
            {STATUS_TO_TEXT2.map((t, idx) =>
              <MenuItem key={idx} value={idx}>{t}</MenuItem>
            )}
          </Select>
          {/* </FormControl> */}
          &nbsp;
          <Button variant='contained' disabled={valuesMap.current.size === 0} onClick={onSave}>
            Save changes
          </Button>


        </Grid>
      </Grid>

      {!error && data && <>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>Job#</TableCell>
                <TableCell>Job Name</TableCell>
                <TableCell sx={{ textWrap: 'nowrap' }}> Job Start</TableCell>
                <TableCell>Customer</TableCell>
                <TableCell>Estimator</TableCell>
                <TableCell>NoNotice</TableCell>

                {filterStatus === 3 &&
                  <TableCell>Exported</TableCell>
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => {
                let newStatus = valuesMap.current.get(row.orderno);

                return (
                  <TableRow
                    key={row.orderno}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >

                    <TableCell>
                      <ComponentLienActionBtn value={(newStatus !== undefined) ? newStatus : row.status} original={row.status}
                        onChange={(newValue) => {
                          if (newValue === row.status) {
                            valuesMap.current.delete(row.orderno);
                          } else {
                            valuesMap.current.set(row.orderno, newValue);
                          }
                          debounceRerender();
                          console.log("valuesMap.current", valuesMap.current);
                          // xxx setRerender((i) => i + 1);
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <a target="_blank" href={"/job.php?id=" + row.orderno} style={{ color: "#1976d2" }}> {row.orderno}</a>
                    </TableCell>
                    <TableCell><Box style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{row.JobName}</Box></TableCell>
                    <TableCell sx={{ textWrap: 'nowrap' }}>{(row.start.valueOf() > 0) ? toMMDDYYY(row.start) : "N/A"} </TableCell>
                    <TableCell><Box style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                      <a target="_blank" href={"/customer.php?id=" + row.CustomerNo} style={{ color: "#1976d2" }}> {row.Customer}</a>
                    </Box></TableCell>
                    <TableCell sx={{ textWrap: 'nowrap' }}>{row.Estimator}</TableCell>
                    <TableCell>{row.nolien ? "true" : "false"}</TableCell>
                    {filterStatus === 3 &&
                      <TableCell>{row.exported ? toMMDDYYY(row.exported) : "N/A"}</TableCell>
                    }

                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </>
      }
    </Container>
  </>);
}
