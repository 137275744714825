
import TaskChart, { GroupData, TaskData } from "../utils/charttasks"

import { Box, Button, Slide, Typography, AppBar, Toolbar, IconButton, Paper, Alert, AlertTitle, Snackbar, ButtonGroup, Divider, Grid, InputAdornment, Badge, useScrollTrigger, ListItemIcon, ListItemText, Drawer } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Group } from "../utils/charttasks/ChartTasks";
import { Task } from "../utils/charttasks/Task";
import { useEffect, useState } from "react";

import styles from './JobBoard.module.css';


export default function JobBoard() {
  const virtTrigger = useScrollTrigger({ threshold: 0 });

  const [taskData, setTaskData] = useState([]);
  const [allData, setAllData] = useState<any>({});

  const [bottomDrawer, setBottomDrawer] = useState(true);

  useEffect(() => { pullFromAPI() }, []);

  const pullFromAPI = async () => {
    // console.log("pullFromAPI", moment(this.state.queryTimeStart).format("YYYY-MM-DD"), moment(this.state.queryTimeEnd).format("YYYY-MM-DD"));

    let API_URL = "https://jobmgrdev.shenandoahconstruction.com/jobboard-ajax-react.php?" + "s=2022-02-01" + "&e=2022-06-01";
    await fetch(API_URL, { mode: "cors", credentials: "same-origin" }) // Call the fetch function passing the url of the API as a parameter
      .then((resp) => {
        return resp.json();
      })
      .then((json) => {
        setAllData({
          srcgroups: json.reactGroups,
          srcitems: json.reactItems,
          srceq: json.reactEquipment,
        });
        console.log("DATA: ", {
          srcgroups: json.reactGroups,
          srcitems: json.reactItems,
          srceq: json.reactEquipment,
        });
        // this.processData();
      })
      .catch(function (error) {
        // This is where you run code if the server returns any errors
        console.log("pullFromAPI.Fetch Error:", error);
      });
  };

  // const pullJobData = async (jobid) => {
  //   console.log("pullJobData", jobid, this.state.jobid);
  //   if (jobid && this.state.content.jobid !== jobid) {
  //     let API_URL =
  //       process.env.REACT_APP_AJAX_URL + "jobboard-ajax-react.php?id=" + jobid;

  //     // Call the fetch function passing the url of the API as a parameter
  //     await fetch(API_URL, { mode: "cors", credentials: "same-origin" })
  //       .then((resp) => {
  //         return resp.json();
  //       })
  //       .then((json) => {
  //         console.log("pullJobData - setting content", json);
  //         this.setState({
  //           jobname: json.job.OrderNo + " - " + json.job.JobName,
  //           jobid: json.job.OrderNo,
  //           content: json,
  //         });
  //       })
  //       .catch((error) => {
  //         this.setState({
  //           jobname: "ERROR OPENING JOB " + jobid,
  //           open: false,
  //           content: { job: {}, schedule: [] },
  //         });
  //         // this.setState({  });
  //       });
  //   }
  // };


  let jobID = "12345";

  let GroupData: GroupData[] = [
    { id: "row1", name: "Row #1" },
  ];
  let TaskData: TaskData[] = [
    { id: "t1", name: "Task #1", start: new Date("2022-04-13"), end: new Date("2022-04-15"), groupID: "row1" },
  ];

  return <>
    <Box sx={{ flexGrow: 1 }}>
      <Slide appear={false} direction="down" in={!virtTrigger}>
        <div>
          <AppBar elevation={virtTrigger ? 8 : 0}>
            <Toolbar sx={{ whiteSpace: 'nowrap' }}>
              <IconButton
                size="small"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                href={"/job.php?id=" + jobID}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h6" component="h1">JobCost #{jobID} &nbsp; {virtTrigger}</Typography>
              <Typography variant="subtitle2" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }} overflow="hidden" textOverflow="ellipsis">&nbsp; {jobID}</Typography>
              <Box sx={{ flexGrow: 1 }}></Box>

              <Button variant="contained" color="warning">
                Placeholder
              </Button>

            </Toolbar>
          </AppBar>
          <Toolbar />
        </div>
      </Slide>
    </Box>
    <TaskChart title="TEST" groups={GroupData} tasks={TaskData} options={{}} />
    <div className={styles.bottomDrawerContainer} onClick={() => { setBottomDrawer(true) }}>
      <div style={{ borderTop: "1px grey solid" }}>
        HEre to open
        <image href={process.env.PUBLIC_URL + "/horz3dots.png"} width={20} height={6} />
        <div style={{position:'absolute', top:-4, left:"50%", textAlign: "center"}}>⬨ ⬨ ⬨ ⬨</div>
      </div>
    </div>
    <Drawer
      anchor="bottom"
      open={bottomDrawer}
      onClose={() => { setBottomDrawer(false) }}
    >
      drawer
    </Drawer>
  </>;
}