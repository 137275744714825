import { useCallback, useState } from "react";
import { Input, InputProps } from "@mui/material";
import { COL, DATA, ROW } from '../jobtix/Types';
import { handleResponseToJson } from "./JobCost";

const isNumRegex = new RegExp('^[0-9]*\\.?[0-9]*$');

export const RightAlignInput = //styled(
  (props: InputProps & { tab: number, col: COL, row: ROW, data: DATA, uid: number, uname: string, onDataChange: (d: DATA) => void }) => {
    const [value, setValue] = useState<string>("" + props.data.qty);
    const [isBusy, setBusy] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    // useEffect(()=>{
    //   console.log("RAI+");
    //   return ()=>{console.log("RAI-")};
    // },[])

    let forceWidth = (value != null && value.length > 3) ? { width: (value.length / 2 + 1) * 1.1 + "em" } : { width: 'unset' };
    // console.log("forceWidth: ", value, forceWidth)
    const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      // console.log("Blur", e);
      setError(false);
      let numValue = Number.parseFloat(e.target.value);

      if (Number.isNaN(numValue) == false && props.data.qty != numValue) {
        console.log("SUBMITTING CHANGE:", numValue, 'Old:', props.data.qty);
        const formPostData = {
          target: 'data',
          action: 'qty',
          order_id: props.col.order_id,
          col_id: props.data.col_id,
          row_id: props.data.row_id,
          prev_qty: props.data.qty,
          new_qty: numValue,
        }

        setBusy(true);
        fetch('/api/json_jobcost_api.php', { method: "POST", body: JSON.stringify(formPostData) })
          .then(handleResponseToJson)
          .then((json) => {
            console.log('/api/json_jobcost_api.php RSP:', json);
            props.onDataChange({ ...props.data, qty: numValue });
          })
          .catch((error) => {
            setError(true)
          }).finally(() => {
            setBusy(false);
          });
      }
    }

    const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
      let v = e.target.value;
      if (v == '') {
        setValue('0');
      } else if (isNumRegex.test(v)) {
        if (v.length == 2 && v[0] == '0' && v[1] != '.')
          setValue(v.substring(1))
        else
          setValue(v);
      }
    }, [])

    let size = Math.max(4, (value.length || 0) - 2);
    let classes = 'rawrightinput';
    if (error)
      classes += ' error';

    return (
      <div style={forceWidth}>
        <input className={classes}
          value={value} size={size}
          tabIndex={props.tab} //inputMode='numeric' pattern='[0-9]+\.?[0-9]*'
          onChange={onChange} onBlur={onBlur} disabled={props.disabled || isBusy}
        />
        {/* <Input size="small" fullWidth
        margin="none"
        value={value}
        tabIndex={props.tab}
        className="rightaligninput"
        onChange={(e) => setValue(e.target.value)}
        onBlur={onBlur}
        error={error || value != null && Number.isNaN(value)}
        disabled={props.disabled || error || isBusy}
        inputProps={{ tabIndex: props.tab, inputMode: 'numeric', pattern: '[0-9]+\.?[0-9]*' }}
      /> */}
      </div>
    )
  }
// )(({ theme }) => ({
//   '& input': {
//     textAlign: 'right',
//   },
//   '& :invalid': {
//     color: 'red',
//   },
// }));
export default RightAlignInput;