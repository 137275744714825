import React from 'react';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import AddIcon from '@mui/icons-material/Add';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PeopleIcon from '@mui/icons-material/People';

import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import { GiWoodBeam as GiWoodBeamIcon } from 'react-icons/gi';
import { FaToolbox as FaToolboxIcon } from 'react-icons/fa';
import { FaPencilRuler as FaPencilRulerIcon } from 'react-icons/fa';

import LinearProgress from '@mui/material/LinearProgress';
import { Box, Typography, IconButton } from '@mui/material';

import RightAlignInput from './RightAlignInput';

import { ROW, COL, DATA, ROW_JOBCOST_TYPES, JOBCOST_BILL_COLS } from '../jobtix/Types';
import { asDollars, dispRound } from '../utils/Functions'
import { ROWPLUS } from './JobCost';
import JobName from '../utils/JobName';



const lightgrey: [string, string] = ["rgba(215,215,215,.85)", "rgba(245,245,245,.85)"];
const lightpurple: [string, string] = ["rgba(170,115,170,.85)", "rgba(190,135,190,.85)"];
const lightblue: [string, string] = ["rgba(143,186,200,.85)", "rgba(193,226,250,.85)"];
const darkergrey: [string, string] = ["rgba(195,195,195,.85)", "rgba(205,205,205,.85)"];
const lightyellow: [string, string] = ["rgba(205,205,115,.85)", "rgba(225,225,145,.85)"];
const lightgreen: [string, string] = ["rgba(85,175,85,.85)", "rgba(105,195,105,.85)"];
const fadedarkref: [string, string] = ["rgba(139,0,0,.5)", "rgba(159,20,20,.5)"];

/** First entry is for darker leading cell, second is for rest of the row */
export const JOBTYPE2COLOR: { [key in ROW_JOBCOST_TYPES]: [string, string] } = {
  "equip": lightgrey,
  "unique": lightgrey,
  "extracrew": lightpurple,
  "extratruck": lightpurple,
  "additional": lightblue,
  "addl_material": lightyellow,
  "addl_subcontractor": lightgreen,
  "addl_rentalequip": darkergrey,
  "addl_interoffset": fadedarkref,

};

export function JobCostRow(props: {
  row: ROWPLUS, rowIdx: number, dataArr: { [colid: string]: DATA }, cols: COL[], jobspecialty: "0" | "1",
  colBillStatus: Map<string, JOBCOST_BILL_COLS>,
  compact: boolean, viewByCount: boolean, viewByCost: boolean, viewByRate: boolean, viewByProfit: boolean, trigger: boolean,
  setOpenRowAddlModal: (r: ROW) => void, setOpenRowTruckModal: (r: ROW) => void, setOpenRowCrewModal: (r: ROW) => void,
  mapData: {
    [rowid: string]: {
      [colid: string]: DATA;
    };
  }, setOpenNoteModal: (d: DATA | null) => void, actionsDisabled: boolean, onDataChange: (d: DATA) => void,
  uid: number | null, uname: string | null,
  noWrap: React.CSSProperties,
  readonly: boolean,
}) {

  const {
    row, rowIdx, dataArr, cols, jobspecialty,
    compact, viewByCount, viewByCost, viewByRate, viewByProfit, trigger,
    setOpenRowAddlModal, setOpenRowTruckModal, setOpenRowCrewModal,
    mapData, setOpenNoteModal, actionsDisabled, onDataChange,
    uid, uname,
    noWrap,
  } = props;

  const styleStrikeThrough: React.CSSProperties = { textDecoration: 'line-through' };
  const noWrapStrike: React.CSSProperties = { ...noWrap, ...styleStrikeThrough };

  let qtyUsed = Object.values(dataArr).reduce((p, c): number => {
    return p + c.qty || 0;
  }, 0);

  let qtyLeft = row.qty - qtyUsed;
  let precent = (qtyUsed / row.qty);
  let precentDisplay = Number.isFinite(precent) ? (precent * 100).toFixed(0) : "???";

  let multiplier: number = ((viewByRate) ? row.rate : (viewByCost) ? row.cost : (viewByProfit) ? ((row.rate || Number.NaN) - (row.cost || Number.NaN)) : 0) || 0;

  let used = (viewByCount) ? (qtyUsed) : (qtyUsed * multiplier);
  let left = (viewByCount) ? (qtyLeft) : (qtyLeft * multiplier);
  let total = (viewByCount) ? (row.qty) : (row.qty * multiplier);

  let typeText: "Qty" | "Rate" | "Cost" | "Profit" = (viewByCount) ? "Qty" : (viewByRate) ? "Rate" : (viewByCost) ? "Cost" : "Profit";
  let isExtraRow = (row.jobcost_type === 'extratruck' || row.jobcost_type === 'extracrew');
  let isAdditionalRow = (row.jobcost_type === 'additional' || row.jobcost_type === 'addl_material' || row.jobcost_type === 'addl_subcontractor' || row.jobcost_type === 'addl_rentalequip' || row.jobcost_type === 'addl_interoffset');
  let isAdditionalPlusRow = isAdditionalRow && (row.jobcost_type !== 'additional');

  let rowOnClickFunc: undefined | ((e: any) => void) = undefined;
  if (isAdditionalRow || isExtraRow) {
    rowOnClickFunc = () => {
      if (props.readonly) { return; }
      else if (row.jobcost_type === 'extratruck') { setOpenRowTruckModal(row); isExtraRow = true; }
      else if (row.jobcost_type === 'extracrew') { setOpenRowCrewModal(row); isExtraRow = true; }
      else if (isAdditionalRow) { setOpenRowAddlModal(row); }
    };
    // rowOnClick['sx'] = { '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.08)', boxShadow: "0px 0px 3px 3px rgba(0, 0, 0, 0.08)" }, cursor: "pointer" };
  }

  let isOddRow = (rowIdx % 2 == 1);
  let isNonStandardRow = (isAdditionalRow || isExtraRow);
  let rowBackgroundColor = (isOddRow)
    ? ((isNonStandardRow) ? "rgba(214,236,243,.85)" : "rgba(255,255,255,.85)") //"rgba(173,216,230,.5)"
    : ((isNonStandardRow) ? "rgba(173,216,230,.85)" : "rgba(245,245,245,.85)"); // "whitesmoke"=rgb(245,245,245)

  let rowBackgroundColorTH = (isOddRow)
    ? ((isNonStandardRow) ? "rgba(164,186,193,.85)" : "rgba(235,235,235,.85)") //"rgba(173,216,230,.5)"
    : ((isNonStandardRow) ? "rgba(123,166,180,.85)" : "rgba(215,215,215,.85)"); // "whitesmoke"=rgb(245,245,245)

  let shouldIndent = Boolean(row.addl_cost_parent)
  /*
   * Construct the left row 'header' area, composed of three possible parts:
   */
  let precentBarTD = null;
  let summOnRightTD = null;
  let textRowTD = null;

  if (!isExtraRow) {
    precentBarTD = (
      <Box width={300} style={{ position: 'relative', top: -2, paddingLeft: shouldIndent ? 20 : 0 }} >
        <LinearProgress variant="determinate" value={Math.min(precent, 1) * 100} color={(Number.isFinite(precent) && precent > 1) ? "error" : "primary"} />
        <Box display="flex" style={{ position: 'absolute', left: 0, right: 0, top: "-0.5rem" }} >
          <Box m="auto" style={{ boxShadow: '0px 0px 5px 5px rgb(255 255 255)', backgroundColor: 'white', opacity: 0.75, textAlign: "center" }} >
            {Number.isFinite(precent) && (
              <>{precentDisplay}% {qtyUsed} / {row.qty}{(row.unit_type_abbr) ? " " + (row.unit_type_abbr) : null}</>
            )}
            {!Number.isFinite(precent) && (
              (row.rate == 0 && row.cost != 0) ? (<>Addl Cost: {asDollars(row.cost)} / unit</>)
                : (row.rate != 0 && row.cost == 0) ? (<>Addl Rate: {asDollars(row.rate)} / unit</>)
                  : (<>{precentDisplay} % {qtyUsed} / {row.qty}{(row.unit_type_abbr) ? " " + (row.unit_type_abbr) : null}</>)
            )}
          </Box>
        </Box>
      </Box>
    );
  }

  if (isExtraRow) {
    summOnRightTD = (
      <tbody>
        <tr><td style={noWrap}>Total: </td><td width={5}></td><td style={noWrap} title={qtyUsed + ' Hrs'}>{qtyUsed} Hrs</td></tr>
      </tbody>
    );
  } else if (isAdditionalPlusRow && row.cost === 1) {
    summOnRightTD = (
      <tbody>
        <tr><td style={noWrap}>Cost: </td><td width={5}></td><td style={noWrap} title={(qtyUsed) ? '' + qtyUsed : undefined}>{asDollars(qtyUsed)}</td></tr>
      </tbody>
    );
  } else {
    summOnRightTD = (
      <tbody>
        {viewByRate && <>
          <tr><td style={noWrap}>Rate:</td><td width={5}></td><td style={noWrap} title={row.rate ? row.rate + '' : ""}>{asDollars(row.rate)}</td></tr>
          <tr><td style={noWrap}>Qty Left:</td><td></td><td style={noWrap} title={qtyLeft ? qtyLeft + '' : ""}>{dispRound(qtyLeft)}</td></tr>
          <tr><td style={noWrap}>Rate Left:</td><td></td><td style={noWrap} title={left ? left + '' : ""}>{asDollars(left)}</td></tr>
        </>}
        {!viewByRate && <>
          <tr>
            <td style={(row.hasSub) ? noWrapStrike : noWrap}>{typeText} Used:</td>
            <td width={5}></td>
            <td style={(row.hasSub) ? noWrapStrike : noWrap} title={(used) ? '' + used : undefined}>{(viewByCount) ? dispRound(used) : asDollars(used)}</td>
          </tr>
          <tr>
            <td style={(row.hasSub) ? noWrapStrike : noWrap}>{typeText} Left:</td>
            <td></td>
            <td style={(row.hasSub) ? noWrapStrike : noWrap} title={(left) ? '' + left : undefined}>{(viewByCount) ? dispRound(left) : asDollars(left)}</td>
          </tr>
          <tr>
            <td style={(row.hasSub) ? noWrapStrike : noWrap}>{typeText} Total:</td>
            <td></td>
            <td style={(row.hasSub) ? noWrapStrike : noWrap} title={(total) ? '' + total : undefined}>{(viewByCount) ? dispRound(total) : asDollars(total)}</td>
          </tr>
        </>}
      </tbody>
    );
  }

  textRowTD = (
    <Box onClick={rowOnClickFunc} className={(rowOnClickFunc) ? "hasOnHover" : ""} width={300} paddingLeft={shouldIndent ? 3 : 0}>
      <Box style={{ float: 'right' }}>
        {row.addl_cost_type == 'addl_material' && <GiWoodBeamIcon fontSize="small" title="Material" style={{ width: 20, height: 20, marginRight: 3 }} />}
        {row.addl_cost_type == 'addl_subcontractor' && <span title="Subcontractor"><PeopleIcon fontSize="small" style={{ marginRight: 3 }} /></span>}
        {row.addl_cost_type == 'addl_rentalequip' && <FaToolboxIcon fontSize="small" title="Rental Equipment" style={{ marginRight: 3 }} />}
        {(row.specialtyitem === '1' || jobspecialty === '1') && <><FaPencilRulerIcon fontSize="15px" title={(jobspecialty === '1') ? "Job is marked Specialty" : "Specialty Job Item"} style={{ marginRight: 3, marginTop: 0 }} /></>}

      </Box>
      {shouldIndent && <ArrowForwardIos style={{ position: 'absolute', left: 0, top: '50%', width: 20 }} />}
      <Typography noWrap textOverflow='ellipsis' fontWeight='bold' paddingLeft={0} title={row.name + ((row.addl_cost_type) ? " (for cost type: " + row.addl_cost_type + ")" : "")}>
        {row.jobcost_type == 'additional' && <AddIcon fontSize="small" />}
        {row.jobcost_type == 'addl_material' && <GiWoodBeamIcon fontSize="small" style={{ width: 20, height: 20, marginRight: 3 }} />}
        {row.jobcost_type == 'addl_subcontractor' && <PeopleIcon fontSize="small" style={{ marginRight: 3 }} />}
        {row.jobcost_type == 'addl_rentalequip' && <FaToolboxIcon fontSize="small" style={{ marginRight: 3 }} />}
        {row.jobcost_type == 'addl_interoffset' && <AssignmentReturnIcon fontSize="small" style={{ marginRight: 3 }} />}

        {row.jobcost_type === 'extratruck' && <LocalShippingIcon fontSize="small" style={{ marginRight: 3 }} />}
        {row.jobcost_type === 'extracrew' && <PeopleIcon fontSize="small" style={{ marginRight: 3 }} />}

        {row.jobcost_type == 'addl_interoffset' && <span>Job #<a target="_blank" href={"/job.php?id=" + row.name} onClick={e => e.stopPropagation()}>{row.name}</a><JobName id={row.name} prefix=": " /></span>}
        {row.jobcost_type != 'addl_interoffset' && <span>{row.name}</span>}
      </Typography>

      {!isExtraRow && !isAdditionalPlusRow && (
        <Typography noWrap textOverflow='ellipsis' fontStyle='italic' fontSize='small' paddingLeft={1}>
          Rate: {asDollars(row.rate)} /
          {row.hasSub && <span style={styleStrikeThrough}>Cost: {asDollars(row.cost)}</span>}
          {!row.hasSub && <span style={{}}>Cost: {asDollars(row.cost)}</span>}

          {row.unit_type && <>/ {row.unit_type}</>}
        </Typography>
      )}

    </Box>
  );
  // textRowTD = (
  //   <Box onClick={rowOnClickFunc} className={(rowOnClickFunc) ? "hasOnHover" : ""} fontWeight='bold' width={300} style={{ minWidth: 300, maxWidth: 300 }} title={row.name}>
  //     <Typography noWrap textOverflow='ellipsis' fontWeight='bold' paddingLeft={0} title={row.name + " [" + row.jobcost_type + "]"}>
  //       <span style={{ paddingLeft: '4px', fontWeight: 'bold' }}>{row.name}</span>
  //     </Typography>
  //   </Box>
  // );

  // If precentBar exists for this row, place first.
  let firstRowTD = (precentBarTD) ? precentBarTD : textRowTD;
  let secondRowTD = (precentBarTD) ? textRowTD : null;

  return (
    <TableRow
      key={row.row_id}
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
        // '&:nth-of-type(odd)': { backgroundColor: (row.jobcost_type == 'additional' || isExtraRow) ? "rgba(173,216,230,0)" : "whitesmoke" },
        backgroundColor: JOBTYPE2COLOR[row.jobcost_type][1] //rowBackgroundColor
      }}
    >
      <TableCell component="th" scope="row" style={{ position: 'sticky', left: '0', height: 60, zIndex: 1, backgroundColor: JOBTYPE2COLOR[row.jobcost_type][0] }}
        sx={{ overflow: "clip", boxShadow: (!trigger) ? "" : "7px 0 9px -7px rgb(0 0 0 / 40%)", maxWidth: 300, paddingTop: 0, paddingBottom: 0 }}>
        <table cellSpacing={0} width={475} style={{ width: 475 }}>
          <tbody>
            <tr>
              <td width={300} colSpan={3} height={20}>
                {firstRowTD}
              </td>
              <td width={2}></td>
              <td rowSpan={2} width="100%" height="0px" style={{ borderLeft: "1px solid darkgrey" }}>
                <Box>
                  <table cellPadding={0} cellSpacing={0} style={{ paddingLeft: 5 }}>
                    {summOnRightTD}
                  </table>
                </Box>
              </td>
            </tr>
            {secondRowTD && (
              <tr>
                <td valign="top" width={300} colSpan={3}>
                  {secondRowTD}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </TableCell>
      {
        cols.map((c, idx) => {
          // let found = data.find((d) => (c.col_id == d.col_id && row.row_id == d.row_id));
          let found = (mapData[row.row_id]) ? mapData[row.row_id][c.col_id] : null;

          if (!found)
            return <TableCell key={c.col_id} width={85} align="right" color="red">ERROR</TableCell>
          else if (uid != null && uname != null) {
            /* Lock editing if this column has been queued or billed. */
            const colBillStatRow = props.colBillStatus.get(c.col_id);
            const cbStatus = (colBillStatRow !== undefined) ? colBillStatRow.status : 0;
            const hasRate = (row.rate != null && row.rate > 0);
            // If col status is not 0, no edit. (0 is not billed & not queued for billing) Unless the row doesn't have a rate, which means it doesn't affect billing so we don't need to lock.
            const disabledEditFromColStatus = (cbStatus != 0) && hasRate;

            return (
              <TableCell key={c.col_id} align="right">
                <table width={85}>
                  <tbody>
                    <tr>
                      <td><IconButton size='small' onClick={() => { if(props.readonly == false) { setOpenNoteModal(found) } }} sx={{ paddingLeft: 0, paddingRight: 0 }} title={found.note ? found.note.replaceAll("\\n", ", ") : "Add Note"}>{found.note ? <StickyNote2Icon /> : <NoteAddIcon style={{ color: "lightgrey" }} />}</IconButton></td>
                      <td>{found && <RightAlignInput disabled={props.readonly || actionsDisabled || disabledEditFromColStatus} onDataChange={onDataChange} key={c.col_id + '|' + row.row_id} uid={uid} uname={uname} col={c} row={row} data={found} tab={idx + ((idx + 1) * 100)} />}</td>
                    </tr>
                    <tr><td colSpan={2} style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
                      <div style={{ textAlign: 'left', display: (compact) ? 'none' : 'block', ...((typeText === 'Cost' && row.hasSub) ? styleStrikeThrough : {}) }}>
                        {/* , transition: "all 1.5s ease" */}
                        {!viewByCount && !isExtraRow && typeText + ' ' + asDollars(multiplier * found.qty)}
                      </div>
                    </td></tr>
                  </tbody>
                </table>
                <div style={{ display: 'flex' }}>
                </div>
              </TableCell>
            );
          } else
            return <TableCell key={c.col_id} width={85} align="right">User not logged in</TableCell>
        })
      }
    </TableRow>
  );
}
export default JobCostRow;